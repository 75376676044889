import { createAction } from "@reduxjs/toolkit";

export type GetCommissionsFilters = {
  clientId: string;
  date: { from: string; to: string };
};

export const getCommissions = createAction<GetCommissionsFilters>("getCommissions");
export const getMasterClients = createAction("getMasterClients");
export const getCommissionsCsv = createAction<GetCommissionsFilters>("getCommissionsCsv");
