import { EMatTable } from "Components";
import { formatCurrency } from "src/helpers/formatCurrency";
import "./ExpandableRow.scss";

const ExpandableRow = ({ record, handleSetInstrumentStrike }: any) => {
  const { data, instrument, instrument_name, update_time, _id } = record;

  const renderValue = (text: any, row: object, index: number) => {
    return formatCurrency(text);
  };

  //Strike button
  const renderStrike = (text: any, row: any, index: number) => {
    return (
      <button
        className="strike-tag"
        onClick={() =>
          handleSetInstrumentStrike(row, { instrument, instrument_name, update_time, _id })
        }
      >
        {formatCurrency(text)}
      </button>
    );
  };

  const columns = [
    {
      title: "CALL",
      with: "50%",
      children: [
        {
          title: "COM. (US$)",
          dataIndex: "call_buy",
          key: "call_buy",
          render: renderValue,
        },
        {
          title: "VEN. (US$)",
          dataIndex: "call_sell",
          key: "call_sell",
          render: renderValue,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "STRIKE (US$)",
          dataIndex: "strike",
          key: "strike",
          render: renderStrike,
        },
      ],
    },
    {
      title: "PUT",
      with: "50%",
      children: [
        {
          title: "COM. (US$)",
          dataIndex: "put_buy",
          key: "put_buy",
          render: renderValue,
        },
        {
          title: "VEN. (US$)",
          dataIndex: "put_sell",
          key: "put_sell",
          render: renderValue,
        },
      ],
    },
  ];

  return (
    <EMatTable
      className="strike-table"
      id="strike-table"
      columns={columns}
      dataSource={data}
      rowKey="strike"
      pagination={false}
    />
  );
};

export default ExpandableRow;
