const baseURL = process.env.REACT_APP_BASE_URL;

const API_ROUTES = {
  AUTH: {
    LOGIN: baseURL + "auth/login",
    REFRESH_TOKEN: baseURL + "auth/refresh",
    RECOVER_PASSWORD: baseURL + "auth/password/recover",
    VALIDATE_TOKEN: baseURL + "auth/password/validate",
    CHANGE_PASSWORD: baseURL + "auth/password/change",
    LOGOUT: baseURL + "auth/logout",
  },
  USERS: {
    CREATE: baseURL + "user/add/client",
    GET_CLIENTS: baseURL + "user/clients",
    COMMON: baseURL + "user",
    UPDATE_PASSWORD: baseURL + "user/password/change",
  },
  OPERATION: {
    GET_FUTURES: "future/get?filter=:type",
    GET_OPTIONS: "option/get?filter=:type",
    GET_SPREADS: "spread/get?filter=:type",
    CREATE_OPERATION: "order/create",
    CREATE_OPERATION_GROUP: "order",
    UPDATE_OPERATION: "order/:id",
    GET_CONTRA_POSITIONS: "position/get/contrapositions?symbol=:symbol&operation=:operation",
    GET_OWN_OPEN_POSITIONS: "position/get/own",
    GET_OWN_OPEN_POSITIONS_CSV: "position/get/own/csv",
    GET_OWN_CLOSED_POSITIONS: "position/get/own/closed",
    GET_OWN_CLOSED_POSITIONS_CSV: "position/get/own/closed/csv",
    GET_CLIENTS_OPEN_POSITIONS: "position/get/client",
    GET_CLIENTS_OPEN_POSITIONS_CSV: "position/get/client/csv",
    GET_CLIENTS_CLOSED_POSITIONS: "position/get/client/closed",
    GET_CLIENTS_CLOSED_POSITIONS_CSV: "position/get/client/closed/csv",
    GET_POSITIONS: "position/get/positions?symbol=:symbol&operation=:operation&userId=:userId",
    GET_COUNT: "order/count",
  },
  ISSUED_ORDERS: {
    HISTORIC: "order",
    GET_ISSUED_ORDERS: "order/emitted",
    DELETE_ISSUED_ORDER: "order",
    DELETE_ORDER_GROUP: "order/group",
    GET_ORDER_BY_ID: "order/getbyid",
    SET_STATUS_SENT_ORDER: "order/resend",
  },
  RECEIVED_ORDERS: {
    GET_CLIENTS: "user/clients",
    GET_RECEIVED_ORDERS: "order/received",
    DELETE_RECEIVED_ORDERS: "order",
    DELETE_ORDER_GROUP: "order/group",
    MARK_RECEIVED: "order/receive",
    MARK_OPERATED: "order/operate",
    MARK_PARTIAL_OPERATED: "/order/operate-partial",
    SEND_TO_OPERATOR: "order/send",
  },
  MONITOR: {
    GET_CLIENTS: "user/clients",
    GET_CLIENT_TONS: "monitor/processed-tons",
    GET_RESULT: "monitor/result",
  },
  NOTIFICATIONS: {
    COUNT: "notification/count",
    GET_ALL: "notification",
    CHECK: "notification/check",
  },
  NOTIFICATIONS: {
    COUNT: "notification/count",
    GET_ALL: "notification",
    CHECK: "notification/check"
  },
  ALERTS: {
    GET_ALL: "alert/all/get",
    CREATE: "alert/create",
    UPDATE: "alert/update",
    GET_COUNT: "alert/get/count",
    GET_BY_ID: "alert/get",
    DELETE: "alert/delete",
  },
  COMMISSIONS: {
    GET_BY_CLIENT: "commission",
    GET_CSV_BY_CLIENT: "commission/csv",
    GET_CLIENTS: "user/masters",
  },
};

export default API_ROUTES;
