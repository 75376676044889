import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { FuturesType, OptionsType, OrdersGroupsResponse, SpreadsType } from "src/types/operation";
import { ContrapositionType, OperationType } from "src/types/operation";
import { Order } from "src/types/order";
import { clearAllStores } from "../actions/common";

type OperationStateType = {
  futures: FuturesType | null;
  loadingFutures: boolean;
  options: OptionsType | null;
  loadingOptions: boolean;
  spreads: SpreadsType | null;
  loadingSpreads: boolean;
  issuedOrders: null;
  historicIssuedOrders: null;
  issuedOrderById: null;
  clients: Client | null;
  receivedOrders: Order | null;
  loadingGetClients: boolean;
  loadingReceivedOrders: boolean;
  loadingSetStatusSent: boolean;
  loadingIssuedOrders: boolean;
  loadingHistirocIsssuedOrders: boolean;
  loadingIssuedOrderById: boolean;
  loadingOperation: boolean;
  operationSubmitted: OperationType | null;
  operationGroupSubmitted: OrdersGroupsResponse | null;
  issuedOrdersGroups: OrdersGroupsResponse[] | null;
  receivedOrdersGroups: OrdersGroupsResponse[] | null;
  loadingOrders: boolean;
  deleteIssuedOrderSuccess: boolean;
  setStateSentSuccess: boolean;
  contraPositions: ContrapositionType[] | null;
  loadingContraPositions: boolean;
  deleteReceivedOrderSuccess: boolean;
  markReceivedSuccess: boolean;
  markOperatedSuccess: boolean;
  markPartiallyOperatedSuccess: boolean;
  sentToOperatorSuccess: boolean;
  ownOpenPositions: any;
  loadingOwnOpenPositions: boolean;
  ownClosedPositions: any;
  loadingOwnClosedPositions: boolean;
  clientsOpenPositions: any;
  loadingClientsOpenPositions: boolean;
  clientsClosedPositions: any;
  loadingClientsClosedPositions: boolean;
  positions: any[];
  loadingPositions: boolean[];
  count: { received: number | undefined; emitted: number | undefined };
  loadingCsv: boolean;
  positionsCsv: any;
};
const initialState: OperationStateType = {
  futures: null,
  issuedOrders: null,
  historicIssuedOrders: null,
  issuedOrderById: null,
  clients: null,
  receivedOrders: null,
  loadingGetClients: false,
  loadingReceivedOrders: false,
  loadingFutures: false,
  options: null,
  loadingOptions: false,
  spreads: null,
  loadingSpreads: false,
  loadingOperation: false,
  operationSubmitted: null,
  operationGroupSubmitted: null,
  issuedOrdersGroups: null,
  receivedOrdersGroups: null,
  loadingOrders: false,
  loadingIssuedOrders: false,
  loadingHistirocIsssuedOrders: false,
  loadingSetStatusSent: false,
  loadingIssuedOrderById: false,
  deleteIssuedOrderSuccess: false,
  setStateSentSuccess: false,
  contraPositions: null,
  loadingContraPositions: false,
  deleteReceivedOrderSuccess: false,
  markReceivedSuccess: false,
  markOperatedSuccess: false,
  markPartiallyOperatedSuccess: false,
  sentToOperatorSuccess: false,
  ownOpenPositions: null,
  loadingOwnOpenPositions: false,
  ownClosedPositions: null,
  loadingOwnClosedPositions: false,
  clientsOpenPositions: null,
  loadingClientsOpenPositions: false,
  clientsClosedPositions: null,
  loadingClientsClosedPositions: false,
  positions: [],
  loadingPositions: [],
  count: { emitted: 0, received: 0 },
  positionsCsv: null,
  loadingCsv: false,
};

const operation = createSlice({
  name: "futures",
  initialState: initialState,
  reducers: {
    getFuturesStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingFutures: true, error: null }),
    getFuturesSuccess: (state, { payload }: PayloadAction<FuturesType>) =>
      updateObject(state, { futures: payload, loadingFutures: false }),
    getFuturesError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingFutures: false, error: payload }),

    getOptionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOptions: true, error: null }),
    getOptionsSuccess: (state, { payload }: PayloadAction<OptionsType>) =>
      updateObject(state, { options: payload, loadingOptions: false }),
    getOptionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOptions: false, error: payload }),

    getSpreadsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingSpreads: true, error: null }),
    getSpreadsSuccess: (state, { payload }: PayloadAction<SpreadsType>) =>
      updateObject(state, { spreads: payload, loadingSpreads: false }),
    getSpreadsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingSpreads: false, error: payload }),

    //Own open positions
    //TODO: add type
    getOwnOpenPositionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOwnOpenPositions: true, error: null }),
    getOwnOpenPositionsSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { ownOpenPositions: payload, loadingOwnOpenPositions: false }),
    getOwnOpenPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOwnOpenPositions: false, error: payload }),

    getOwnOpenPositionsCsvStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingCsv: true, error: null }),
    getOwnOpenPositionsCsvSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { positionsCsv: payload, loadingCsv: false }),
    getOwnOpenPositionsCsvError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingCsv: false, error: payload }),
    clearPositionsCsv: (state, { payload }: PayloadAction) =>
      updateObject(state, { positionsCsv: null }),

    //Own close positions
    //TODO: add type
    getOwnClosedPositionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOwnClosedPositions: true, error: null }),
    getOwnClosedPositionsSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { ownClosedPositions: payload, loadingOwnClosedPositions: false }),
    getOwnClosedPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOwnClosedPositions: false, error: payload }),

    getOwnClosedPositionsCsvStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingCsv: true, error: null }),
    getOwnClosedPositionsCsvSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { positionsCsv: payload, loadingCsv: false }),
    getOwnClosedPositionsCsvError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingCsv: false, error: payload }),

    getClientsOpenPositionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingClientsOpenPositions: true, error: null }),
    getClientsOpenPositionsSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { clientsOpenPositions: payload, loadingClientsOpenPositions: false }),
    getClientsOpenPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingClientsOpenPositions: false, error: payload }),
    clearClientOpenPositions: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingClientsOpenPositions: false,
        clientsOpenPositions: null,
        error: null,
      }),

    getClientsOpenPositionsCsvStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingCsv: true, error: null }),
    getClientsOpenPositionsCsvSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { positionsCsv: payload, loadingCsv: false }),
    getClientsOpenPositionsCsvError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingCsv: false, error: payload }),

    getClientsClosedPositionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingClientsClosedPositions: true, error: null }),
    getClientsClosedPositionsSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        clientsClosedPositions: payload,
        loadingClientsClosedPositions: false,
      }),
    getClientsClosedPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingClientsClosedPositions: false, error: payload }),
    clearClientClosedPositions: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingClientsClosedPositions: false,
        clientsClosedPositions: null,
        error: null,
      }),

    getClientsClosedPositionsCsvStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingCsv: true, error: null }),
    getClientsClosedPositionsCsvSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { positionsCsv: payload, loadingCsv: false }),
    getClientsClosedPositionsCsvError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingCsv: false, error: payload }),

    submitOperationStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOperation: true,
        error: null,
        operationSubmitted: null,
      }),
    submitOperationSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        operationSubmitted: payload,
        loadingOperation: false,
      }),
    submitOperationError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOperation: false, error: payload }),
    clearOperationSubmitted: (state, { payload }: PayloadAction) =>
      // it works for operations and operations groups (order groups)
      updateObject(state, { operationSubmitted: null, operationGroupSubmitted: null }),

    /** CREATE NEW ORDERS GROUP */
    createOrdersGroupStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOperation: true,
        error: null,
        operationGroupSubmitted: null,
      }),
    createOrdersGroupSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        operationGroupSubmitted: payload,
        loadingOperation: false,
      }),
    createOrdersGroupError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOperation: false, error: payload }),

    /** GET ISSUED ORDERS GROUPS */
    getIssuedOrdersGroupsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOrders: true, error: null }),
    getIssuedOrdersGroupsSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        issuedOrdersGroups: payload,
        loadingOrders: false,
      }),
    getIssuedOrdersGroupsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    /** GET RECEIED ORDERS GROUPS */
    getReceivedOrdersGroupsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOrders: true, error: null }),
    getReceivedOrdersGroupsSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        receivedOrdersGroups: payload,
        loadingOrders: false,
      }),
    getReceivedOrdersGroupsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    getContraPositionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingContraPositions: true,
        error: null,
        contraPositions: null,
      }),
    getContraPositionsSuccess: (state, { payload }: PayloadAction<ContrapositionType[]>) =>
      updateObject(state, {
        contraPositions: payload,
        loadingContraPositions: false,
      }),
    getContraPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingContraContraPositions: false,
        error: payload,
      }),

    //issued orders
    getIssuedOrdersSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingIssuedOrders: true, error: null }),
    getIssuedOrdersSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        issuedOrders: payload,
        loadingIssuedOrders: false,
      }),
    getIssuedOrdersSagaError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingIssuedOrders: false, error: payload }),

    // Historic issued orders
    getHistoricIssuedOrdersStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingHistirocIsssuedOrders: true, error: null }),
    getHistoricIssuedOrdersSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        historicIssuedOrders: payload,
        loadingHistirocIsssuedOrders: false,
      }),
    getHistoricIssuedOrdersError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingHistirocIsssuedOrders: false, error: payload }),

    //delete issued orders
    deleteIssuedOrderSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        deleteIssuedOrderSuccess: false,
      }),
    deleteIssuedOrderSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        deleteIssuedOrderSuccess: true,
      }),
    deleteIssuedOrderSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //get issued order by id
    getIssuedOrderByIdSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingIssuedOrderById: true, error: null }),
    getIssuedOrderByIdSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        issuedOrderById: payload,
        loadingIssuedOrderById: false,
      }),
    getIssuedOrderByIdSagaError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingIssuedOrderById: false, error: payload }),
    clearGetIssuedOrderById: (state, { payload }: PayloadAction) =>
      updateObject(state, { issuedOrderById: null }),

    //set state sent in issued order
    setStatusSentSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        setStateSentSuccess: false,
      }),
    setStatusSentSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        setStateSentSuccess: true,
      }),
    setStatusSentSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //get clients (for received order)
    getClientsSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingGetClients: true, error: null }),
    getClientsSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        clients: payload,
        loadingGetClients: false,
      }),
    getClientsSagaError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingGetClients: false, error: payload }),

    //get received order
    getReceivedOrdersSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingReceivedOrders: true, error: null }),
    getReceivedOrdersSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        receivedOrders: payload,
        loadingReceivedOrders: false,
      }),
    getReceivedOrdersSagaError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingReceivedOrders: false, error: payload }),

    //delete received order
    deleteReceivedOrderSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        deleteReceivedOrderSuccess: false,
      }),
    deleteReceivedOrderSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        deleteReceivedOrderSuccess: true,
      }),
    deleteReceivedOrderSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //mark received order
    markReceivedSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        markReceivedSuccess: false,
      }),
    markReceivedSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        markReceivedSuccess: true,
      }),
    markReceivedSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //mark operated order
    markOperatedSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        markOperatedSuccess: false,
      }),
    markOperatedSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        markOperatedSuccess: true,
      }),
    markOperatedSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //mark operated order
    markPartiallyOperatedSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        markPartiallyOperatedSuccess: false,
      }),
    markPartiallyOperatedSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        markPartiallyOperatedSuccess: true,
      }),
    markPartiallyOperatedSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    //mark sent to operator order
    sentToOperatorSagaStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingOrders: true,
        error: null,
        sentToOperatorSuccess: false,
      }),
    sentToOperatorSagaSuccess: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingOrders: false,
        sentToOperatorSuccess: true,
      }),
    sentToOperatorSagaError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),

    getPositionsStart: (state, { payload }: PayloadAction<any>) => {
      const updatedPositions = [...state.positions];
      updatedPositions[payload.index] = null;
      const updateLoading = [...state.loadingPositions];
      updateLoading[payload.index] = true;
      return updateObject(state, {
        loadingPositions: updateLoading,
        error: null,
        positions: updatedPositions,
      });
    },
    getPositionsSuccess: (state, { payload }: PayloadAction<any>) => {
      const updatedPositions = [...state.positions];
      updatedPositions[payload.index] = payload.positions;
      const updateLoading = [...state.loadingPositions];
      updateLoading[payload.index] = false;
      return updateObject(state, {
        positions: updatedPositions,
        loadingPositions: updateLoading,
      });
    },
    getPositionsError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loadingPositions: false,
        error: payload,
      }),
    getCountStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingOrders: true, error: null }),
    getCountSuccess: (state, { payload }: PayloadAction<number>) =>
      updateObject(state, { count: payload, loadingOrders: false }),
    getCountError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingOrders: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getFuturesStart,
  getFuturesSuccess,
  getFuturesError,
  getOptionsStart,
  getOptionsSuccess,
  getOptionsError,
  getSpreadsStart,
  getSpreadsSuccess,
  getSpreadsError,
  submitOperationStart,
  submitOperationSuccess,
  submitOperationError,
  clearOperationSubmitted,
  /** ORDERS GROUPS */
  // Create
  createOrdersGroupStart,
  createOrdersGroupSuccess,
  createOrdersGroupError,
  // Get Issued
  getIssuedOrdersGroupsStart,
  getIssuedOrdersGroupsSuccess,
  getIssuedOrdersGroupsError,
  // Get Received
  getReceivedOrdersGroupsStart,
  getReceivedOrdersGroupsSuccess,
  getReceivedOrdersGroupsError,
  /** ------------ */
  getContraPositionsStart,
  getContraPositionsSuccess,
  getContraPositionsError,
  getIssuedOrdersSagaStart,
  getIssuedOrdersSagaSuccess,
  getIssuedOrdersSagaError,
  getHistoricIssuedOrdersStart,
  getHistoricIssuedOrdersSuccess,
  getHistoricIssuedOrdersError,
  deleteIssuedOrderSagaStart,
  deleteIssuedOrderSagaSuccess,
  deleteIssuedOrderSagaError,
  getIssuedOrderByIdSagaStart,
  getIssuedOrderByIdSagaSuccess,
  getIssuedOrderByIdSagaError,
  setStatusSentSagaStart,
  setStatusSentSagaSuccess,
  setStatusSentSagaError,
  clearGetIssuedOrderById,
  getClientsSagaStart,
  getClientsSagaSuccess,
  getClientsSagaError,
  getReceivedOrdersSagaStart,
  getReceivedOrdersSagaSuccess,
  getReceivedOrdersSagaError,
  deleteReceivedOrderSagaStart,
  deleteReceivedOrderSagaSuccess,
  deleteReceivedOrderSagaError,
  markReceivedSagaStart,
  markReceivedSagaSuccess,
  markReceivedSagaError,
  markOperatedSagaStart,
  markOperatedSagaSuccess,
  markOperatedSagaError,
  markPartiallyOperatedSagaStart,
  markPartiallyOperatedSagaSuccess,
  markPartiallyOperatedSagaError,
  sentToOperatorSagaStart,
  sentToOperatorSagaSuccess,
  sentToOperatorSagaError,
  getOwnOpenPositionsStart,
  getOwnOpenPositionsSuccess,
  getOwnOpenPositionsError,
  getOwnClosedPositionsStart,
  getOwnClosedPositionsSuccess,
  getOwnClosedPositionsError,
  getClientsOpenPositionsStart,
  getClientsOpenPositionsSuccess,
  getClientsOpenPositionsError,
  clearClientOpenPositions,
  getClientsClosedPositionsStart,
  getClientsClosedPositionsSuccess,
  getClientsClosedPositionsError,
  clearClientClosedPositions,
  getPositionsStart,
  getPositionsSuccess,
  getPositionsError,
  getCountStart,
  getCountSuccess,
  getCountError,
  clearPositionsCsv,
  getOwnOpenPositionsCsvStart,
  getOwnOpenPositionsCsvSuccess,
  getOwnOpenPositionsCsvError,
  getOwnClosedPositionsCsvStart,
  getOwnClosedPositionsCsvSuccess,
  getOwnClosedPositionsCsvError,
  getClientsOpenPositionsCsvStart,
  getClientsOpenPositionsCsvSuccess,
  getClientsOpenPositionsCsvError,
  getClientsClosedPositionsCsvStart,
  getClientsClosedPositionsCsvSuccess,
  getClientsClosedPositionsCsvError,
} = operation.actions;

export default operation;
