import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { OperationType } from "src/types/operation";
import FailSummary from "./FailSummary";
import { arrowDown } from "Assets";
import { ReactSVG } from "react-svg";
import { OPERATION_TYPES } from "src/constants/operation";
import EMatTable from "src/components/EMatTable/EMatTable";
import EMatButton from "src/components/UI/EMatButton/EMatButton";
import SpinnerInParent from "src/components/UI/SpinnerInParent/SpinnerInParent";
import { SortOrder } from "antd/lib/table/interface";

interface Props {
  operationInfo: OperationType;
  onClickConfirm: (operation: OperationType) => void;
  onClickEdit: () => void;
  contraPositions: any;
  onCancel: () => void;
  onFinalize: () => void;
}

const autoSetPositions = ({
  operation,
  contraPositions,
  setTotalQuantity,
  setSelectedRowKeys,
  setSelectedContrapositions,
  maxQuantity,
}: any) => {
  let index = 0;
  let selectedContrapositions = [];

  for (let quantity = 0; quantity < maxQuantity; ) {
    quantity = quantity + contraPositions[index].order_quantity;
    selectedContrapositions.push(contraPositions[index]);
    setTotalQuantity(quantity);
    index++;
  }
  setSelectedRowKeys(selectedContrapositions.map((c) => c.order_id));
  setSelectedContrapositions(selectedContrapositions);
};

const CloseSummary = ({
  operationInfo,
  onClickConfirm,
  onClickEdit,
  contraPositions,
  onCancel,
  onFinalize,
}: Props) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [selectedContrapositions, setSelectedContrapositions] = useState<any[]>([]);

  const {
    instrument,
    operation,
    agreementType,
    position,
    bonus,
    priceFrom,
    priceTo,
    quantity,
    strike,
  } = operationInfo;

  const [maxQuantity, setMaxQuantity] = useState(0);

  const orderDesend: SortOrder = "descend";

  useEffect(() => {
    if (contraPositions && contraPositions.length > 0) {
      let max = 0;
      contraPositions.forEach((p: any) => (max += p.order_quantity));
      if (operationInfo.quantity < max) {
        setMaxQuantity(operationInfo.quantity);
      } else {
        setMaxQuantity(max);
      }

      autoSetPositions({
        operationInfo,
        contraPositions,
        setTotalQuantity,
        setSelectedRowKeys,
        setSelectedContrapositions,
        maxQuantity,
      });
    }
  }, [contraPositions, operationInfo, maxQuantity]);

  const columns = [
    {
      title: t("operation:options.summary.quantity").toUpperCase(),
      dataIndex: "order_quantity",
      key: "order_quantity",
      render: (text: any, row: object, index: number) => text && text + " Tn.",
    },
    {
      title: t("operation:options.price").toUpperCase(),
      dataIndex: "price",
      key: "price",
      render: (text: any, row: object, index: number) => text + " $",
      sorter: (a: any, b: any) => a.price - b.price,
      defaultSortOrder: orderDesend,
    },
  ];

  const handleSelectRow = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedContrapositions = contraPositions.filter((c: any) =>
      selectedRowKeys.includes(c.order_id)
    );
    let total = 0;
    selectedContrapositions.forEach((c: any) => {
      total += c.order_quantity;
    });
    setTotalQuantity(total);
    total === quantity && setShowError(false);
    setSelectedContrapositions(selectedContrapositions);
  };

  const handleClickConfirm = () => {
    if (totalQuantity === Number(quantity)) {
      const arrayContra: any = [];
      selectedContrapositions.forEach((object) => {
        arrayContra.push({
          price: object.price,
          quantity: object.order_quantity,
          order_id: object.order_id,
        });
      });
      operationInfo.contraposition = arrayContra;
      onClickConfirm(operationInfo);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {!contraPositions && <SpinnerInParent backgroundColor="blue" iconColor="green" withOpacity />}
      {contraPositions?.length === 0 ? (
        <FailSummary onClickEdit={onClickEdit} onBackToOperate={onCancel} />
      ) : (
        <>
          <div className="summary-values close">
            {/* OPERACION */}
            <Row gutter={8}>
              <Col span={12} className="label upper">
                {t("operation:options.summary.operation")}
              </Col>
              <Col span={12} className="value">
                {`${t(`operation:options.${operation.toLowerCase()}`)} ${t(
                  `operation:options.${agreementType?.toLowerCase()}`
                )}`}
              </Col>
            </Row>
            {/* STRIKE */}
            <Row gutter={8}>
              <Col span={12} className="label upper">
                {t("operation:options.strike")}
              </Col>
              <Col span={12} className="value">
                {strike}
              </Col>
            </Row>
            {/* INSTRUMENT */}
            <Row gutter={8}>
              <Col span={12} className="label upper">
                {t("operation:options.summary.instrument")}
              </Col>
              <Col span={12} className="value">
                {instrument}
              </Col>
            </Row>
            {/* QUANTITY */}
            {quantity && (
              <Row gutter={8}>
                <Col span={12} className="label upper">
                  {t("operation:options.summary.quantity")}
                </Col>
                <Col span={12} className="value">
                  {quantity} Tn.
                </Col>
              </Row>
            )}
            {/* PRIMA */}
            <Row gutter={8}>
              <Col span={12} className="label upper">
                {t("operation:options.price")}
              </Col>
              <Col span={12} className="value">
                {bonus ? `${bonus} $` : t("operation:options.to-market")}
              </Col>
            </Row>
            {/* RANGO */}
            {priceFrom && priceTo && (
              <Row gutter={8}>
                <Col span={12} className="label upper">
                  {t("operation:options.summary.range")}
                </Col>
                <Col span={12} className="value">
                  <span className="range-elipsis">
                    ${priceFrom} / ${priceTo}
                  </span>
                </Col>
              </Row>
            )}
            {/* POSICION */}
            <Row gutter={8}>
              <Col span={12} className="label upper">
                {t("operation:options.summary.position")}
              </Col>
              <Col span={12} className="value">
                {t(`operation:options.${position.toLowerCase()}`)}{" "}
              </Col>
            </Row>
            {/* CLOSING OPERATIONS */}
            <div className="close-operations">
              <h1>
                {t(
                  `operation:futures.summary.${operation === OPERATION_TYPES.BUY ? "sell" : "buy"}`
                ) +
                  " " +
                  instrument}
              </h1>
              <EMatTable
                id="contra-positions"
                columns={columns}
                dataSource={contraPositions}
                rowKey={"order_id"}
                pagination={false}
                rowSelection={{ selectedRowKeys, onChange: handleSelectRow }}
                scroll={{ y: 300 }}
              />
            </div>
            {showError && (
              <div className="error-msg">
                <p>{t("operation:options.summary.insufficient-quantity")}</p>
              </div>
            )}

            <div className="buttons-container close">
              <EMatButton
                type="primary"
                onClick={() => {
                  handleClickConfirm();
                  onFinalize();
                }}
              >
                {t("operation:options.summary.confirm-order")}
              </EMatButton>
              <EMatButton type="text" id="edit" onClick={onClickEdit}>
                {t("operation:common.summary.add-new")}
              </EMatButton>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CloseSummary;
