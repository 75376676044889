import { Divider, Form, Radio, RadioChangeEvent, Select } from "antd"
import { EMatButton, EMatFormItem, EMatInput, EMatModal, Spinner } from "Components"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes"
import { ALERT_TYPES, ALERT_TYPE_OPTIONS } from "src/constants/operation"
import { useAppSelector } from "src/hooks/reduxHooks"
import { AlertType, InstrumentType, AlertBodyType } from "src/types/alerts"

type AlertFormModalProps = {
  onCancel: () => void
  values?: AlertType
  onSubmit: (values: AlertBodyType) => void
  getContainer?: string | HTMLElement | (() => HTMLElement) | false
  onSelectOperationType: (value: string) => void
  onSelectGrainType: (value: string) => void
  selectedId: string
}

const AlertFormModal = ({
  onCancel,
  values,
  onSubmit,
  getContainer,
  onSelectOperationType,
  onSelectGrainType,
  selectedId,
}: AlertFormModalProps) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const [alertType, setAlerType] = useState<string>(ALERT_TYPES.FUTURE)
  const instruments = useAppSelector<InstrumentType[] | null>((state) => state.alerts.instruments)
  const loadingInstruments = useAppSelector<boolean>((state) => state.alerts.loadingInstruments)
  const [options, setOptions] = useState<any>([])
  const [operationType, setOperationType] = useState<string>(
    values?.operation ? values.operation : ""
  )

  const grainOptions = [
    {
      label: t("futures:options.corn"),
      value: "MAI",
    },
    {
      label: t("futures:options.soy"),
      value: "SOJ",
    },
    {
      label: t("futures:options.wheat"),
      value: "TRI",
    },
  ]

  const onInstrumentChange = (value: string) => {
    const optionType = form.getFieldValue("optionType").charAt(0).toUpperCase()
    const instrument = instruments?.find((i) => i._id === value)

    if (instrument && instrument.data) {
      const selectedOption = instrument.data.filter(
        (option) => option._id.substr(option._id.length - 1) === optionType
      )
      setOptions(selectedOption)
    }


    if (instrument) {
      const { last, bid, offer, settelement_price } = instrument;
      const actualPrice = (operationType === "sell" ? bid : offer) || last || settelement_price;
      form.setFieldsValue({ actualPrice });
    }
  }

  const onOptionTypeChange = (event: RadioChangeEvent) => {
    const { value: optionType } = event.target
    setAlerType(optionType)
    event && onSelectOperationType(optionType)
    form.resetFields()
    form.setFieldsValue({ optionType })

    setOptions([])
    setOperationType("")
  }

  const onStrikeChange = (value: any) => {
    const option = options.find((o: any) => o.strike === value)

    switch (alertType) {
      case ALERT_TYPES.PUT:
        if (operationType === "sell") {
          form.setFieldsValue({ actualPrice: option?.put_buy })
        } else {
          form.setFieldsValue({ actualPrice: option?.put_sell })
        }
        break
      case ALERT_TYPES.CALL:
        if (operationType === "sell") {
          form.setFieldsValue({ actualPrice: option?.call_buy })
        } else {
          form.setFieldsValue({ actualPrice: option?.call_sell })
        }
        break

      default:
        break
    }

    form.setFieldsValue({ instrument: option._id })
  }

  const onOperationTypeChange = (value: any) => {
    setOperationType(value)
  }

  const alertTypeOptions = ALERT_TYPE_OPTIONS.map((type: string) => (
    <EMatInput eMatType={EMAT_INPUT_TYPES.RADIO} value={type} key={type}>
      {t(`alerts:types.${type}`)}
    </EMatInput>
  ))

  const operationOptions = ["buy", "sell"].map((o) => (
    <Select.Option key={o} value={o}>
      {t(`operation:common.${o}`)}
    </Select.Option>
  ))

  const strikeOptions = options.map((o: any) => (
    <Select.Option key={o._id} value={o.strike}>
      {o.strike.toString()}
    </Select.Option>
  ))

  const instrumentOptions =
    instruments &&
    instruments.map((i) => (
      <Select.Option key={i._id} value={i.instrument}>
        {i.instrument_name}
      </Select.Option>
    ))

  const initialValues = {
    id: values?.id,
    optionType: values?.option_type,
    operation: values?.operation,
    instrument: values?.instrument,
    strike: values?.strike,
    bonus: values?.bonus,
    actualPrice: values?.actual_price,
    price: values?.price,
  }


  return (
    <div id="alert-modal-container">
      <EMatModal
        closable={true}
        visible={true}
        title=""
        className="alert-form-modal"
        onCancel={onCancel}
        footer={null}
        getContainer={getContainer}
      >
        {loadingInstruments && <Spinner className="close" spinning={true} size="large" />}
        <h2>{t("alerts:form:title")}</h2>
        <Divider type="horizontal" />
        <Form
          id="alert-form"
          form={form}
          onFinish={(values) => {
            if (initialValues.id) {
              onSubmit({ ...values, id: initialValues.id })
            } else {
              onSubmit(values)
            }
          }}
          onFinishFailed={(error) => console.log(error)}
          initialValues={initialValues}
        >
          <EMatFormItem
            className="operation-type-item"
            name="optionType"
            rules={[
              {
                required: true,
                message: t("common:errors:fieldRequiredDefault"),
              },
            ]}
            validateTrigger={["onBlur"]}
            initialValue="future"
          >
            <Radio.Group
              id="operation-type"
              onChange={onOptionTypeChange}
              disabled={values !== undefined}
            >
              {alertTypeOptions}
            </Radio.Group>
          </EMatFormItem>
          <div className="grain-select">
            <EMatInput
              className="select-filter"
              id={selectedId}
              defaultActiveFirstOption={true}
              eMatType={EMAT_INPUT_TYPES.SELECT}
              options={grainOptions}
              onSelect={(option: any) => onSelectGrainType(option)}
              defaultValue={grainOptions[0].value}
              disabled={values !== undefined}
            />
          </div>
          <div id="operation-item-container">
            <EMatFormItem
              name="operation"
              rules={[
                {
                  required: true,
                  message: t("common:errors:fieldRequiredDefault"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.SELECT}
                placeholder={t("alerts:form.operation")}
                getPopupContainer={() =>
                  document.getElementById("operation-item-container") as HTMLElement
                }
                onChange={(value) => onOperationTypeChange(value)}
              >
                {operationOptions}
              </EMatInput>
            </EMatFormItem>
          </div>
          <div id="instrument-item-container">
            <EMatFormItem
              name="instrument"
              rules={[
                {
                  required: true,
                  message: t("common:errors:fieldRequiredDefault"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.SELECT}
                placeholder={t("alerts:form.instrument")}
                showSearch
                getPopupContainer={() =>
                  document.getElementById("instrument-item-container") as HTMLElement
                }
                onChange={(value) => onInstrumentChange(value)}
                disabled={operationType === "" || values !== undefined}
              >
                {instrumentOptions}
              </EMatInput>
            </EMatFormItem>
          </div>
          {options.length > 0 ? (
            <div id="strike-item-container">
              <EMatFormItem
                name="strike"
                rules={[
                  {
                    required: true,
                    message: t("common:errors:fieldRequiredDefault"),
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <EMatInput
                  eMatType={EMAT_INPUT_TYPES.SELECT}
                  placeholder={t("alerts:form.strike")}
                  showSearch
                  getPopupContainer={() =>
                    document.getElementById("strike-item-container") as HTMLElement
                  }
                  onChange={(value) => onStrikeChange(value)}
                >
                  {strikeOptions}
                </EMatInput>
              </EMatFormItem>
            </div>
          ) : null}
          <EMatFormItem
            name="price"
            rules={[
              {
                required: alertType !== ALERT_TYPES.CALL && alertType !== ALERT_TYPES.PUT,
                message: t("common:errors:fieldRequiredDefault"),
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <EMatInput
              min={alertType === ALERT_TYPES.SPREAD ? "" : "0"}
              step=".01"
              eMatType={EMAT_INPUT_TYPES.NUMBER}
              id="price"
              placeholder={
                alertType !== ALERT_TYPES.CALL && alertType !== ALERT_TYPES.PUT
                  ? t("operation:common.price:future")
                  : t("operation:common.price:option")
              }
              suffix="US$"
            />
          </EMatFormItem>
          <EMatFormItem
            name="actualPrice"
            rules={[
              {
                required: true,
                message: t("common:errors:fieldRequiredDefault"),
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <EMatInput
              min={alertType === ALERT_TYPES.SPREAD ? "" : "0"}
              step=".01"
              eMatType={EMAT_INPUT_TYPES.NUMBER}
              id="bonus"
              placeholder={t("operation:common.actual-price")}
              suffix="US$"
            />
          </EMatFormItem>
          <EMatFormItem className="buttons-container">
            <EMatButton type="text" id="cancel" onClick={onCancel}>
              {t("common:actions.cancel")}
            </EMatButton>
            <EMatButton type="primary" id="submit" htmlType="submit" loading={false}>
              {t("common:actions.save")}
            </EMatButton>
          </EMatFormItem>
        </Form>
      </EMatModal>
    </div>
  )
}

export default AlertFormModal
