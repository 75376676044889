import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { notificationsService } from "Services";
import { AlertsResponse } from "src/types/alerts";
import * as actions from "../../actions";
import * as sliceActions from "../../slices/notifications";

export function* getNotificationsCount(action: ReturnType<typeof actions.getNotificationsCount>) {
  try {
    yield put(sliceActions.getCountStart());

    const { data }: AxiosResponse<AlertsResponse> = yield notificationsService.getCount();
    yield put(sliceActions.getCountSuccess(data.data.count));
  } catch (error: any) {
    yield put(sliceActions.getCountFail(error.message));
  }
}

export function* getNotifications(action: ReturnType<typeof actions.getNotifications>) {
  try {
    yield put(sliceActions.getNotificationsStart());

    const { data }: AxiosResponse<AlertsResponse> = yield notificationsService.getAll(
      action.payload
    );

    yield put(sliceActions.getNotificationsSuccess(data.data.notifications));
  } catch (error: any) {
    yield put(sliceActions.getNotificationsFail(error.message));
  }
}

export function* getScreenNotifications(action: ReturnType<typeof actions.getScreenNotifications>) {
  try {
    yield put(sliceActions.getScreenNotificationsStart());

    const { data }: AxiosResponse<AlertsResponse> = yield notificationsService.getAll(
      action.payload
    );

    yield put(sliceActions.getScreenNotificationsSuccess(data.data.notifications));
  } catch (error: any) {
    yield put(sliceActions.getScreenNotificationsFail(error.message));
  }
}

export function* markAsChecked(action: ReturnType<typeof actions.markAsChecked>) {
  try {
    yield put(sliceActions.markAsCheckedStart());

    yield notificationsService.markAsChecked(action.payload);
    
    yield put(actions.getScreenNotifications(true));
    yield put(sliceActions.markAsCheckedSuccess());
  } catch (error: any) {
    yield put(sliceActions.markAsCheckedFail(error.message));
  }
}
