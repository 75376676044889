import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EMatButton, EMatFormItem, EMatInput } from "Components";
import instance from "src/api/axios-instance";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../../store/actions";
import "./SignUp.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useEffect, useState } from "react";
import { USER_ROLES } from "src/constants/permissions";
import useValidationRules from "src/hooks/useValidationRules";
import { create } from "domain";

const SignUp = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.auth.error);
  const loading = useAppSelector((state) => state.users.loading);
  const createdUser = useAppSelector((state) => state.users.createdUser);
  const [phonePrefix, setPhonePrefix] = useState("+54");
  const {
    nameRules,
    lastNameRules,
    emailRules,
    phoneNumberRules,
    primaryPasswordRules,
    primaryUserRules,
    concomitentNumberRules,
    companyNameRules,
  } = useValidationRules();

  const handleClickSignIn = (values: {
    name: string;
    lastName: string;
    email: string;
    password: string;
    device_id: string;
    company_name: string;
    concomitent_number: string;
    primary_user: string;
    primary_pass: string;
    role: number;
    phoneNumber: string;
  }) => {
    const {
      name,
      lastName,
      email,
      concomitent_number,
      company_name,
      primary_pass,
      primary_user,
      role,
      phoneNumber,
    } = values;

    dispatch(
      actions.createUser({
        firstname: name,
        lastname: lastName,
        email,
        phone_number: phoneNumber,
        country_code: phonePrefix,
        password: `${concomitent_number}${name}`,
        concomitent_number,
        company_name,
        primary_pass,
        primary_user,
        role,
      })
    );
  };

  useEffect(() => {
    if (createdUser) {
      navigate(ROUTE_PATHS.CLIENTS);
    }
  }, [createdUser]);

  const userType = [
    {
      label: USER_ROLES.MASTER,
      value: 1,
    },
    {
      label: USER_ROLES.CLIENT,
      value: 2,
    },
  ];

  return (
    <div className="signUp-container submit-container background dark">
      <div className="signUp-content">
        <Form
          className="signin-form"
          form={form}
          onFinish={handleClickSignIn}
          initialValues={{
            role: 1,
          }}
        >
          <EMatFormItem name="name" rules={nameRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="name"
              placeholder={t("users:signUp:name")}
            />
          </EMatFormItem>
          <EMatFormItem name="lastName" rules={lastNameRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="lastName"
              placeholder={t("users:signUp:lastName")}
            />
          </EMatFormItem>
          <EMatFormItem name="email" rules={emailRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="email"
              placeholder={t("auth:signIn:email")}
            />
          </EMatFormItem>
          <EMatFormItem name="phoneNumber" rules={phoneNumberRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.PHONE_NUMBER}
              id="phoneNumber"
              prefixValue={phonePrefix}
              setPrefixValue={setPhonePrefix}
              placeholder={t("users:signUp:phoneNumber")}
            />
          </EMatFormItem>
          <EMatFormItem name="company_name" rules={companyNameRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="company_name"
              placeholder={t("users:signUp.company_name")}
            />
          </EMatFormItem>
          <EMatFormItem
            name="concomitent_number"
            rules={concomitentNumberRules}
            validateTrigger={["onBlur"]}
          >
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.NUMBER}
              id="concomitent_number"
              placeholder={t("users:signUp.concomitent_number")}
            />
          </EMatFormItem>
          <EMatFormItem name="primary_user" rules={primaryUserRules} validateTrigger={["onBlur"]}>
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="primary_user"
              placeholder={t("users:signUp:primary_user")}
            />
          </EMatFormItem>
          <EMatFormItem
            name="primary_pass"
            rules={primaryPasswordRules}
            validateTrigger={["onBlur"]}
          >
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="primary_pass"
              placeholder={t("users:signUp:primary_pass")}
            />
          </EMatFormItem>
          <EMatFormItem name="role">
            <EMatInput
              id="role"
              className="role-select"
              eMatType={EMAT_INPUT_TYPES.SELECT}
              options={userType}
              // onSelect={(value: any) => setShareClient(value)}
              // defaultValue={shareClient}
            />
          </EMatFormItem>
          {error && (
            <div className="error-msg">
              <p>{t(error)}</p>
            </div>
          )}
          <EMatFormItem>
            <EMatButton type="primary" id="submit" htmlType="submit" loading={loading}>
              {t("users:signUp.create")}
            </EMatButton>
            <EMatButton
              type="default"
              className="cancel"
              onClick={() => navigate(ROUTE_PATHS.CLIENTS)}
            >
              {t("users:signUp.cancel")}
            </EMatButton>
          </EMatFormItem>
        </Form>
      </div>
    </div>
  );
};

export default withErrorHandler(SignUp, instance);
