import { Tabs } from "antd";
import { TabsProps } from "antd/lib/tabs";
import { ReactNode } from "react";
import "./EMatTabs.scss";

type TabType = {
  tab: string;
  key: string;
  component: ReactNode;
};

type EMatTabsProps = TabsProps & { tabs: TabType[] };

const EMatTabs = (props: EMatTabsProps) => {
  const { tabs, className } = props;
  const TabPane = Tabs.TabPane;

  return (
    <Tabs defaultActiveKey={props.defaultActiveKey} {...props} className={"eMat-tabs " + className}>
      {tabs.map((tab) => (
        <TabPane {...tab}>{tab.component}</TabPane>
      ))}
    </Tabs>
  );
};

export default EMatTabs;
