import { OrdersGroupType } from "src/types/operation";
import AntSpin from "../UI/AntSpinner/AntSpin";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { arrowDown, arrowUp } from "Assets";
import EMatTable from "../EMatTable/EMatTable";
import { parceStatus, parseOrderCardData } from "src/containers/IssuedOrders/utils";
import EMatInput from "../UI/EMatInput/EMatInput";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { DefaultOptionType } from "antd/lib/select";
import OrderCardsList from "./components/OrderCardsList";
import { useAppSelector } from "src/hooks/reduxHooks";
import GroupActions from "./components/GroupActions";

interface ListOrderGroupsProps {
  groups: OrdersGroupType[];
  loading: boolean;
  labelCount: string;
  isReceived?: boolean;
  filter?: {
    options: DefaultOptionType[];
    defaultValue: any;
    onSelect: (value: any) => void;
    loading: boolean;
  };
  pagination?: any;
  clientId: string;
  dateRangePicker?: any
}

const ListOrderGroups = ({
  groups,
  loading,
  labelCount,
  isReceived,
  filter,
  pagination,
  clientId,
  dateRangePicker
}: ListOrderGroupsProps) => {
  const [t] = useTranslation();
  const userData = useAppSelector<any>((state) => state.auth.userData);
  const columns = [
    {
      dataIndex: "group_id",
      key: "group_id",
      render: (value: string) => `${t("issued_orders:ordersList.group")}${value}`,
    },
    {
      dataIndex: "status",
      key: "status",
      render: (value: string, row: any) =>
        parceStatus(value.toString(), row.partial_operated_quantity),
    },

    Table.EXPAND_COLUMN,
  ];

  if (isReceived) {
    columns.unshift({ dataIndex: "company_name", key: "company_name" });
  }

  return (
    <div>
      <div className="count-select">
        {groups && !loading  && <h2 className="count-issued-orders">{groups.length + " " + labelCount}</h2>}
        {!groups && !loading && (
          <h2 className="count-issued-orders">{t("received_orders:noReceivedOrders")}</h2>
        )}
        {filter && (
          <EMatInput
            className="select-filter filter-received"
            eMatType={EMAT_INPUT_TYPES.SELECT}
            options={filter.options}
            defaultValue={filter.defaultValue}
            onSelect={filter.onSelect}
            loading={filter.loading}
          />
        )}
        {!loading && dateRangePicker}
      </div>

      {loading ? (
        <AntSpin />
      ) : (
        <>
          <EMatTable
            showHeader={false}
            id="groups"
            columns={columns}
            dataSource={groups || []}
            loading={loading}
            rowKey="group_id"
            pagination={false}
            expandable={{
              expandedRowRender: (group: any) => {
                return (
                  <>
                    <OrderCardsList
                      orders={parseOrderCardData(group.orders)}
                      ordersData={group.orders}
                      description={group.description}
                      isReceived={isReceived}
                      status={group.status}
                      clientId={clientId}
                      isOwner={group.creator_id === userData?.id}
                    />
                    <GroupActions
                      group={group}
                      status={group.status}
                      clientId={clientId}
                      ordersData={group.orders}
                      order={parseOrderCardData(group.orders)}
                      isOwner={group.creator_id === userData?.id}
                      isReceived={isReceived}
                      isFinalUser={group.master.is_final_user}
                    />
                  </>
                );
              },
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <ReactSVG
                    src={arrowUp}
                    onClick={(e: any) => onExpand(record, e)}
                    className="pointer"
                  />
                ) : (
                  <ReactSVG
                    src={arrowDown}
                    onClick={(e: any) => onExpand(record, e)}
                    className="pointer"
                  />
                ),
              expandRowByClick: true,
            }}
          />
          {pagination}
        </>
      )}
    </div>
  );
};

export default ListOrderGroups;
