import { Col, Empty, Row } from "antd";
import { arrowDown } from "Assets";
import { EMatButton, EMatInput, EMatTable, Spinner } from "Components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { OPERATION_TYPES, OPTION_TYPES } from "src/constants/operation";
import FailSummary from "./FailSummary";
import { formatCurrency } from "src/helpers/formatCurrency";

const CloseSummary = ({
  operation,
  contraPositions,
  onClickConfirm,
  onClickEdit,
  submitting,
  onClickGoToOperate,
  optionType,
}: any) => {
  const [t] = useTranslation();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [showError, setShowError] = useState(false);
  const [selectedContrapositions, setselectedContrapositions] = useState<any[]>([]);

  const columns = [
    {
      title: t("operation:futures.quantity").toUpperCase(),
      dataIndex: "order_quantity",
      key: "order_quantity",
      render: (text: any, row: object, index: number) => text && text + " Tn.",
    },
    {
      title: t("operation:futures.price").toUpperCase(),
      dataIndex: "price",
      key: "price",
      render: (text: any, row: object, index: number) => text + " $",
    },
  ];

  const handleSelectRow = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedContrapositions = contraPositions.filter((c: any) =>
      selectedRowKeys.includes(c.order_id)
    );
    let total = 0;
    selectedContrapositions.forEach((c: any) => {
      total += c.order_quantity;
    });
    setTotalQuantity(total);
    total == operation.quantity && setShowError(false);
    setselectedContrapositions(selectedContrapositions);
  };

  const handleClickConfirm = () => {
    if (totalQuantity == operation.quantity) {
      const arrayContra: any = [];
      selectedContrapositions.forEach((object) => {
        arrayContra.push({
          price: object.price,
          quantity: object.order_quantity,
          order_id: object.order_id,
        });
      });
      operation.contraposition = arrayContra;
      onClickConfirm(operation);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {!contraPositions && <Spinner className="close" spinning={true} size="large" />}
      {contraPositions?.length === 0 ? (
        <FailSummary onClickGoToOperate={onClickGoToOperate} onClickEdit={onClickEdit} />
      ) : (
        <>
          <div className="summary-values close">
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.operation")}
              </Col>
              <Col span={12} className="value">
                {t(`operation:common.summary.${operation?.operation.toLowerCase()}`) +
                  " " +
                  t(`operation:operation-options.${operation.optionType.toLowerCase()}`)}
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.instrument")}
              </Col>
              <Col span={12} className="value">
                <span className="value-summary-instrument">{operation?.instrument}</span>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.quantity")}
              </Col>
              <Col span={12} className="value">
                {operation?.quantity} Tn.
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12} className="label">
                {optionType === OPTION_TYPES.FUTURE
                  ? t("operation:common.summary.price.future")
                  : t("operation:common.summary.price.spread")}
              </Col>
              <Col span={12} className="value">
                {operation.price ? `${formatCurrency(operation.price)} US$` : t("operation:common.to-market")}
              </Col>
            </Row>
            {operation?.priceFrom && operation?.priceTo && (
              <Row gutter={8}>
                <Col span={12} className="label">
                  {t("operation:common.summary.range")}
                </Col>
                <Col span={12} className="value">
                  <span className="range-elipsis">
                  {formatCurrency(operation.priceFrom)} US$ / {formatCurrency(operation.priceTo)} US$
                  </span>
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.position")}
              </Col>
              <Col span={12} className="value">
                {" "}
                {t(`operation:common.${operation.position.toLowerCase()}`)}{" "}
              </Col>
            </Row>
          </div>
          <div className="close-operations">
            <h1>
              {t(
                `operation:common.${operation?.operation === OPERATION_TYPES.BUY ? "sell" : "buy"}`
              ) +
                " " +
                operation?.instrument}
            </h1>
            <EMatTable
              id="contra-positions"
              columns={columns}
              dataSource={contraPositions}
              rowKey={"order_id"}
              pagination={false}
              rowSelection={{ selectedRowKeys, onChange: handleSelectRow }}
              scroll={{ y: 300 }}
              className="table-summary"
            />
          </div>
          {showError && (
            <div className="error-msg">
              <p>{t("operation:common.summary.insufficient-quantity")}</p>
            </div>
          )}
          <div className="buttons-container close">
            <EMatButton type="primary" onClick={handleClickConfirm} loading={submitting}>
              {t("operation:common.summary.confirm-order")}
            </EMatButton>
            <EMatButton type="text" id="edit" onClick={onClickEdit}>
              {t("common:actions.edit")}
            </EMatButton>
          </div>
        </>
      )}
    </>
  );
};

export default CloseSummary;
