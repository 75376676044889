import { AxiosResponse } from "axios";
import API_ROUTES from "src/constants/apiRoutes";
import { AlertsResponse, AlertBodyType } from "src/types/alerts";
import axios from "../api/axios-instance";

export const alertService = {
  getAlerts: (grain?: string, status?: number): Promise<AxiosResponse<AlertsResponse>> => {
    const instrumentFilter = grain ? grain : "";
    const alertStatusFilter = status ? status : "";
    return axios.get(
      `${API_ROUTES.ALERTS.GET_ALL}?instrumentFilter=${instrumentFilter}&alertStatusFilter=${alertStatusFilter}`
    );
  },
  getAlert: (id: any): Promise<AxiosResponse<AlertsResponse>> =>
    axios.get(`${API_ROUTES.ALERTS.GET_BY_ID}/${id}`),
  createAlert: (body: AlertBodyType) => {
    return axios.post(API_ROUTES.ALERTS.CREATE, body);
  },
  updateAlert: (body: AlertBodyType) => {
    return axios.post(`${API_ROUTES.ALERTS.UPDATE}/${body.id}`, body);
  },
  deleteAlert: (id: any) => {
    return axios.post(`${API_ROUTES.ALERTS.DELETE}/${id}`);
  },
};
