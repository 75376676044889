import { Spin } from "antd";
import "./AntSpin";

const AntSpin = () => {
  return (
    <div className="custom-spin-container">
      <Spin size="default" spinning={true} />
    </div>
  );
};

export default AntSpin;
