import { useState } from "react";
import { EMatButton, EMatTable } from "Components";
import { useTranslation } from "react-i18next";
import { UpCircleTwoTone, DownCircleTwoTone, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Row } from "antd";

const ActionsButtonsIssued = ({
  info,
  columns,
  showModalSummary,
  data,
  showModalEdit,
  status,
}: {
  info: any;
  columns: any;
  status: number;
  showModalSummary: (value: any, type: string, data: any, info: any) => void;
  data: any;
  showModalEdit: (value: any, type: string, data: any, info: any) => void;
  loading?: boolean;
  isOwner?: boolean;
}) => {
  const [t] = useTranslation();
  const [showContent, setShowContent] = useState(false);

  const handlerShowContent = () => {
    setShowContent((prevState) => !prevState);
  };

  const menu = (
    <Menu className="order-card">
      {status === 1 && data.is_exercise === 0 && (
        <Menu.Item>
          <p
            id="edit"
            className="text-secondary"
            onClick={() => showModalEdit(data.id, "edit", data, info)}
          >
            {t(`issued_orders:button.edit`)}
          </p>
        </Menu.Item>
      )}
      {status === 1 && (
        <Menu.Item>
          <p
            id="delete"
            style={{ color: "red" }}
            className="text-secondary"
            onClick={() => showModalSummary(data.id, "delete", data, info)}
          >
            {t(`issued_orders:button.delete`)}
          </p>
        </Menu.Item>
      )}
      {status === 4 && (
        <Menu.Item>
          <p
            id="delete"
            style={{ color: "red" }}
            className="text-secondary"
            onClick={() => showModalSummary(data.id, "delete", data, info)}
          >
            {t(`issued_orders:button.delete`)}
          </p>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="actions-buttons">
      {(status === 1 || status === 4) && (
        <Dropdown overlay={menu} className="dropdown" trigger={["click", "hover"]}>
          <MoreOutlined style={{ fontSize: "26px", position: "absolute", top: 10, right: 5 }} />
        </Dropdown>
      )}

      {info.position === "Close" && (
        <div>
          <EMatButton className="text-secondary" onClick={handlerShowContent} type="text">
            <Row align="middle">
              {showContent ? (
                <UpCircleTwoTone
                  twoToneColor="#1cc3a7"
                  style={{ fontSize: "28px", marginRight: "6px" }}
                />
              ) : (
                <DownCircleTwoTone
                  twoToneColor="#1cc3a7"
                  style={{ fontSize: "28px", marginRight: "6px" }}
                />
              )}
              {t(`issued_orders:button.viewMore`)}
            </Row>
          </EMatButton>
          {showContent && (
            <EMatTable
              columns={columns}
              dataSource={data.contrapositions}
              rowKey="order_id"
              pagination={false}
              scroll={{ y: 200 }}
              // className="table-summary"
            ></EMatTable>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsButtonsIssued;
