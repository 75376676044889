import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "./components/UI/Spinner/Spinner";
import LayoutHoc from "./hoc/Layout/LayoutHoc";
import MainRoutes from "./routing/mainRoutes";
import routeArray from "./routing/routeArray";
import { useAppDispatch } from "./hooks/reduxHooks";
import { tryAutoSignUp } from "./store/actions";
import "./i18n/i18";
import "./App.scss";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(tryAutoSignUp(location.pathname));
  }, [tryAutoSignUp]);

  return (
    <div className="App">
      <LayoutHoc>
        <Suspense fallback={<Spinner spinning={true} size="large" />}>
          <MainRoutes routes={routeArray} />
        </Suspense>
      </LayoutHoc>
    </div>
  );
}

export default App;
