import axios from "../api/axios-instance";
import API_ROUTES from "src/constants/apiRoutes";
import { UpdatePasswordBodyType, UpdateUserBodyType, UsersBodyType } from "src/types/users";

export const usersService = {
  getClients: () => axios.get(API_ROUTES.USERS.GET_CLIENTS),
  createUser: (body: UsersBodyType) => {
    return axios.post(API_ROUTES.USERS.CREATE, body);
  },
  getUserById: (id: number) => {
    return axios.get(`${API_ROUTES.USERS.COMMON}/${id}`);
  },
  updateUser: (body: UpdateUserBodyType) => {
    return axios.patch(API_ROUTES.USERS.COMMON, body);
  },
  updatePassword: (body: UpdatePasswordBodyType) => {
    return axios.patch(API_ROUTES.USERS.UPDATE_PASSWORD, body);
  },
};
