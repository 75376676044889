import { takeLatest } from "redux-saga/effects";
import * as actions from "../../actions";
import { createUserSaga, getUsersSaga, getUserByIdSaga, updateUser, updatePassword } from "./users";

export function* watchUsersSaga() {
  yield takeLatest(actions.getUsers.type, getUsersSaga);
  yield takeLatest(actions.createUser.type, createUserSaga);
  yield takeLatest(actions.getUserById.type, getUserByIdSaga);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.updatePassword.type, updatePassword);
}
