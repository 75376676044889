const EMAT_INPUT_TYPES = {
  TEXT: "text",
  PASSWORD: "password",
  SELECT: "select",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  NUMBER: "number",
  RADIO: "radio",
  DATE_PIKER: "date",
  PHONE_NUMBER: "phoneNumber"
};

export default EMAT_INPUT_TYPES;
