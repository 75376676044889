import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import spinner from "./spinner";
import layout from "./layout";
import operation from "./operation";
import alerts from "./alerts";
import monitor from "./monitor";
import commissions from "./commissions";
import notifications from "./notifications";
import users from './users'

const RootReducer = combineReducers({
  auth: auth.reducer,
  spinner: spinner.reducer,
  layout: layout.reducer,
  operation: operation.reducer,
  monitor: monitor.reducer,
  alerts: alerts.reducer,
  commissions: commissions.reducer,
  notifications: notifications.reducer,
  users: users.reducer
});

export default RootReducer;
