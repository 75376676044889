export const USER_ROLES = {
  MASTER: "Maestro",
  CLIENT: "Cliente",
  ADMIN: "Admin"
};
//Route without permissions should have permissions array empty []
const PERMISSIONS = {
  DASHBOARD: [],
  SIGN_UP: [USER_ROLES.MASTER],
  OPERATION: [USER_ROLES.MASTER, USER_ROLES.CLIENT],
  ISSUED_ORDERS: [USER_ROLES.MASTER, USER_ROLES.CLIENT],
  RECEIVED_ORDERS: [USER_ROLES.MASTER],
  OWN_ACCOUNTS: [USER_ROLES.MASTER, USER_ROLES.CLIENT],
  CLIENTS_ACCOUNTS: [USER_ROLES.MASTER],
  MONITOR: [USER_ROLES.MASTER, USER_ROLES.CLIENT],
  ALERTS: [USER_ROLES.MASTER, USER_ROLES.CLIENT],
  COMMISSIONS: [USER_ROLES.ADMIN],
  NOTIFICATIONS: [],
  CLIENTS: [USER_ROLES.MASTER],
  PROFILE: [USER_ROLES.MASTER, USER_ROLES.CLIENT]
};

export default PERMISSIONS;
