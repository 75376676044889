import { ReactNode, useEffect, useState } from "react";
import OfflinePage from "./OfflinePage/OfflinePage";

const Offline = ({ children }: { children: ReactNode }) => {
  const [isOffline, setIsOffline] = useState<boolean>(false);

  const handleOnlineApp = () => {
    setIsOffline(false);
  };

  const handleOfflineApp = () => {
    setIsOffline(true);
  };

  useEffect(() => {
    window.addEventListener("online", handleOnlineApp);
    window.addEventListener("offline", handleOfflineApp);

    return () => {
      window.removeEventListener("online", handleOnlineApp);
      window.removeEventListener("offline", handleOfflineApp);
    };
  }, []);
  return <>{!isOffline ? children : <OfflinePage />}</>
};

export default Offline;
