import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { alertService, operationService } from "Services";
import { ALERT_TYPES } from "src/constants/operation";
import { AlertsResponse, InstrumentResponse } from "src/types/alerts";
import { InstrumentType } from "src/types/operation";
import * as actions from "../../actions";
import * as sliceActions from "../../slices/alerts";

export function* getAlertsSaga(action: ReturnType<typeof actions.getAlerts>) {
  try {
    yield put(sliceActions.getAlertsStart());

    const { grain, status } = action.payload;

    const { data }: AxiosResponse<AlertsResponse> = yield alertService.getAlerts(grain, status);

    yield put(sliceActions.getAlertsSuccess(data.data));
  } catch (error: any) {
    yield put(sliceActions.getAlertsFail(error.message));
  }
}

export function* getInstrumentsSaga(action: ReturnType<typeof actions.getInstruments>) {
  try {
    yield put(sliceActions.getInstrumentsStart());

    const { operation, grain } = action.payload;

    let instruments: InstrumentType[] = [];
    let response: AxiosResponse<InstrumentResponse>;
    switch (operation) {
      case ALERT_TYPES.FUTURE:
        response = yield operationService.getFutures(grain);
        instruments = response.data.data;
        break;
      case ALERT_TYPES.SPREAD:
        response = yield operationService.getSpreads(grain);
        instruments = response.data.data;
        break;
      default:
        response = yield operationService.getOptions(grain);
        instruments = response.data.data;
        break;
    }

    yield put(sliceActions.getInstrumentsSuccess(instruments));
  } catch (error: any) {
    yield put(sliceActions.getInstrumentsFail(error.message));
  }
}

export function* submitAlertSaga(action: ReturnType<typeof actions.submitAlert>) {
  yield put(sliceActions.submitAlertStart());
  try {
    let response: AxiosResponse<any>;
    if (!action.payload.id) {
      response = yield alertService.createAlert(action.payload);
    } else {
      response = yield alertService.updateAlert(action.payload);
    }
    yield put(sliceActions.submitAlertSuccess(response.data));

    yield put(sliceActions.getAlertsStart());
    const { data }: AxiosResponse<AlertsResponse> = yield alertService.getAlerts();
    yield put(sliceActions.getAlertsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.submitAlertError("common:errors.somethingWentWrong"));
  }
}

export function* deleteAlertSagga(action: ReturnType<typeof actions.deleteAlert>) {
  const { id } = action.payload;
  try {
    yield put(sliceActions.deleteAlertStart());
    yield alertService.deleteAlert(id);
    yield put(sliceActions.deleteAlertSuccess({ id }));
    yield put(sliceActions.getAlertsStart());
    const { data }: AxiosResponse<AlertsResponse> = yield alertService.getAlerts();
    yield put(sliceActions.getAlertsSuccess(data.data));
  } catch (error: any) {
    yield put(sliceActions.deleteAlertFail(error.message));
  }
}
