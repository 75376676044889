import { ReactNode } from "react";
import { Card, Col, Row } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import "./OrderCard.scss";

const OrderCard = ({
  data,
  children,
  isActive,
}: {
  data: DefaultOptionType;
  children: ReactNode;
  isActive?: boolean;
}) => {
  return (
    <Card className={isActive ? "active-order-card" : "order-card"}>
      {data.map((item: DefaultOptionType, i: any) => {
        if (!item || item.label === "isActive" || item.label === "id") {
          return null;
        }
        return (
          <Row gutter={8} key={i}>
            <Col span={12} className="label">
              {item.label}
            </Col>
            <Col span={12} className="value-orders">
              {item.value}
            </Col>
          </Row>
        );
      })}
      {children}
    </Card>
  );
};

export default OrderCard;
