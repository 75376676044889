import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
    //"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //"Access-Control-Allow-Headers": "Access-Control-Allow-Origin, authorization"
  },
  json: true
});

export default instance;