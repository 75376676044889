import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as actions from "../../../store/actions";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import {
  InstrumentType,
  OperationType,
  OptionInfoType,
  OptionsType,
  OptionType,
  StrikeInstrumentType,
} from "src/types/operation";
import { TableWithFilter, CallPutModal } from "Components";
import ExpandableRow from "./ExpandableRow/ExpandableRow";
import { arrowDown, arrowUp } from "Assets";
import { ReactSVG } from "react-svg";
import { Table } from "antd";
import "./Options.scss";
import { AGREEMENT_TYPE, OPERATION_TYPES, OPTION_TYPES } from "src/constants/operation";
import { useLocation } from "react-router-dom";
import { separateInstrumentAndStrike } from "src/helpers/separeInstrumentAndStrike";
import { capitalizeFirstChar } from "src/helpers/capiitalizeFirstChar";
import { OperationGroup } from "../OperationGroup";

const Options = (props: any) => {
  const { operationGroup, setOperationGroup, setIsSumaryVisible, tons, setTons } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state }: { state: any } = location;
  const [instrumentStrike, setInstrumentStrike] = useState<StrikeInstrumentType | null>(null);
  const [operationType, setOperationType] = useState(OPERATION_TYPES.BUY);
  const [agreementType, setAgreementType] = useState(AGREEMENT_TYPE.CALL);
  const [asociatedAlert, setAsociatedAlert] = useState(undefined);
  const optionsData = useAppSelector<OptionsType | null>((state) => state.operation.options);
  const isLoading = useAppSelector((state) => state.operation.loadingOptions);
  const isSubmitting = useAppSelector((state) => state.operation.loadingOperation);
  const operationGroupSubmitted = useAppSelector(
    (state) => state.operation.operationGroupSubmitted
  );
  const contraPositions = useAppSelector((state) => state.operation.contraPositions);
  const loadingContraPositions = useAppSelector((state) => state.operation.loadingContraPositions);

  //** Handlers **//

  const handleConfirmOperation = (operationInfo: OperationType) => {
    const data = {
      agreementType: operationInfo.agreementType,
      instrument: operationInfo.instrument,
      operation: operationInfo.operation,
      position: operationInfo.position,
      bonus: operationInfo.bonus,
      priceFrom: operationInfo.priceFrom,
      priceTo: operationInfo.priceTo,
      quantity: operationInfo.quantity,
      strike: operationInfo.strike,
      toMarket: operationInfo.toMarket,
      optionType: OPTION_TYPES.OPTION as OptionType,
      contraposition: operationInfo.contraposition,
    };
    setOperationGroup([...operationGroup, data]);
    setInstrumentStrike(null);
    setTons(operationInfo.quantity);
    if (asociatedAlert) {
      dispatch(actions.deleteAlert({ id: asociatedAlert }));
    }
  };

  const getContraPosition = (operation: string) => {
    if (instrumentStrike) {
      dispatch(
        actions.getContraPositions({
          operation: operation,
          symbol: encodeURIComponent(instrumentStrike._id),
        })
      );
    }
  };

  const handleFilter = (value: any) => {
    dispatch(actions.getOptions(value));
  };

  /*
    Set the clicked instrument strike. Modal will open. A mix of the strike option and the instrument itself. This mix will be only created in Options Container, this info in emitted or received orders will be bringed by the db.
  */
  const handleSetInstrumentStrike = (strike: OptionInfoType, instrument: InstrumentType): void => {
    if (operationGroup.length < 3) {
      setInstrumentStrike({
        ...instrument,
        ...strike,
      });
    } else {
      alert("No se pueden agregar más de 3 operaciones");
    }
  };

  //Delete the instrument strike generated when clicked an option strike. Modal will close
  const handleCancel = () => setInstrumentStrike(null);

  //** Use Effects  **//

  //Set the default option
  useEffect(() => {
    handleFilter(options[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //When operation is submitted successfully, clear the operation and redirect.
  useEffect(() => {
    if (operationGroupSubmitted) {
      setInstrumentStrike(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationGroupSubmitted]);

  // ** Formatted data  **//
  //Options for filters
  const options = [
    {
      label: t("futures:options.corn"),
      value: "MAI",
    },
    {
      label: t("futures:options.soy"),
      value: "SOJ",
    },
    {
      label: t("futures:options.wheat"),
      value: "TRI",
    },
  ];

  // column for header of the accordion
  const columns = [
    {
      title: t("dashboard:prices.instrument"),
      dataIndex: "instrument_name",
      key: "instrument_name",
    },
    Table.EXPAND_COLUMN,
  ];

  useEffect(() => {
    const { SELL, BUY } = OPERATION_TYPES;
    const { CALL, PUT } = AGREEMENT_TYPE;
    if (state) {
      setAsociatedAlert(state.id);
      const instrumentString = separateInstrumentAndStrike(state.instrument).instrument;

      const instrument = optionsData?.data.find((o: any) => o._id === instrumentString);
      const strike = instrument?.data.find((i: any) => i.strike === Number(state.strike));
      const agreement = state.instrument.slice(-1);

      if (strike) {
        setOperationType(capitalizeFirstChar(state.operation) !== BUY ? SELL : BUY);
        setAgreementType(agreement === "C" ? CALL : PUT);
        setInstrumentStrike(strike);
      } else {
        console.log("No existe el instrumento en el listado");
      }
    }
  }, [state, optionsData]);

  return (
    <div className="options-container" id="options-container">
      {/* MODAL CALL / PUT */}
      {/* All user flow and iterations about an Option will take place within this modal. */}
      {instrumentStrike && (
        <CallPutModal
          instrumentStrike={instrumentStrike}
          getContainer={document.getElementById("options-container") || false}
          onCancel={handleCancel}
          onConfirm={handleConfirmOperation}
          loading={isSubmitting}
          loadingContrapositions={loadingContraPositions}
          contraPositions={contraPositions}
          onGetContraPosition={getContraPosition}
          agreement={agreementType}
          operationType={operationType}
          setIsSumaryVisible={setIsSumaryVisible}
          tons={tons}
          setTons={setTons}
        />
      )}
      <TableWithFilter
        showHeader={false}
        columns={columns}
        data={optionsData?.data || []}
        lastUpdateDate={optionsData?.last_update}
        loading={isLoading}
        onFilter={handleFilter}
        defaultSelected={options[0].value}
        selectOptions={options}
        rowKey="_id"
        selectId="option-types"
        tableId="options-prices"
        tableClassName="options-prices"
        expandable={{
          expandedRowRender: (record: any) => (
            <ExpandableRow record={record} handleSetInstrumentStrike={handleSetInstrumentStrike} />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <ReactSVG
                src={arrowUp}
                onClick={(e: any) => onExpand(record, e)}
                className="pointer"
              />
            ) : (
              <ReactSVG
                src={arrowDown}
                onClick={(e: any) => onExpand(record, e)}
                className="pointer"
              />
            ),
          expandRowByClick: true,
        }}
        operationGroup={operationGroup}
        setOperationGroup={setOperationGroup}
      />
    </div>
  );
};

export default Options;
