import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions/common";
import { Notification } from "src/types/notifications";

type NotificationsStateType = {
  loading: boolean;
  loadingScreenNotifications: boolean,
  notifications: Notification[] | null;
  screenNotifications: Notification[] | null;
  notificationsCount: number;
  error: null;
};

const initialState: NotificationsStateType = {
  loading: false,
  loadingScreenNotifications: false,
  notifications: null,
  screenNotifications: null,
  notificationsCount: 0,
  error: null,
};

const notifications = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    getCountStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, notificationsCount: null, error: null }),
    getCountSuccess: (state, { payload }: PayloadAction<number>) =>
      updateObject(state, { loading: false, notificationsCount: payload }),
    getCountFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    getNotificationsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, notifications: null, error: null }),
    getNotificationsSuccess: (state, { payload }: PayloadAction<Notification[]>) =>
      updateObject(state, { loading: false, notifications: payload }),
    getNotificationsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    getScreenNotificationsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingScreenNotifications: true, notifications: null, error: null }),
    getScreenNotificationsSuccess: (state, { payload }: PayloadAction<Notification[]>) =>
      updateObject(state, { loadingScreenNotifications: false, screenNotifications: payload }),
    getScreenNotificationsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingScreenNotifications: false, error: payload }),

    markAsCheckedStart: (state) => updateObject(state, { loading: true }),
    markAsCheckedSuccess: (state) => updateObject(state, { loading: false }),
    markAsCheckedFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getCountStart,
  getCountSuccess,
  getCountFail,
  getNotificationsStart,
  getNotificationsSuccess,
  getScreenNotificationsStart,
  getScreenNotificationsSuccess,
  getScreenNotificationsFail,
  getNotificationsFail,
  markAsCheckedStart,
  markAsCheckedSuccess,
  markAsCheckedFail,
} = notifications.actions;

export default notifications;
