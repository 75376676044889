import { arrowDown } from "Assets";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Col, Row } from "antd";
import EMatButton from "src/components/UI/EMatButton/EMatButton";
import { OperationType } from "src/types/operation";

interface Props {
  operationInfo: OperationType;
  onClickConfirm: (operation: OperationType) => void;
  onClickEdit: () => void;
  onFinalize: () => void;
  loading: boolean;
}

const OpenSummary = ({ operationInfo, onClickConfirm, loading, onFinalize }: Props) => {
  const { t } = useTranslation();

  //TODO How to handle the operationType if there is no instrument_name?
  const {
    instrument,
    operation,
    agreementType,
    position,
    bonus,
    priceFrom,
    priceTo,
    quantity,
    strike,
  } = operationInfo;

  return (
    <>
      <h1>{t("operation:common.summary.open-title")}</h1>
      <div className="summary-values">
        <Row gutter={8}>
          <Col span={12} className="label upper">
            {t("operation:options.summary.operation")}
          </Col>
          <Col span={12} className="value">
            {`${t(`operation:options.${operation.toLowerCase()}`)} ${t(
              `operation:options.${agreementType?.toLowerCase()}`
            )}`}
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12} className="label upper">
            {t("operation:options.summary.instrument")}
          </Col>
          <Col span={12} className="value">
            {instrument}
          </Col>
        </Row>
        {quantity && (
          <Row gutter={8}>
            <Col span={12} className="label upper">
              {t("operation:options.summary.quantity")}
            </Col>
            <Col span={12} className="value">
              {quantity} Tn.
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={12} className="label upper">
            {t("operation:options.strike")}
          </Col>
          <Col span={12} className="value">
            {strike} $
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12} className="label upper">
            {t("operation:options.price")}
          </Col>
          <Col span={12} className="value">
            {bonus ? `${bonus} $` : t("operation:options.to-market")}
          </Col>
        </Row>
        {priceFrom && priceTo && (
          <Row gutter={8}>
            <Col span={12} className="label upper">
              {t("operation:options.summary.range")}
            </Col>
            <Col span={12} className="value">
              <span className="range-elipsis">
                {priceFrom} $ / {priceTo} $
              </span>
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={12} className="label upper">
            {t("operation:options.summary.position")}
          </Col>
          <Col span={12} className="value">
            {t(`operation:options.${position.toLowerCase()}`)}{" "}
          </Col>
        </Row>
      </div>

      <div className="buttons-container">
        <EMatButton
          type="primary"
          onClick={() => {
            onClickConfirm(operationInfo);
            onFinalize();
          }}
          loading={loading}
        >
          {t("operation:futures.summary.confirm-order")}
        </EMatButton>
        <EMatButton type="text" id="edit" onClick={() => onClickConfirm(operationInfo)}>
          {t("operation:common.summary.add-new")}
        </EMatButton>
      </div>
    </>
  );
};

export default OpenSummary;
