import { Avatar, Form } from "antd";
import { useTranslation } from "react-i18next";
import { AntSpin, EMatButton, EMatFormItem, EMatInput } from "Components";
import instance from "src/api/axios-instance";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../store/actions";
import "./Profile.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { ChangePassword } from "./ChangePassword";


const Profile = () => {
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.auth.error);
  const loading = useAppSelector((state) => state.users.loading);
  const currentUser = useAppSelector((state) => state.auth.userData);
  const userData = useAppSelector((state) => state.users.userData);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  useEffect(() => {
    if (currentUser) {
      dispatch(actions.getUserById({ id: currentUser.id }));
    }
  }, []);

  const handleClickUpdate = (values: { name: string; lastName: string; company_name: string }) => {
    const { name, lastName, company_name } = values;
    dispatch(
      actions.updateUser({
        firstname: name,
        lastname: lastName,
        company_name,
      })
    );
  };

  return (
    <div className="profile-container submit-container background dark">
      {loading && <AntSpin />}
      {userData && (
        <div className="profile-content">
          <Form
            className=""
            form={form}
            onFinish={handleClickUpdate}
            initialValues={{
              name: userData.firstname,
              lastName: userData.lastname,
              email: userData.email,
              company_name: userData.company_name,
            }}
          >
            <div>
              <Avatar size={64} icon={<UserOutlined />} />
            </div>
            <EMatFormItem
              name="name"
              rules={[
                {
                  required: true,
                  message: t("common:errors:fieldRequiredDefault"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.TEXT}
                id="name"
                placeholder={t("users:signUp:name")}
              />
            </EMatFormItem>
            <EMatFormItem
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("common:errors:fieldRequiredDefault"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.TEXT}
                id="lastName"
                placeholder={t("users:signUp:lastName")}
              />
            </EMatFormItem>
            <EMatFormItem
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("auth:invalidEmail"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                disabled
                eMatType={EMAT_INPUT_TYPES.TEXT}
                id="email"
                placeholder={t("auth:signIn:email")}
              />
            </EMatFormItem>
            <EMatFormItem
              name="company_name"
              rules={[
                {
                  required: true,
                  message: t("common:errors:fieldRequiredDefault"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.TEXT}
                id="company_name"
                placeholder={t("users:signUp.company_name")}
              />
            </EMatFormItem>

            <h1 className="change-password" onClick={() => setModalIsVisible(true)}>
              {t("users:change-password")}
            </h1>
            {error && (
              <div className="error-msg">
                <p>{t(error)}</p>
              </div>
            )}
            <EMatFormItem>
              <EMatButton type="primary" id="submit" htmlType="submit" loading={loading}>
                {t("users:update")}
              </EMatButton>
            </EMatFormItem>
          </Form>
        </div>
      )}
      <ChangePassword visible={modalIsVisible} setVisible={setModalIsVisible} />
    </div>
  );
};

export default withErrorHandler(Profile, instance);
