import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { FirebaseTokenResponse, TokenResponse, User } from "src/types/auth";
import { clearAllStores } from "../actions/common";
interface AuthState {
  token: string | null;
  tokenType: string | null;
  firebaseToken: string | null;
  refreshToken: string | null;
  error: string | null;
  loading: boolean;
  recoverSuccess: boolean;
  isValidToken: boolean | null;
  passwordChanged: boolean;
  userPermissions: string[];
  userData: User | null;
  email: string | null
}

const initialState: AuthState = {
  token: null,
  tokenType: null,
  firebaseToken: null,
  refreshToken: null,
  error: null,
  loading: false,
  recoverSuccess: false,
  isValidToken: null,
  passwordChanged: false,
  userPermissions: [],
  userData: null,
  email: null
};

const auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    signInStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    signInSuccess: (state, { payload }: PayloadAction<TokenResponse>) =>
      updateObject(state, {
        token: payload.token,
        tokenType: payload.tokenType,
        refreshToken: payload.refreshToken,
        userPermissions: payload.userPermissions,
        userData: payload.userData,
        loading: false,
        error: null,
      }),
    signInError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    refreshTokenSuccess: (state, { payload }: PayloadAction<TokenResponse>) =>
      updateObject(state, {
        token: payload.token,
        tokenType: payload.tokenType,
        refreshToken: payload.refreshToken,
        loading: false,
        error: null,
        userPermissions: payload.userPermissions,
      }),

    recoverPasswordStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    recoverPasswordSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        loading: false,
        error: null,
        recoverSuccess: true,
      }),
    recoverPasswordError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loading: false, error: payload }),

    validateTokenStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null, isValidToken: null }),
    validateTokenSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        loading: false,
        error: null,
        isValidToken: true,
      }),
    validateTokenError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loading: false,
        error: payload,
        isValidToken: false,
      }),

    changePasswordStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loading: true,
        error: null,
        passwordChanged: false,
      }),
    changePasswordSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        loading: false,
        error: null,
        passwordChanged: true,
        email: payload.data.email
      }),
    changePasswordError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, {
        loading: false,
        error: payload,
        passwordChanged: false,
      }),
    authCheckStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { checkingAuth: true }),
    authCheckSuccess: (state, { payload }: PayloadAction<TokenResponse>) =>
      updateObject(state, {
        token: payload.token,
        tokenType: payload.tokenType,
        refreshToken: payload.refreshToken,
        error: null,
        userPermissions: payload.userPermissions,
        userData: payload.userData
      }),

    getFirebaseTokenStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    getFirebaseSuccess: (state, { payload }: PayloadAction<FirebaseTokenResponse>) =>
      updateObject(state, {
        firebaseToken: payload.token,
        loading: false,
        error: null,
      }),
    getFirebaseError: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    clearStore: (state, { payload }: PayloadAction) => {
      return updateObject(state, {
        error: null,
        loading: false,
        isValidToken: null,
        passwordChanged: false,
        email: null
      });
    },
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  signInStart,
  signInSuccess,
  signInError,
  refreshTokenSuccess,
  recoverPasswordStart,
  recoverPasswordSuccess,
  recoverPasswordError,
  validateTokenStart,
  validateTokenSuccess,
  validateTokenError,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError,
  authCheckStart,
  authCheckSuccess,
  getFirebaseTokenStart,
  getFirebaseSuccess,
  getFirebaseError,
  clearStore,
} = auth.actions;

export default auth;
