import { EMatTabs, EmatOrderGroupModal } from "Components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "src/api/axios-instance";
import { ROUTE_PATHS } from "src/constants/routePaths";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import Futures from "./Futures/Futures";
import "./Operation.scss";
import Options from "./Options/Options";
import Spreads from "./Spreads/Spreads";
import { useEffect, useState } from "react";

const Operation = (props: any) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [operationGroup, setOperationGroup] = useState<any[]>([]);
  const [isSumaryVisible, setIsSumaryVisible] = useState<boolean>(false);
  const [tons, setTons] = useState<number | null>(null)

  const handleClickTab = (key: string) => {
    navigate(key);
  };

  const tabs = [
    {
      tab: t("operation:tabs.futures"),
      key: ROUTE_PATHS.OPERATION_FUTURES,
      component: (
        <Futures
          operationGroup={operationGroup}
          setOperationGroup={setOperationGroup}
          setIsSumaryVisible={setIsSumaryVisible}
          tons={tons}
          setTons={setTons}
        />
      ),
    },
    {
      tab: t("operation:tabs.options"),
      key: ROUTE_PATHS.OPERATION_OPTIONS,
      component: (
        <Options
          operationGroup={operationGroup}
          setOperationGroup={setOperationGroup}
          setIsSumaryVisible={setIsSumaryVisible}
          tons={tons}
          setTons={setTons}
        />
      ),
    },
    {
      tab: t("operation:tabs.spread"),
      key: ROUTE_PATHS.OPERATION_SPREAD,
      component: (
        <Spreads
          operationGroup={operationGroup}
          setOperationGroup={setOperationGroup}
          setIsSumaryVisible={setIsSumaryVisible}
          tons={tons}
          setTons={setTons}
        />
      ),
    },
  ];

  useEffect(() => {
    if (operationGroup.length === 0) {
      setTons(null)
    }
  }, [operationGroup])

  return (
    <div className="operation-content background light">
      <EmatOrderGroupModal
        orders={operationGroup}
        onCancel={() => setIsSumaryVisible(false)}
        visible={isSumaryVisible}
      />
      <EMatTabs
        defaultActiveKey={!location.pathname ? tabs[0].key : location.pathname}
        size="large"
        className="tab-menu"
        centered
        moreIcon={<div></div>}
        onTabClick={handleClickTab}
        tabs={tabs}
      ></EMatTabs>
    </div>
  );
};

export default withErrorHandler(Operation, instance);
