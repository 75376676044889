import { Form, FormItemProps } from "antd";
import './EMatFormItem.scss';

const EMatFormItem = (props: FormItemProps) => {
  const { children, className, ...rest } = props;
  const classes = "emat-form-item " && className;
  return (
    <Form.Item className={classes} {...rest}>
      {children}
    </Form.Item>
  );
};

export default EMatFormItem;
