import { DefaultOptionType } from "antd/lib/cascader";
import { ColumnsType } from "antd/lib/table";
import { EMatInput } from "Components";
import { DefaultRecordType, ExpandableConfig } from "rc-table/lib/interface";
import { useTranslation } from "react-i18next";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { EMatTable } from "Components";
import "./TableWithFilter.scss";
import moment from "moment";
import { OperationGroup } from "src/containers/Operation/OperationGroup";

const TableWithFilter = ({
  selectOptions,
  onFilter,
  defaultSelected,
  columns,
  data,
  loading,
  selectId,
  tableId,
  rowKey,
  rowClassName,
  lastUpdateDate,
  expandable,
  showHeader,
  expandIconColumnIndex,
  tableClassName,
  operationGroup,
  setOperationGroup,
}: {
  selectOptions: DefaultOptionType[];
  onFilter: (value: any) => void;
  defaultSelected: any;
  columns: ColumnsType<DefaultRecordType>;
  data: any[];
  loading: boolean;
  selectId: string;
  tableId: string;
  rowKey: string;
  lastUpdateDate?: string;
  rowClassName?: string | ((record: any, index: number, indent: number) => string);
  expandable?: ExpandableConfig<any>;
  showHeader?: boolean;
  expandIconColumnIndex?: number;
  tableClassName?: string;
  operationGroup?: any;
  setOperationGroup?: (value: any) => void;
}) => {
  const [t] = useTranslation();

  return (
    <div className="table-with-filters-container">
      <div className="filter-container">
        <EMatInput
          className="select-filter"
          id={selectId}
          defaultActiveFirstOption={true}
          eMatType={EMAT_INPUT_TYPES.SELECT}
          options={selectOptions}
          onSelect={onFilter}
          defaultValue={defaultSelected}
        />
        {operationGroup && (
          <OperationGroup className="op-group" operationGroup={operationGroup} setOperationGroup={setOperationGroup} />
        )}
      </div>
      <EMatTable
        id={tableId}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={rowKey}
        rowClassName={rowClassName}
        pagination={{
          position: ["bottomRight"],
          pageSize: 6,
        }}
        expandable={expandable}
        showHeader={showHeader}
        expandIconColumnIndex={expandIconColumnIndex}
        className={tableClassName ? tableClassName : ""}
        footer={
          lastUpdateDate
            ? () => (
                <h3 id="update-date">
                  {t("common:last-update")}{" "}
                  {moment.utc(lastUpdateDate).local().format("HH:mm DD/MM/YY")}
                </h3>
              )
            : undefined
        }
      />
    </div>
  );
};

export default TableWithFilter;
