import instance from "src/api/axios-instance";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import "./Clients.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import * as actions from "../../store/actions";
import { EMatButton, EMatTable } from "Components";
import { Col, Row } from "antd";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clients = useAppSelector<any>((state) => state.users.users || []);
  const loading = useAppSelector<boolean>((state) => state.users.loading);

  useEffect(() => {
    dispatch(actions.getUsers());
  }, [dispatch]);

  const columns = [
    {
      title: t("users:signUp.company_name"),
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: t("users:signUp.name"),
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: t("users:signUp.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("users:signUp.concomitent_number"),
      dataIndex: "concomitent_number",
      key: "concomitent_number",
    },
  ];

  return (
    <div className="content clients-container background light-3" id="clients-container">
      <Row gutter={16}>
        <div className="filter-positions-row">
          <h1>{t("users:title")}</h1>
          <div className="filter">
            <EMatButton type="primary" onClick={() => navigate(ROUTE_PATHS.SIGN_UP)}>
              {t("common:actions.signUp")}
            </EMatButton>
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={24}>
          <div className="prices-container">
            <EMatTable
              id={"tableId"}
              columns={columns}
              dataSource={clients}
              loading={loading}
              rowKey="_id"
              pagination={{
                position: ["bottomRight"],
                pageSize: 13,
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorHandler(Clients, instance);
