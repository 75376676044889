import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import OrderCard from "../../OrderCard/OrderCard";
import ReceivedOrdersActions from "./ReceivedOrdersActions";
import { Order } from "src/types/order";
import IssuedOrdersActions from "./IssuedOrdersActions";
const OrderCardsList = (props: {
  orders: any;
  ordersData: Order[];
  description: string;
  isReceived: boolean | undefined;
  status: number;
  clientId: string;
  isOwner: boolean;
}) => {
  const [t] = useTranslation();

  const { orders, description, isReceived, status, clientId, ordersData, isOwner } = props;

  return (
    <div>
      <Row gutter={16}>
        {orders.map((order: any, i: number) => (
          <Col xs={24} lg={12} className="col-order" key={i}>
            <OrderCard data={order}>
              {isReceived ? (
                <ReceivedOrdersActions
                  status={status}
                  clientId={clientId}
                  ordersData={ordersData[i]}
                  order={order}
                  isOwner={isOwner}
                />
              ) : (
                <IssuedOrdersActions
                  status={status}
                  order={order}
                  ordersData={ordersData[i]}
                  isOwner={isOwner}
                />
              )}
            </OrderCard>
          </Col>
        ))}
      </Row>
      {description && (
        <p>
          {t("issued_orders:ordersList.comment")}
          {description}
        </p>
      )}
    </div>
  );
};

export default OrderCardsList;
