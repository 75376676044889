import {
  Checkbox,
  CheckboxProps,
  Input,
  InputNumberProps,
  InputProps,
  Radio,
  RadioProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
} from "antd";
import "./EMatInput.scss";
import EMAT_INPUT_TYPES from "../../../constants/EMatInputTypes";

type EMatInputProps = {
  eMatType: string;
  prefixValue?: string;
  setPrefixValue?: (value: string) => void;
};

const EMatInput = (
  props: InputProps &
    SelectProps &
    EMatInputProps &
    SwitchProps &
    CheckboxProps &
    InputNumberProps &
    RadioProps
) => {
  const { eMatType, className, prefixValue, setPrefixValue, ...rest } = props;
  let classes = "emat-input ";
  let element = null;
  const { Option } = Select;

  if (className) classes = classes + className;

  switch (eMatType) {
    case EMAT_INPUT_TYPES.TEXT:
      element = <Input className={classes} {...rest} type="text" />;
      break;
    case EMAT_INPUT_TYPES.PASSWORD:
      element = <Input.Password className={classes} {...rest} type="password" />;
      break;
    case EMAT_INPUT_TYPES.SELECT:
      element = <Select className={classes} {...rest} />;
      break;
    case EMAT_INPUT_TYPES.SWITCH:
      element = <Switch className={classes} {...rest} />;
      break;
    case EMAT_INPUT_TYPES.CHECKBOX:
      element = <Checkbox className={classes} {...rest} />;
      break;
    case EMAT_INPUT_TYPES.NUMBER:
      element = (
        <Input
          onKeyDown={(e) => e.key === "e" && e.preventDefault()}
          type={EMAT_INPUT_TYPES.NUMBER}
          className={classes}
          {...rest}
          lang="en"
        />
      );
      break;
    case EMAT_INPUT_TYPES.RADIO:
      element = <Radio className={classes} {...rest} />;
      break;

    case EMAT_INPUT_TYPES.PHONE_NUMBER:
      element = (
        <Input.Group {...rest} compact>
          <Select
            style={{ width: "30%", textAlign: "left", fontSize: 15 }}
            className={classes}
            value={prefixValue}
            onChange={setPrefixValue}
            defaultValue="Argentina"
          >
            <Option value="+54">(ARG) +54</Option>
            <Option value="+598">(URU) +598</Option>
            <Option value="+595">(PAR) +595</Option>
            <Option value="+55">(BRA) +55</Option>
          </Select>
          <Input
            style={{ width: "70%", borderRadius: "0 5px 5px 0", textAlign: "left" }}
            className={classes}
            {...rest}
          />
        </Input.Group>
      );
      break;
    default:
      element = <Input className={classes} {...rest} />;
  }
  return element;
};

export default EMatInput;
