import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { EMatButton, EMatFormItem, EMatInput, EMatLogo } from "Components";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../../store/actions/auth";
import * as sliceActions from "../../../store/slices/auth";
import "../common/auth.scss";
import "./ChangePassword.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { Spinner } from "Components";
import getDeviceId from "src/helpers/getDeviceId";

const ChangePassword = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.auth.error);
  const loading = useAppSelector((state) => state.auth.loading);
  const isValidToken = useAppSelector((state) => state.auth.isValidToken);
  const passwordChanged = useAppSelector((state) => state.auth.passwordChanged);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const isAuth = useAppSelector((state) => !!state.auth.token);
  const email = useAppSelector((state) => state.auth.email);


  const handleSubmit = (values: any) => {
    dispatch(actions.changePassword({ ...values, token }));
  };

  const handleClickCancel = () => {
    navigate(ROUTE_PATHS.LOGIN);
  };

  const handleClickSignIn = () => {
    navigate(ROUTE_PATHS.LOGIN);
  };

  const handleClickResend = () => {
    navigate(ROUTE_PATHS.RECOVER_PASSWORD);
  };

  useEffect(() => {
    token && dispatch(actions.validateToken(token));
    return () => {
      dispatch(sliceActions.clearStore());
    };
  }, []);

  useEffect(() => {
    if (passwordChanged && email) {
      dispatch(actions.signIn({ email, password: form.getFieldValue("password"), device_id: getDeviceId() }))
    }
  }, [passwordChanged, email])

  useEffect(() => {
    if (isAuth) navigate(ROUTE_PATHS.DASHBOARD);
  }, [isAuth]);

  return (
    <div className="auth-container change-password-container background dark">
      {loading ? (
        <Spinner className="global-spinner" spinning={true} size="large" />
      ) : (
        <div className="auth-content change-password-content">
          <EMatLogo />
          {isValidToken || isValidToken === null ? (
            passwordChanged ? (
              <Form
                className="change-password-form"
                form={form}
                onFinish={handleSubmit}
              >
                <EMatFormItem
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("common:errors:fieldRequiredDefault"),
                    },
                    {
                      min: 6,
                      message: t("auth:changePassword.passwordMinLength"),
                    },
                  ]}
                  validateTrigger={["onBlur"]}
                >
                  <EMatInput
                    eMatType={EMAT_INPUT_TYPES.PASSWORD}
                    id="password"
                    placeholder={t("auth:changePassword.newPassword")}
                  />
                </EMatFormItem>
                <EMatFormItem
                  name="password_confirmation"
                  rules={[
                    {
                      required: true,
                      message: t("common:errors:fieldRequiredDefault"),
                    },
                    {
                      min: 6,
                      message: t("auth:changePassword.passwordMinLength"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("auth:changePassword.passwordDontMatch")
                        );
                      },
                    }),
                  ]}
                  validateTrigger={["onBlur"]}
                >
                  <EMatInput
                    eMatType="password"
                    id="newPassword"
                    placeholder={t("auth:changePassword.repeatPassword")}
                  />
                </EMatFormItem>
                {error && (
                  <div className="error-msg">
                    <p>{t(error)}</p>
                  </div>
                )}
                <EMatFormItem className="buttons-container">
                  <EMatButton
                    type="text"
                    id="cancel"
                    onClick={handleClickCancel}
                  >
                    {t("common:actions.cancel")}
                  </EMatButton>
                  <EMatButton
                    type="primary"
                    id="submit"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("common:actions.save")}
                  </EMatButton>
                </EMatFormItem>
              </Form>
            ) : (
              <Result
                status="success"
                subTitle={t("auth:changePassword.success")}
                // extra={[
                //   <EMatButton
                //     type="primary"
                //     id="go-to-sign-in"
                //     onClick={handleClickSignIn}
                //   >
                //     {t("auth:goToSignIn")}
                //   </EMatButton>,
                // ]}
              />
            )
          ) : (
            <Result
              status="error"
              subTitle={t("auth:changePassword.expiredToken")}
              extra={[
                <EMatButton
                  type="primary"
                  id="go-to-sign-in"
                  onClick={handleClickResend}
                >
                  {t("auth:changePassword.resendEmail")}
                </EMatButton>,
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
