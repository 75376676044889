import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions/common";
import { CommissionType } from "src/types/commissions";
import { ClientType } from "src/types/monitor";

const initialState = {
  loadingCommissions: false,
  commissionList: null,
  error: null,
  loadingInput: false,
  masterClients: null,
  commisionsCsv: null,
  loadingCsv: false,
};

const commissions = createSlice({
  name: "commissions",
  initialState,
  reducers: {
    getCommissionsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingCommissions: true,
        commissionList: null,
        error: null,
      }),
    getCommissionsSuccess: (state, { payload }: PayloadAction<CommissionType[]>) =>
      updateObject(state, {
        loadingCommissions: false,
        commissionList: payload,
      }),
    getCommissionsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingCommissions: false, error: payload }),

    getMasterClientsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingInput: true,
        masterClients: null,
        error: null,
      }),
    getMasterClientsSuccess: (state, { payload }: PayloadAction<ClientType[]>) =>
      updateObject(state, {
        loadingInput: false,
        masterClients: payload,
      }),
    getMasterClientsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingInput: false, error: payload }),

    getCommissionsCsvStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingCsv: true }),
    getCommissionsCsvSuccess: (state, { payload }: PayloadAction<any>) =>
    
      updateObject(state, { commisionsCsv: payload, loadingCsv: false }),
    getCommissionsCsvFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingCsv: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getCommissionsStart,
  getCommissionsSuccess,
  getCommissionsFail,
  getMasterClientsStart,
  getMasterClientsSuccess,
  getMasterClientsFail,
  getCommissionsCsvFail,
  getCommissionsCsvStart,
  getCommissionsCsvSuccess,
} = commissions.actions;

export default commissions;
