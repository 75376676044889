import { useEffect } from "react";
import { Form, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { EMatButton, EMatFormItem, EMatInput, EMatLogo } from "Components";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../../store/actions/auth";
import * as sliceActions from "../../../store/slices/auth";
import "../common/auth.scss";
import "./RecoverPassword.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";

const RecoverPassword = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.auth.error);
  const loading = useAppSelector((state) => state.auth.loading);
  const recoverSuccess = useAppSelector((state) => state.auth.recoverSuccess);
  const isAuth = useAppSelector((state) => !!state.auth.token);

  const handleSubmit = (values: any) => {
    dispatch(actions.recoverPassword(values.email));
  };

  const handleClickCancel = () => {
    navigate(ROUTE_PATHS.LOGIN);
  };

  const handleClickSignIn = () => {
    navigate(ROUTE_PATHS.LOGIN);
  };

  useEffect(() => {
    return () => {
      dispatch(sliceActions.clearStore());
    };
  }, []);

  useEffect(() => {
    if (isAuth) navigate(ROUTE_PATHS.DASHBOARD);
  }, [isAuth]);

  return (
    <div className="auth-container recover-password-container background dark">
      <div className="auth-content recover-password-content">
        <EMatLogo />
        {!recoverSuccess ? (
          <Form
            className="reset-password-form"
            form={form}
            onFinish={handleSubmit}
          >
            <EMatFormItem
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("auth:invalidEmail"),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.TEXT}
                id="email"
                placeholder={t("auth:resetPassword.email")}
              />
            </EMatFormItem>
            {error && (
              <div className="error-msg">
                <p>{t(error)}</p>
              </div>
            )}
            <EMatFormItem className="buttons-container">
              <EMatButton type="text" id="cancel" onClick={handleClickCancel}>
                {t("common:actions.cancel")}
              </EMatButton>
              <EMatButton
                type="primary"
                id="submit"
                htmlType="submit"
                loading={loading}
              >
                {t("auth:resetPassword.sendEmail")}
              </EMatButton>
            </EMatFormItem>
          </Form>
        ) : (
          <Result
            status="success"
            subTitle={t("auth:resetPassword.success")}
            extra={[
              <EMatButton
                type="primary"
                id="go-to-sign-in"
                onClick={handleClickSignIn}
              >
                {t("auth:goToSignIn")}
              </EMatButton>,
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default RecoverPassword;
