import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "src/api/axios-instance";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getFutures } from "src/store/actions/operation";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import { Col, Row } from "antd";
import Orders from "./Orders/Orders";
import Prices from "./Prices/Prices";
import Alert from "./Alert/Alert";
import * as actions from "../../store/actions";
import "./Dashboard.scss";
import { FuturesType } from "src/types/operation";

const refresh_interval = parseInt(process.env.REACT_APP_REFETCH_INTERVAL || "60000");

const Dashboard = () => {
  const [t] = useTranslation();
  const futures = useAppSelector<FuturesType | null>((state) => state.operation.futures);
  const loadingFutures = useAppSelector((state) => state.operation.loadingFutures);
  const loadingOrders = useAppSelector<boolean>((state) => state.operation.loadingOrders);
  const count = useAppSelector((state) => state.operation.count);
  const dispatch = useAppDispatch();
  const [selectedType, setSelectedType] = useState("MAI")

  const options = [
    {
      label: t("futures:options.corn"),
      value: "MAI",
    },
    {
      label: t("futures:options.soy"),
      value: "SOJ",
    },
    {
      label: t("futures:options.wheat"),
      value: "TRI",
    },
  ];

  const handleSelectType = (value: any) => {
    setSelectedType(value)
  };

  useEffect(() => {
    const apiCalls = () => {
      dispatch(getFutures(selectedType));
      dispatch(actions.getCount());
    };
    apiCalls();
    const interval = setInterval(() => {
      apiCalls();
    }, refresh_interval);

    return () => clearInterval(interval);
  }, [dispatch, selectedType]);

  return (
    <div className="main-content dashboard-content background dark">
      <Row gutter={16}>
        <Col xs={24} md={12} className="mb-16">
          <Alert />
        </Col>
        <Col xs={24} md={12} className="mb-16">
          <Orders loading={loadingOrders} emitted={count.emitted} received={count.received} />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Prices
            data={futures}
            loading={loadingFutures}
            onSelectType={handleSelectType}
            typeOptions={options}
            defaultSelected={options[0].value}
          />
        </Col>
      </Row>
    </div>
  );
};

export default withErrorHandler(Dashboard, instance);
