import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import { NotificationsBell, Spinner } from "Components";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  UserOutlined,
  BarChartOutlined,
  InteractionOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  UsergroupAddOutlined,
  AlertOutlined,
  MonitorOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import * as actions from "../../store/actions";
import EMatLogo from "src/components/UI/Logo/EMatLogo";
import { ROUTE_PATHS } from "src/constants/routePaths";
import "./LayoutHoc.scss";
import PERMISSIONS, { USER_ROLES } from "src/constants/permissions";
import useWindowSize from "src/hooks/useWindowSize";
import useLockBodyScroll from "src/hooks/useLockBodyScroll";
import { hasPermissions } from "src/common/utils";
import Offline from "../Offline/Offline";
import Notification from "src/components/Notification/Notification";

type LayoutProps = {
  children: ReactNode;
  isAuth?: boolean;
};

const refresh_interval = parseInt(process.env.REACT_APP_REFETCH_INTERVAL || "60000");

const LayoutHoc = (props: LayoutProps) => {
  const { children } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isBroken, setIsBroken] = useState(false);
  const { Header, Sider, Content } = Layout;
  const [t] = useTranslation();
  const isAuth = useAppSelector((state) => !!state?.auth?.token);
  const data = useAppSelector((state) => state.users.userData);
  const isLoading = useAppSelector((state) => state?.spinner?.isLoading);
  const selectedPage = useAppSelector((state) => state?.layout?.selectedPage);
  const userPermissions = useAppSelector((state) => state.auth.userPermissions);
  const userData = useAppSelector((state) => state.auth.userData);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { width: windowWidth } = useWindowSize();
  const { lockScroll, unlockScroll, isLocked } = useLockBodyScroll();

  const toggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleClickLogout = () => {
    navigate(ROUTE_PATHS.LOGOUT, { replace: true });
  };

  const handleBreakpoint = (broken: boolean) => {
    if (broken) {
      setIsCollapsed(true);
      setIsBroken(true);
    } else {
      setIsBroken(false);
    }
  };

  const handleClickMenuItem = (routePath: string) => {
    navigate(routePath);
    isBroken && setIsCollapsed(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="sign-up" onClick={() => navigate(ROUTE_PATHS.PROFILE)}>
        <span className="logout-menu">{t("common:actions.profile")}</span>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleClickLogout}>
        <span className="logout-menu">{t("common:actions.logout")}</span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!isLoading && location.pathname.search("/auth") === -1) {
      //if pathname is /home/dashboard nameKey is home-dashboard
      const nameKey = location.pathname.substring(1).replace(new RegExp("/", "g"), () => "-");
      dispatch(
        actions.onSelectMenu({
          selectedPage: t(`common:pages.${nameKey}`),
          selectedPath: location.pathname,
        })
      );
    }
  }, [location]);

  // Para evitar el scroll cuando el menu mobile esta abierto
  useEffect(() => {
    /* 
      -isBroken nos determina que estamos en mobile
      -isCollapsed nos determina que el menu esta cerrado
    */
    if (!isBroken && !isLocked) {
      return;
    }

    //No esta lockeado, en mobile y menu abierto, lockeo scroll.
    !isLocked && isBroken && !isCollapsed && lockScroll();

    //Esta lockeado, en mobile y menu cerrado, deslockeo scroll.
    isLocked && isBroken && isCollapsed && unlockScroll();

    //Menu de Desktop y scroll lockeado, deslockeo scroll.
    !isBroken && isLocked && unlockScroll();
  }, [isCollapsed, isBroken, isLocked]);

  const menuItems = [
    {
      name: t("common:pages.dashboard").toUpperCase(),
      route: ROUTE_PATHS.DASHBOARD,
      permissions: PERMISSIONS.DASHBOARD,
      icon: <BarChartOutlined />,
    },
    {
      name: t("common:pages.operation").toUpperCase(),
      route: ROUTE_PATHS.OPERATION_FUTURES,
      permissions: PERMISSIONS.OPERATION,
      icon: <InteractionOutlined />,
    },
    {
      name: t("common:pages.issued-orders").toUpperCase(),
      route: ROUTE_PATHS.ISSUED_ORDERS_CURRENT,
      permissions: PERMISSIONS.ISSUED_ORDERS,
      icon: <SolutionOutlined />,
    },
    {
      name: t("common:pages.received-orders").toUpperCase(),
      route: ROUTE_PATHS.RECEIVED_ORDERS,
      permissions: PERMISSIONS.RECEIVED_ORDERS,
      icon: <FileDoneOutlined />,
    },
    {
      name: t("common:pages.own-accounts").toUpperCase(),
      route: ROUTE_PATHS.OWN_ACCOUNTS_OPEN,
      permissions: PERMISSIONS.OWN_ACCOUNTS,
      icon: <UserOutlined />,
    },
    {
      name: t("common:pages.clients-accounts").toUpperCase(),
      route: ROUTE_PATHS.CLIENTS_ACCOUNTS_OPEN,
      permissions: PERMISSIONS.CLIENTS_ACCOUNTS,
      icon: <UsergroupAddOutlined />,
    },
    {
      name: t("common:pages.alerts").toUpperCase(),
      route: ROUTE_PATHS.ALERTS,
      permissions: PERMISSIONS.ALERTS,
      icon: <AlertOutlined />,
    },
    {
      name: t("common:pages.monitor").toUpperCase(),
      route: ROUTE_PATHS.MONITOR,
      permissions: PERMISSIONS.MONITOR,
      icon: <MonitorOutlined />,
    },
    {
      name: t("common:pages.commissions").toUpperCase(),
      route: ROUTE_PATHS.COMMISSIONS,
      permissions: PERMISSIONS.COMMISSIONS,
      icon: <MonitorOutlined />,
    },
    {
      name: t("common:pages.clients").toUpperCase(),
      route: ROUTE_PATHS.CLIENTS,
      permissions: PERMISSIONS.CLIENTS,
      icon: <UserSwitchOutlined />,
    },
  ];

  /* 
  DASHBOARD - OPERAR - ORDENES EMITIDAS - ORDENES RECIBIDAS - CUENTAS CLIENTES - CUENTAS PROPIAS - ALERTAS - MONITOR
  Estas son las opciones que van al sidebar (varia segun roles)
  */

  //TODO - Componetizar algunos elementos que estan aca.

  useEffect(() => {
    const checked = false;
    let interval: NodeJS.Timeout;
    setTimeout(() => {
      dispatch(actions.getNotifications(checked));
      interval = setInterval(() => {
        dispatch(actions.getNotifications(checked));
      }, refresh_interval * 5);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (userData && userData.id) {
        dispatch(actions.getUserById({ id: userData.id }));
      }
    }, 1000);
  }, [userData]);

  return (
    <div className="wrapper">
      {isLoading && <Spinner className="global-spinner" spinning={true} size="large" />}
      <div className="theme-wrapper theme-default ">
        <Offline>
          <Layout id="components-layout-custom-trigger">
            {isAuth && (
              <Sider
                trigger={null}
                collapsible
                collapsed={isCollapsed}
                className="site-layout-background sider"
                breakpoint="lg"
                onBreakpoint={handleBreakpoint}
                width={windowWidth > 1200 ? 300 : 250}
              >
                <div className="sider-trigger">
                  <EMatLogo collapsed={isCollapsed} />
                </div>

                <Menu theme="dark" mode="inline" selectedKeys={[location.pathname.split("/")[1]]}>
                  {menuItems.map(
                    (item) =>
                      hasPermissions(userPermissions, item.permissions) && (
                        <Menu.Item
                          key={item.route.split("/")[1]}
                          onClick={() => handleClickMenuItem(item.route)}
                          icon={item.icon}
                          title={null}
                        >
                          {item.name}
                        </Menu.Item>
                      )
                  )}
                </Menu>
              </Sider>
            )}
            {/* Muestro el overlay para colapasar el sidebar */}
            {isBroken && !isCollapsed && <div className="close-sidebar-overlay" onClick={toggle} />}

            <Layout className="site-layout">
              {isAuth && (
                <Header className="site-layout-background header" style={{ padding: 0 }}>
                  <Notification />
                  <div>
                    {!isBroken ? (
                      isCollapsed ? (
                        <MenuUnfoldOutlined className="trigger" onClick={toggle} />
                      ) : (
                        <MenuFoldOutlined className="trigger" onClick={toggle} />
                      )
                    ) : (
                      <MenuOutlined className="trigger-with-bg" onClick={toggle} />
                    )}
                    <h1 className="selected-page">{selectedPage && selectedPage.toUpperCase()}</h1>
                  </div>
                  <div id="user-menu-container">
                    <NotificationsBell />
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomLeft"
                      className="user-menu"
                      getPopupContainer={() =>
                        document.getElementById("user-menu-container") as HTMLElement
                      }
                    >
                      <div onClick={(e) => e.preventDefault()}>
                        <Avatar size="large" icon={<UserOutlined />} />
                      </div>
                    </Dropdown>
                    {data && <h3 className="user-name">{data.firstname}</h3>}
                  </div>
                </Header>
              )}
              <Content>{children}</Content>
            </Layout>
          </Layout>
        </Offline>
      </div>
    </div>
  );
};

export default LayoutHoc;
