import { Checkbox, Col, Row } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { arrowDown } from "Assets";
import { EMatButton, EMatTable, Spinner } from "Components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { OPERATION_TYPES, OPTION_TYPES } from "src/constants/operation";
import FailSummary from "./FailSummary";
import { formatCurrency } from "src/helpers/formatCurrency";

const autoSetPositions = ({
  operation,
  contraPositions,
  setTotalQuantity,
  setSelectedRowKeys,
  setSelectedContrapositions,
  maxQuantity,
}: any) => {
  let index = 0;
  let selectedContrapositions = [];

  for (let quantity = 0; quantity < maxQuantity; ) {
    quantity = quantity + contraPositions[index].order_quantity;
    selectedContrapositions.push(contraPositions[index]);
    setTotalQuantity(quantity);
    index++;
  }
  setSelectedRowKeys(selectedContrapositions.map((c) => c.order_id));
  setSelectedContrapositions(selectedContrapositions);
};

const CloseSummary = ({
  operation,
  contraPositions,
  onClickConfirm,
  onClickEdit,
  submitting,
  onClickGoToOperate,
  optionType,
  onFinalize,
}: any) => {
  const [t] = useTranslation();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
  const [showError, setShowError] = useState(false);
  const [selectedContrapositions, setSelectedContrapositions] = useState<any[]>([]);
  const [autoCancel, setAutoCancel] = useState<boolean>(true);
  const [maxQuantity, setMaxQuantity] = useState(0);

  const orderDesend: SortOrder = "descend";

  useEffect(() => {
    if (contraPositions && contraPositions.length > 0) {
      let max = 0;
      contraPositions.forEach((p: any) => (max += p.order_quantity));
      if (operation.quantity < max) {
        setMaxQuantity(operation.quantity);
      } else {
        setMaxQuantity(max);
      }

      autoSetPositions({
        operation,
        contraPositions,
        setTotalQuantity,
        setSelectedRowKeys,
        setSelectedContrapositions,
        maxQuantity,
      });
    }
  }, [contraPositions, operation, maxQuantity]);

  const columns = [
    {
      title: t("operation:common.quantity").toUpperCase(),
      dataIndex: "order_quantity",
      key: "order_quantity",
      render: (text: any, row: object, index: number) => text && text + " Tn.",
    },
    {
      title: t("operation:common.price.future").toUpperCase(),
      dataIndex: "price",
      key: "price",
      render: (text: any, row: object, index: number) => text + " $",
      sorter: (a: any, b: any) => a.price - b.price,
      defaultSortOrder: orderDesend,
    },
  ];

  const handleSelectRow = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedContrapositions = contraPositions.filter((c: any) =>
      selectedRowKeys.includes(c.order_id)
    );
    let total = 0;
    selectedContrapositions.forEach((c: any) => {
      total += c.order_quantity;
    });
    setTotalQuantity(total);
    total === operation.quantity && setShowError(false);
    setSelectedContrapositions(selectedContrapositions);
  };

  const handleClickConfirm = () => {
    if (totalQuantity === Number(operation.quantity)) {
      const arrayContra: any = [];
      selectedContrapositions.forEach((object) => {
        arrayContra.push({
          price: object.price,
          quantity: object.order_quantity,
          order_id: object.order_id,
        });
      });
      operation.contraposition = arrayContra;
      onClickConfirm(operation);
    } else {
      setShowError(true);
    }
  };

  const handleCheckAutoCancel = () => {
    if (autoCancel) {
      setSelectedContrapositions([]);
      setSelectedRowKeys([]);
      setTotalQuantity(0);
    } else {
      autoSetPositions({
        operation,
        contraPositions,
        setTotalQuantity,
        setSelectedRowKeys,
        setSelectedContrapositions,
        maxQuantity,
      });
    }
    setAutoCancel(!autoCancel);
  };

  return (
    <>
      {!contraPositions && <Spinner className="close" spinning={true} size="large" />}
      {contraPositions?.length === 0 ? (
        <FailSummary onClickGoToOperate={onClickGoToOperate} onClickEdit={onClickEdit} />
      ) : (
        <>
          <div className="summary-values close">
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.operation")}
              </Col>
              <Col span={12} className="value">
                {t(`operation:common.summary.${operation?.operation.toLowerCase()}`) +
                  " " +
                  t(`operation:operation-options.${operation.optionType.toLowerCase()}`)}
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.instrument")}
              </Col>
              <Col span={12} className="value">
                {operation?.instrument}
              </Col>
            </Row>
            {operation?.quantity && (
              <Row gutter={8}>
                <Col span={12} className="label">
                  {t("operation:common.summary.quantity")}
                </Col>
                <Col span={12} className="value">
                  {operation?.quantity} Tn.
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              <Col span={12} className="label">
                {optionType === OPTION_TYPES.FUTURE
                  ? t("operation:common.summary.price.future")
                  : t("operation:common.summary.price.spread")}
              </Col>
              <Col span={12} className="value">
              {operation.price
              ? `${formatCurrency(operation.price)} US$`
              : t("operation:common.to-market")}
              </Col>
            </Row>
            {operation?.priceFrom && operation?.priceTo && (
              <Row gutter={8}>
                <Col span={12} className="label">
                  {t("operation:common.summary.range")}
                </Col>
                <Col span={12} className="value">
                  <span className="range-elipsis">
                    {formatCurrency(operation.priceFrom)} $ / {formatCurrency(operation.priceTo)} $
                  </span>
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              <Col span={12} className="label">
                {t("operation:common.summary.position")}
              </Col>
              <Col span={12} className="value">
                {" "}
                {t(`operation:common.${operation.position.toLowerCase()}`)}{" "}
              </Col>
            </Row>
          </div>
          <div className="close-operations">
            <h1>
              {t(
                `operation:common.summary.${
                  operation?.operation === OPERATION_TYPES.BUY ? "sell" : "buy"
                }`
              ) +
                " " +
                operation?.instrument}
            </h1>
            <EMatTable
              id="contra-positions"
              columns={columns}
              dataSource={contraPositions}
              rowKey={"order_id"}
              pagination={false}
              rowSelection={{
                selectedRowKeys,
                onChange: handleSelectRow,
                getCheckboxProps: (record) => ({
                  disabled: autoCancel,
                  key: record.key,
                }),
              }}
              scroll={{ y: 300 }}
            />
            <Row className="auto-cancellation">
              <Checkbox checked={autoCancel} onClick={() => handleCheckAutoCancel()} />
              <p>Cancelación automatica</p>
            </Row>
          </div>
          {showError && (
            <div className="error-msg">
              <p>{t("operation:common.summary.insufficient-quantity")}</p>
            </div>
          )}
          <div className="buttons-container close">
            <EMatButton
              type="primary"
              onClick={() => {
                handleClickConfirm();
                onFinalize();
              }}
              loading={submitting}
            >
              {t("operation:common.summary.confirm-order")}
            </EMatButton>
            <EMatButton type="text" id="edit" onClick={handleClickConfirm}>
              {t("operation:common.summary.add-new")}
            </EMatButton>
          </div>
        </>
      )}
    </>
  );
};

export default CloseSummary;
