import { takeLatest } from "redux-saga/effects";

import * as actions from "../../actions";
import { deleteAlertSagga, getAlertsSaga, getInstrumentsSaga, submitAlertSaga } from "./alerts";

export function* watchAlertsSaga() {
  yield takeLatest(actions.getAlerts.type, getAlertsSaga);
  yield takeLatest(actions.getInstruments.type, getInstrumentsSaga);
  yield takeLatest(actions.submitAlert.type, submitAlertSaga);
  yield takeLatest(actions.deleteAlert.type, deleteAlertSagga);
}
