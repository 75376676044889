import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { EMatButton, EMatInput, OrderCard } from "Components";
import AntSpin from "src/components/UI/AntSpinner/AntSpin";
import AlertFormModal from "./AlertFormModal/AlertFormModal";
import instance from "src/api/axios-instance";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { AlertType, AlertBodyType } from "src/types/alerts";
import * as actions from "../../store/actions";
import "./Alerts.scss";
import { ALERT_TYPES, OPTION_TYPES } from "src/constants/operation";
import { capitalizeFirstChar } from "src/helpers/capiitalizeFirstChar";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { DefaultOptionType } from "antd/lib/cascader";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { formatCurrency } from "src/helpers/formatCurrency";

const Alerts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [alertToUpdate, setAlertToUpdate] = useState<AlertType>();
  const alertList = useAppSelector<AlertType[] | null>((state) => state.alerts.alertList);
  const loadingAlerts = useAppSelector<boolean>((state) => state.alerts.loadingAlerts);
  const [selectedId, setSelectedId] = useState("MAI");
  const [grainFilter, setGrainFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(0);
  const [alertType, setAlerType] = useState<string>(ALERT_TYPES.FUTURE);
  const [t] = useTranslation();

  const grainOptions = [
    {
      label: t("futures:options.all"),
      value: "",
    },
    {
      label: t("futures:options.corn"),
      value: "MAI",
    },
    {
      label: t("futures:options.soy"),
      value: "SOJ",
    },
    {
      label: t("futures:options.wheat"),
      value: "TRI",
    },
  ];

  const statusOptions = [
    {
      label: t("alerts:statusFilter.all"),
      value: 0,
    },
    {
      label: t("alerts:statusFilter.activated"),
      value: 3,
    },
    {
      label: t("alerts:statusFilter.not-activated"),
      value: 2,
    },
  ];

  const dataList = useMemo(() => {
    return alertList?.map((alert: any) => {
      const dataArr = [
        {
          label: t("common:labels.operation"),
          value: `${t(`operation:common.${alert.operation.toLowerCase()}`)} ${t(
            `operation:common.option-type.${alert.option_type.toLowerCase()}`
          )}`,
        },
        {
          label: t("common:labels.instrument"),
          value: <strong>{alert.instrument_name}</strong>,
        },
        {
          label: "isActive",
          value: alert.idAlertStatus_FK === 3,
        },
        {
          label: "id",
          value: alert.id,
        },
      ];
      alert.price &&
        dataArr.push({
          label: t("common:labels.price"),
          value: `${formatCurrency(alert.price)} US$`,
        });
      alert.strike &&
        dataArr.push({
          label: t("common:labels.strike"),
          value: `${formatCurrency(alert.strike)} US$`,
        });
      alert.bonus &&
        dataArr.push({
          label: t("common:labels.bonus"),
          value: `${formatCurrency(alert.bonus)} US$`,
        });
      alert.actual_price &&
        dataArr.push({
          label: t("common:labels.today-price"),
          value: `${formatCurrency(alert.actual_price)} US$`,
        });
      alert.idAlertStatus_FK &&
        dataArr.push({
          label: t("common:labels.status"),
          value: `${t(`alerts:status.${alert.idAlertStatus_FK}`)}`,
        });

      return dataArr;
    });
  }, [alertList, t]);

  const handleClickAdd = () => {
    setShowFormModal(true);
  };

  const handleClickEdit = (index: any) => {
    if (alertList && alertList?.length > 0) {
      setAlertToUpdate(alertList[index]);
      setShowFormModal(true);
    }
  };

  const handleClickDelete = (index: any) => {
    if (alertList && alertList?.length > 0) {
      const { id } = alertList[index];
      dispatch(actions.deleteAlert({ id }));
    }
  };

  const handleCancel = () => {
    setShowFormModal(false);
    setAlertToUpdate(undefined);
  };

  const handleSubmitAlert = (values: AlertBodyType) => {
    if (values.optionType === "call" || values.optionType === "put") {
      values.optionType = "option";
    }
    // Capitalize option type first char
    values.optionType = capitalizeFirstChar(values.optionType);
    // Set active status
    values.alertStatus = 2;
    dispatch(actions.submitAlert(values));
    setShowFormModal(false);
  };
  const handleSelectOperation = (value: string) => {
    setAlerType(value);
  };
  const handleSelectGrain = (value: string) => {
    setSelectedId(value);
  };

  useEffect(() => {
    dispatch(actions.getInstruments({ operation: alertType, grain: selectedId }));
  }, [dispatch, selectedId, alertType]);

  useEffect(() => {
    if (grainFilter === "" && statusFilter === 0) {
      dispatch(actions.getAlerts({}));
    } else {
      dispatch(actions.getAlerts({ grain: grainFilter, status: statusFilter }));
    }
  }, [dispatch, grainFilter, statusFilter]);

  const handleClickActiveAlert = (id: number) => {
    const alert = alertList?.find((alert: AlertType) => alert.id === id);

    let path = '';
    switch (alert?.option_type) {
      case OPTION_TYPES.FUTURE:
        path = ROUTE_PATHS.OPERATION_FUTURES;
        break;
      case OPTION_TYPES.OPTION:
        path = ROUTE_PATHS.OPERATION_OPTIONS;
        break;
      case OPTION_TYPES.SPREAD:
        path = ROUTE_PATHS.OPERATION_SPREAD;
        break;
      default:
        break;
    }

    navigate(path, { state: alert });
  };

  return (
    <div className="main-content alerts-container background light-3" id="alerts-container">
      {loadingAlerts && <AntSpin />}
      {showFormModal && (
        <AlertFormModal
          values={alertToUpdate}
          onCancel={handleCancel}
          onSubmit={handleSubmitAlert}
          getContainer={document.getElementById("alerts-container") || undefined}
          onSelectOperationType={handleSelectOperation}
          selectedId={selectedId}
          onSelectGrainType={handleSelectGrain}
        />
      )}
      <div className="alert-header">
        {alertList && alertList.length > 0 ? (
          alertList?.length > 1 ? (
            <h2>{t("alerts:created-alerts", { count: alertList?.length })}</h2>
          ) : (
            <h2>{t("alerts:created-alert", { count: alertList?.length })}</h2>
          )
        ) : (
          <h2>{t("alerts:no-alerts")}</h2>
        )}
        <div className="filter">
          <EMatInput
            className="filters-alerts"
            id={grainFilter}
            defaultActiveFirstOption={true}
            eMatType={EMAT_INPUT_TYPES.SELECT}
            options={grainOptions}
            onSelect={(option: any) => setGrainFilter(option)}
            defaultValue={grainOptions[0].value}
          />
          <EMatInput
            className="filters-alerts"
            id={statusFilter.toString()}
            defaultActiveFirstOption={true}
            eMatType={EMAT_INPUT_TYPES.SELECT}
            options={statusOptions}
            onSelect={(option: any) => setStatusFilter(option)}
            defaultValue={statusOptions[0].value}
          />
          <EMatButton
            className="button-alerts"
            type="primary"
            id="add-alert"
            onClick={handleClickAdd}
          >
            {t("alerts:add-alert")}
          </EMatButton>
        </div>
      </div>
      <div className="cards-container">
        {!dataList ? (
          <AntSpin />
        ) : (
          <Row gutter={[16, 16]}>
            {dataList.length > 0 &&
              dataList?.map((data: any, index: number) => {
                const isActive: boolean = data.find(
                  (i: DefaultOptionType) => i.label === "isActive"
                )?.value;
                const id = data.find((i: DefaultOptionType) => i.label === "id")?.value;
                return (
                  <Col key={index} xs={24} lg={12}>
                    <OrderCard data={data} isActive={isActive}>
                      <div className="buttons-container">
                        <EMatButton
                          id="delete"
                          className="text-secondary danger"
                          type="primary"
                          onClick={() => handleClickDelete(index)}
                        >
                          {t("common:actions.delete")}
                        </EMatButton>
                        <EMatButton
                          type="primary"
                          id="edit-alert"
                          onClick={() => handleClickEdit(index)}
                        >
                          {t("common:actions.edit")}
                        </EMatButton>
                        {isActive && (
                          <EMatButton
                            type="ghost"
                            id="edit-alert"
                            className="operator"
                            onClick={() => handleClickActiveAlert(id)}
                          >
                            {t("received_orders:buttons.send-to-operator")}
                          </EMatButton>
                        )}
                      </div>
                    </OrderCard>
                  </Col>
                );
              })}
          </Row>
        )}
      </div>
    </div>
  );
};

export default withErrorHandler(Alerts, instance);
