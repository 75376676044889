import { PlusOutlined } from "@ant-design/icons";
import { TableWithFilter } from "Components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { FuturesType } from "src/types/operation";
import * as actions from "../../../store/actions";
import { BuyOrSellModal } from "Components";
import "./Futures.scss";
import { OPERATION_TYPES, OPTION_TYPES, STATE_OPTIONS } from "src/constants/operation";
import { capitalizeFirstChar } from "src/helpers/capiitalizeFirstChar";
import { formatCurrency } from "src/helpers/formatCurrency";

const Futures = (props: any) => {
  const { operationGroup, setOperationGroup, setIsSumaryVisible, tons, setTons } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state }: { state: any } = location;
  const [instrumentToOperate, setInstrumentToOperate] = useState<any>(null);
  const [operationType, setOperationType] = useState(OPERATION_TYPES.BUY);
  const [asociatedAlert, setAsociatedAlert] = useState(undefined);
  const futures = useAppSelector<FuturesType | null>((state) => state.operation.futures);
  const isLoading = useAppSelector((state) => state.operation.loadingFutures);
  const isSubmitting = useAppSelector((state) => state.operation.loadingOperation);
  const operationGroupSubmitted = useAppSelector(
    (state) => state.operation.operationGroupSubmitted
  );
  const contraPositions = useAppSelector((state) => state.operation.contraPositions);
  const loadingContraPositions = useAppSelector((state) => state.operation.loadingContraPositions);

  const renderValue = (text: any, row: object, index: number) => {
    return formatCurrency(text);
  };

  const options = [
    {
      label: t("futures:options.corn"),
      value: "MAI",
    },
    {
      label: t("futures:options.soy"),
      value: "SOJ",
    },
    {
      label: t("futures:options.wheat"),
      value: "TRI",
    },
  ];

  const handleClickBuySell = (row: object, index: number) => {
    setInstrumentToOperate(row);
  };

  const columns = [
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (text: any, row: object, index: number) => (
        <div onClick={() => handleClickBuySell(row, index)}>
          <PlusOutlined />
        </div>
      ),
    },
    {
      title: t("dashboard:prices.instrument"),
      dataIndex: "instrument_name",
      key: "instrument_name",
    },
    {
      title: t("dashboard:prices.buy"),
      dataIndex: "bid",
      key: "bid",
      render: renderValue,
      sorter: (a: any, b: any) => a.bid - b.bid,
      align: "right" as "right"
    },
    {
      title: t("dashboard:prices.sell"),
      dataIndex: "offer",
      key: "offer",
      render: renderValue,
      sorter: (a: any, b: any) => a.offer - b.offer,
      align: "right" as "right"
    },
    {
      title: t("dashboard:prices.last"),
      key: "last",
      dataIndex: "last",
      render: renderValue,
      sorter: (a: any, b: any) => a.last - b.last,
      align: "right" as "right"
    },
  ];

  const handleFilter = (value: any) => {
    dispatch(actions.getFutures(value));
  };

  const handleCancel = () => {
    setInstrumentToOperate(null);
  };

  const handleBuyInstrument = (values: any) => {
    const body = values;
    body.instrument = instrumentToOperate._id;
    body.orderState = STATE_OPTIONS.SENT;
    setTons(body.quantity);
    setOperationGroup([...operationGroup, body]);
    setInstrumentToOperate(null);
  };

  const handleSellInstrument = (values: any) => {
    const body = values;
    body.instrument = instrumentToOperate._id;
    body.orderState = STATE_OPTIONS.SENT;
    setTons(body.quantity);
    setOperationGroup([...operationGroup, body]);
    setInstrumentToOperate(null);
  };

  const getContraPosition = (operation: string) => {
    dispatch(
      actions.getContraPositions({
        operation: operation,
        symbol: encodeURIComponent(instrumentToOperate._id),
      })
    );
  };

  useEffect(() => {
    handleFilter(options[0].value);
  }, []);

  useEffect(() => {
    if (operationGroupSubmitted) {
      setInstrumentToOperate(null);
    }
  }, [operationGroupSubmitted]);

  useEffect(() => {
    const { SELL, BUY } = OPERATION_TYPES;
    if (state) {
      setAsociatedAlert(state.id);
      const instrument = futures?.data.find((f: any) => f._id === state.instrument);
      if (instrument) {
        setOperationType(capitalizeFirstChar(state.operation) !== BUY ? SELL : BUY);

        setInstrumentToOperate(instrument);
      } else {
        console.log("No existe el instrumento en el listado");
      }
    }
  }, [state, futures]);

  return (
    <div className="futures-container" id="futures-container">
      {instrumentToOperate && (
        <BuyOrSellModal
          instrument={instrumentToOperate}
          onCancel={handleCancel}
          onBuy={handleBuyInstrument}
          onSell={handleSellInstrument}
          getContainer={document.getElementById("futures-container") || false}
          loading={isSubmitting}
          operationSubmitted={operationGroupSubmitted}
          onGetContraPosition={getContraPosition}
          contraPositions={contraPositions}
          loadingContrapositions={loadingContraPositions}
          isUpdate={false}
          optionType={OPTION_TYPES.FUTURE}
          opType={operationType}
          asociatedAlert={asociatedAlert}
          setIsSumaryVisible={setIsSumaryVisible}
          setTons={setTons}
          tons={tons}
        />
      )}
      <TableWithFilter
        columns={columns}
        data={futures?.data || []}
        lastUpdateDate={futures?.last_update}
        loading={isLoading}
        onFilter={handleFilter}
        defaultSelected={options[0].value}
        selectOptions={options}
        rowKey="_id"
        selectId="future-types"
        tableId="future-prices"
        operationGroup={operationGroup}
        setOperationGroup={setOperationGroup}
      />
    </div>
  );
};

export default Futures;
