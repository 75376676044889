import { DatePicker, TimeRangePickerProps, ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import esEs from 'antd/lib/locale-provider/es_ES';
import enUs from 'antd/lib/locale-provider/en_US';


const { RangePicker } = DatePicker;

const DateRangePicker = (props: TimeRangePickerProps) => {
  const { i18n } = useTranslation()
  const { className, ...rest } = props
  let classes = "emat-input "
  const locale = i18n.language === 'es' ? esEs : enUs

  if (className) classes += className

  return (
    <ConfigProvider locale={locale}>
      <RangePicker className={classes} {...rest}  />
    </ConfigProvider>
  )
}

export default DateRangePicker
