import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { AlertType } from "src/types/alerts";
import { InstrumentType } from "src/types/operation";
import { clearAllStores } from "../actions/common";

const initialState = {
  loadingAlerts: false,
  alertList: null,
  error: null,
  loadingInstruments: false,
  instruments: null,
  loadingAlertMutation: false,
  alertSubmitted: null,
  loadingDeleteAlert: false,
  alertDeleted: null,
};

const alerts = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    getAlertsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingAlerts: true, alertList: null, error: null }),
    getAlertsSuccess: (state, { payload }: PayloadAction<AlertType[]>) =>
      updateObject(state, { loadingAlerts: false, alertList: payload }),
    getAlertsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingAlerts: false, error: payload }),

    getInstrumentsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingInstruments: true, instruments: null, error: null }),
    getInstrumentsSuccess: (state, { payload }: PayloadAction<InstrumentType[]>) =>
      updateObject(state, { loadingInstruments: false, instruments: payload, error: null }),
    getInstrumentsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingInstruments: false, error: payload }),

    submitAlertStart: (state, { payload }: PayloadAction) =>
      updateObject(state, {
        loadingAlertMutation: true,
        error: null,
        alertSubmitted: null,
      }),
    submitAlertSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, {
        alertSubmitted: payload,
        loadingAlertMutation: false,
      }),
    submitAlertError: (state, { payload }: PayloadAction<string>) =>
      updateObject(state, { loadingAlertMutation: false, error: payload }),

    deleteAlertStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingDeleteAlert: true, alertDeleted: null, error: null }),
    deleteAlertSuccess: (state, { payload }: PayloadAction<{ id: number }>) =>
      updateObject(state, { loadingDeleteAlert: false, alertDeleted: true }),
    deleteAlertFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingDeleteAlert: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getAlertsStart,
  getAlertsSuccess,
  getAlertsFail,
  getInstrumentsFail,
  getInstrumentsStart,
  getInstrumentsSuccess,
  submitAlertError,
  submitAlertStart,
  submitAlertSuccess,
  deleteAlertStart,
  deleteAlertSuccess,
  deleteAlertFail,
} = alerts.actions;

export default alerts;
