import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { EMatButton, EMatFormItem, EMatInput, EMatModal } from "Components";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import * as actions from "../../store/actions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "src/constants/routePaths";

export const ChangePassword = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: (value: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const error = useAppSelector((state) => state.auth.error);
  const passwordChanged = useAppSelector((state) => state.users.passwordChanged);
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (passwordChanged) {
      dispatch(actions.clearAllStores());
      navigate(ROUTE_PATHS.LOGOUT);
    }
  }, [passwordChanged]);

  const handleUpdatePassword = (values: { current: string; new: string; repeat: string }) => {
    dispatch(
      actions.updatePassword({
        old_password: values.current,
        password: values.new,
        password_confirmation: values.repeat,
      })
    );
  };

  const validatePassword = (value: any, form: any) => {
    if (value !== form.getFieldsValue().new)
      return Promise.reject(new Error(t("common:errors.password")));

    return Promise.resolve();
  };
  return (
    <EMatModal
      closable={true}
      visible={visible}
      className=""
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <h1>{t("users:change-password")}</h1>
      <Form className="" form={form} onFinish={handleUpdatePassword}>
        <EMatFormItem
          name="current"
          rules={[
            {
              required: true,
              message: t("common:errors:fieldRequiredDefault"),
            },
          ]}
        >
          <EMatInput
            eMatType={EMAT_INPUT_TYPES.PASSWORD}
            id="current"
            placeholder={t("auth:signIn:password")}
          />
        </EMatFormItem>

        <EMatFormItem
          name="new"
          rules={[
            {
              required: true,
              message: t("common:errors:fieldRequiredDefault"),
            },
          ]}
        >
          <EMatInput
            eMatType={EMAT_INPUT_TYPES.PASSWORD}
            id="new"
            placeholder={t("auth:signIn:password")}
          />
        </EMatFormItem>

        <EMatFormItem
          name="repeat"
          rules={[
            {
              required: true,
              message: t("common:errors:fieldRequiredDefault"),
            },
            {
              validator: (rule, value) => validatePassword(value, form),
            },
          ]}
          validateTrigger={["onBlur"]}
        >
          <EMatInput
            eMatType={EMAT_INPUT_TYPES.PASSWORD}
            id="repeat"
            placeholder={t("auth:signIn:password")}
          />
        </EMatFormItem>
        {error && (
          <div className="error-msg">
            <p>{t(error)}</p>
          </div>
        )}
        <EMatFormItem>
          <EMatButton type="primary" id="submit-password" htmlType="submit">
            {t("users:confirm")}
          </EMatButton>
        </EMatFormItem>
      </Form>
    </EMatModal>
  );
};
