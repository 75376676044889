import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EMatModal, EMatTabs } from "Components";
import CallPutForm from "./CallPutForm/CallPutForm";
import Summary from "./Summary/Summary";
import "./CallPutModal.scss";
import { OperationType } from "src/types/operation";
import { AGREEMENT_TYPE, OPERATION_POSITION } from "src/constants/operation";
import { handlePosition } from "src/common/utils";

interface Props {
  onCancel: () => void;
  onConfirm: (operation: OperationType) => void;
  instrumentStrike: any; //Could be the object we made in Options or the object we receive by API
  getContainer: string | HTMLElement | (() => HTMLElement) | false;
  loading: boolean;
  onGetContraPosition: (operation: string) => void;
  contraPositions?: any[] | undefined | null;
  loadingContrapositions: boolean;
  agreement?: string;
  operationType?: string;
  setIsSumaryVisible?: (value: boolean) => void;
  tons?: number;
  setTons?: (value: number) => void;
}

const CallPutModal = ({
  onCancel,
  instrumentStrike,
  getContainer,
  onConfirm,
  loading,
  onGetContraPosition,
  contraPositions,
  loadingContrapositions,
  agreement,
  operationType,
  setIsSumaryVisible,
  tons,
}: Props) => {
  const { t } = useTranslation();
  const [operationToSubmit, setOperationToSubmit] = useState<any>(null);
  const [operationToEdit, setOperationToEdit] = useState<any>(null);
  const [agreementType, setAgreementType] = useState(
    agreement ||
      instrumentStrike.agreementType ||
      operationToEdit?.agreementType ||
      AGREEMENT_TYPE.CALL
  );

  const handleSubmit = (formData: any) => {
    formData.position = handlePosition(formData.position);
    setOperationToSubmit({
      ...instrumentStrike,
      ...formData,
      agreementType,
    });
  };

  const tabs = [
    {
      tab: t("operation:options.call"),
      key: AGREEMENT_TYPE.CALL,
      component: (
        <CallPutForm
          instrumentStrike={instrumentStrike} //Has info about the instrument and the strike, should be complete when using in received or emitted orders.
          onCancel={onCancel}
          onSubmit={handleSubmit}
          operationToEdit={operationToEdit}
          agreementType={AGREEMENT_TYPE.CALL}
          operationType={operationType}
          tons={tons}
        />
      ),
    },
    {
      tab: t("operation:options.put"),
      key: AGREEMENT_TYPE.PUT,
      component: (
        <CallPutForm
          instrumentStrike={instrumentStrike} //Has info about the instrument and the strike
          onCancel={onCancel}
          onSubmit={handleSubmit}
          operationToEdit={operationToEdit}
          agreementType={AGREEMENT_TYPE.PUT}
          operationType={operationType}
          tons={tons}
        />
      ),
    },
  ];

  const handleEdit = () => {
    setOperationToEdit(operationToSubmit);
    setOperationToSubmit(null);
  };

  const handleTabClick = (key: string) => {
    setAgreementType(key);
    setOperationToEdit(null);
  };

  return (
    <EMatModal
      closable={true}
      visible={true}
      maskClosable
      onCancel={onCancel}
      afterClose={onCancel}
      getContainer={getContainer}
      className={
        operationToSubmit
          ? operationToSubmit?.position === OPERATION_POSITION.CLOSE
            ? "call-put-modal background close"
            : "call-put-modal background"
          : "call-put-modal"
      }
      footer={null}
    >
      {/* If there is an operation to submit we will show the summary with that info.  */}
      {operationToSubmit ? (
        <Summary
          operation={operationToSubmit}
          onClickEdit={handleEdit}
          onClickConfirm={onConfirm}
          onCancel={onCancel}
          loading={loading}
          onGetContraPosition={onGetContraPosition}
          contraPositions={contraPositions}
          setIsSumaryVisible={setIsSumaryVisible}
        />
      ) : (
        <EMatTabs
          defaultActiveKey={
            agreementType ||
            operationToEdit?.agreementType ||
            instrumentStrike?.agreementType ||
            AGREEMENT_TYPE.CALL
          }
          size="large"
          className="operations-tabs"
          centered
          tabs={tabs}
          destroyInactiveTabPane={true}
          onTabClick={handleTabClick}
        />
      )}
    </EMatModal>
  );
};

export default CallPutModal;
