import uuid4 from 'uuid4'

const getDeviceId = () => {
  let deviceId: string | null = localStorage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }
  deviceId = uuid4();
  localStorage.setItem("deviceId", deviceId);
  return deviceId;
};

export default getDeviceId;
