import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import { operationService } from "Services";
import * as sliceActions from "src/store/slices/operation";
import { AxiosResponse } from "axios";

export function* getFuturesSaga(action: ReturnType<typeof actions.getFutures>) {
  yield put(sliceActions.getFuturesStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getFutures(action.payload);
    yield put(sliceActions.getFuturesSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getFuturesError("common:errors.somethingWentWrong"));
  }
}

export function* getOptionsSaga(action: ReturnType<typeof actions.getOptions>) {
  yield put(sliceActions.getOptionsStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getOptions(action.payload);
    yield put(sliceActions.getOptionsSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getOptionsError("common:errors.somethingWentWrong"));
  }
}

export function* getSpreadsSaga(action: ReturnType<typeof actions.getSpreads>) {
  yield put(sliceActions.getSpreadsStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getSpreads(action.payload);
    yield put(sliceActions.getSpreadsSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getSpreadsError("common:errors.somethingWentWrong"));
  }
}

export function* submitOperationSaga(action: ReturnType<typeof actions.submitOperation>) {
  yield put(sliceActions.submitOperationStart());
  try {
    let response: AxiosResponse<any>;
    if (!action.payload.id) {
      response = yield operationService.createOperation(action.payload);
    } else {
      response = yield operationService.updateOperation(action.payload);
    }
    yield put(sliceActions.submitOperationSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.submitOperationError("common:errors.somethingWentWrong"));
  }
}

export function* getContraPositionsSaga(action: ReturnType<typeof actions.getContraPositions>) {
  yield put(sliceActions.getContraPositionsStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getContraPositions(action.payload);

    yield put(sliceActions.getContraPositionsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getContraPositionsError("common:errors.somethingWentWrong"));
  }
}

//issued orders
export function* getIssuedOrdersSaga(action: ReturnType<typeof actions.getIssuedOrders>) {
  yield put(sliceActions.getIssuedOrdersSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getIssuedOrders(action.payload);
    yield put(sliceActions.getIssuedOrdersSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getIssuedOrdersSagaError("common:errors.somethingWentWrong"));
  }
}

//delete issued order
export function* deleteIssuedOrderSaga(action: ReturnType<typeof actions.deleteIssuedOrder>) {
  yield put(sliceActions.deleteIssuedOrderSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.deleteIssuedOrder(
      action.payload.id
    );
    yield put(actions.getIssuedOrdersGroups({ page: 1 }));
    yield put(sliceActions.deleteIssuedOrderSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.deleteIssuedOrderSagaError("common:errors.somethingWentWrong"));
  }
}

//get issued order by id
export function* getIssuedOrderByIdSaga(action: ReturnType<typeof actions.getOrderById>) {
  yield put(sliceActions.getIssuedOrderByIdSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getOrderById(action.payload);
    yield put(sliceActions.getIssuedOrderByIdSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getIssuedOrderByIdSagaError("common:errors.somethingWentWrong"));
  }
}

//issued historic orders
export function* getHistoricIssuedOrdersSaga(
  action: ReturnType<typeof actions.getHistoricIssuedOrders>
) {
  yield put(sliceActions.getHistoricIssuedOrdersStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getHistoricIssuedOrders(
      action.payload
    );
    yield put(sliceActions.getHistoricIssuedOrdersSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getHistoricIssuedOrdersError("common:errors.somethingWentWrong"));
  }
}

//update state sent in a issued order
export function* setStatusSentSaga(action: ReturnType<typeof actions.setStatusSent>) {
  yield put(sliceActions.setStatusSentSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.setStatusSent(action.payload.id);
    yield put(actions.getIssuedOrdersGroups({ page: 1 }));
    yield put(sliceActions.setStatusSentSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.setStatusSentSagaError("common:errors.somethingWentWrong"));
  }
}

//get clients (for received order)
export function* getClientsSaga(action: ReturnType<typeof actions.getClients>) {
  yield put(sliceActions.getClientsSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getClients();
    yield put(sliceActions.getClientsSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getClientsSagaError("common:errors.somethingWentWrong"));
  }
}

//get received orders
export function* getReceivedOrdersSaga(action: ReturnType<typeof actions.getReceivedOrders>) {
  yield put(sliceActions.getReceivedOrdersSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getReceivedOrders(action.payload);
    yield put(sliceActions.getReceivedOrdersSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getReceivedOrdersSagaError("common:errors.somethingWentWrong"));
  }
}

//delete a received order
export function* deleteReceivedOrderSaga(action: ReturnType<typeof actions.deleteReceivedOrder>) {
  yield put(sliceActions.deleteReceivedOrderSagaStart());
  try {
    let response: any;
    if (action.payload.isGroup) {
      const { data }: AxiosResponse<any> = yield operationService.deleteOrderGroup(
        action.payload.id
      );
      response = data;
      yield put(actions.getIssuedOrdersGroups({ page: 1 }));
    } else {
      const { data }: AxiosResponse<any> = yield operationService.deleteReceivedOrder(
        action.payload.id
      );
      response = data;
    }

    yield put(actions.getReceivedOrdersGroups({ page: 1, clientId: action.payload.filter }));
    yield put(sliceActions.deleteReceivedOrderSagaSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.deleteReceivedOrderSagaError("common:errors.somethingWentWrong"));
  }
}

//mark received
export function* markReceivedSaga(action: ReturnType<typeof actions.markReceived>) {
  yield put(sliceActions.markReceivedSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.markReceived(action.payload.id);
    yield put(actions.getReceivedOrdersGroups({ page: 1, clientId: action.payload.filter }));
    yield put(sliceActions.markReceivedSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.markReceivedSagaError("common:errors.somethingWentWrong"));
  }
}

//mark operated
export function* markOperatedSaga(action: ReturnType<typeof actions.markOperated>) {
  yield put(sliceActions.markOperatedSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.markOperated(action.payload.id);
    yield put(actions.getReceivedOrdersGroups({ page: 1, clientId: action.payload.filter }));
    yield put(sliceActions.markOperatedSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.markOperatedSagaError("common:errors.somethingWentWrong"));
  }
}

//mark partially operated
export function* markPartiallyOperatedSaga(
  action: ReturnType<typeof actions.markPartiallyOperated>
) {
  yield put(sliceActions.markPartiallyOperatedSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.markPartiallyOperated(
      action.payload.id,
      action.payload.quantity
    );
    yield put(actions.getReceivedOrdersGroups({ page: 1, clientId: action.payload.filter }));
    yield put(sliceActions.markPartiallyOperatedSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.markPartiallyOperatedSagaError("common:errors.somethingWentWrong"));
  }
}

//mark sent to operator
export function* sentToOperatorSaga(action: ReturnType<typeof actions.sentToOperator>) {
  yield put(sliceActions.sentToOperatorSagaStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.sentToOperator(action.payload.id);

    yield put(actions.getReceivedOrdersGroups({ page: 1, clientId: action.payload.filter }));
    yield put(sliceActions.sentToOperatorSagaSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.sentToOperatorSagaError("common:errors.somethingWentWrong"));
  }
}
//Own open positions
export function* getOwnOpenPositionsSaga(action: ReturnType<typeof actions.getOwnOpenPositions>) {
  yield put(sliceActions.getOwnOpenPositionsStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getOwnOpenPositions(action.payload);
    yield put(sliceActions.getOwnOpenPositionsSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getOwnOpenPositionsError("common:errors.somethingWentWrong"));
  }
}

//Own open positions csv
export function* getOwnOpenPositionsCsvSaga(
  action: ReturnType<typeof actions.getOwnOpenPositionsCsv>
) {
  yield put(sliceActions.getOwnOpenPositionsCsvStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getOwnOpenPositionsCsv();
    yield put(sliceActions.getOwnOpenPositionsCsvSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getOwnOpenPositionsCsvError("common:errors.somethingWentWrong"));
  }
}

//Own closed positions
export function* getOwnClosedPositionsSaga(
  action: ReturnType<typeof actions.getOwnClosedPositions>
) {
  yield put(sliceActions.getOwnClosedPositionsStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getOwnClosedPositions(
      action.payload
    );
    yield put(sliceActions.getOwnClosedPositionsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getOwnClosedPositionsError("common:errors.somethingWentWrong"));
  }
}

//Own closed positions csv
export function* getOwnClosedPositionsCsvSaga(
  action: ReturnType<typeof actions.getOwnClosedPositionsCsv>
) {
  yield put(sliceActions.getOwnClosedPositionsCsvStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getOwnClosedPositionsCsv(
      action.payload
    );
    yield put(sliceActions.getOwnClosedPositionsCsvSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getOwnClosedPositionsCsvError("common:errors.somethingWentWrong"));
  }
}

//Clients open positions
export function* getClientsOpenPositionsSaga(
  action: ReturnType<typeof actions.getClientsOpenPositions>
) {
  yield put(sliceActions.getClientsOpenPositionsStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getClientsOpenPositions(
      action.payload
    );
    yield put(sliceActions.getClientsOpenPositionsSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getClientsOpenPositionsError("common:errors.somethingWentWrong"));
  }
}

//Clients open positions csv
export function* getClientsOpenPositionsCsvSaga(
  action: ReturnType<typeof actions.getClientsOpenPositionsCsv>
) {
  yield put(sliceActions.getClientsOpenPositionsCsvStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getClientsOpenPositionsCsv(
      action.payload
    );
    yield put(sliceActions.getClientsOpenPositionsCsvSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getClientsOpenPositionsCsvError("common:errors.somethingWentWrong"));
  }
}

//Clear clients open positions
export function* clearClientOpenPositionsSaga(
  action: ReturnType<typeof actions.clearClientOpenPositions>
) {
  yield put(sliceActions.clearClientOpenPositions());
}

//Clients closed positions
export function* getClientsClosedPositionsSaga(
  action: ReturnType<typeof actions.getClientsClosedPositions>
) {
  yield put(sliceActions.getClientsClosedPositionsStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getClientsClosedPositions(
      action.payload
    );
    yield put(sliceActions.getClientsClosedPositionsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getClientsClosedPositionsError("common:errors.somethingWentWrong"));
  }
}

//Clients closed positions csv
export function* getClientsClosedPositionsCsvSaga(
  action: ReturnType<typeof actions.getClientsClosedPositionsCsv>
) {
  yield put(sliceActions.getClientsClosedPositionsCsvStart());

  try {
    const { data }: AxiosResponse<any> = yield operationService.getClientsClosedPositionsCsv(
      action.payload
    );
    yield put(sliceActions.getClientsClosedPositionsCsvSuccess(data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getClientsClosedPositionsCsvError("common:errors.somethingWentWrong"));
  }
}

//Clear clients closed positions
export function* clearClientClosedPositionsSaga(
  action: ReturnType<typeof actions.clearClientClosedPositions>
) {
  yield put(sliceActions.clearClientClosedPositions());
}

//Clear positions Csv
export function* clearPositionsCsvSaga(action: ReturnType<typeof actions.clearPositionsCsv>) {
  yield put(sliceActions.clearPositionsCsv());
}

//get positions
export function* getPositionsSaga(action: ReturnType<typeof actions.getPositions>) {
  yield put(sliceActions.getPositionsStart({ index: action.payload.index }));
  try {
    const { data }: AxiosResponse<any> = yield operationService.getPositions(action.payload);
    yield put(
      sliceActions.getPositionsSuccess({ positions: data.data, index: action.payload.index })
    );
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getPositionsError("common:errors.somethingWentWrong"));
  }
}

export function* getCountSaga(action: ReturnType<typeof actions.getCount>) {
  yield put(sliceActions.getCountStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getCount();
    yield put(sliceActions.getCountSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getCountError("common:errors.somethingWentWrong"));
  }
}
