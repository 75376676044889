import saga from "redux-saga";
import { all, fork } from "redux-saga/effects";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import RootReducer from "./slices";
import { watchAuthSaga } from "./sagas/auth";
import { watchOperationSaga } from "./sagas/operation";
import { watchLayoutSaga } from "./sagas/layout";
import { watchAlertsSaga } from "./sagas/alerts";
import { watchMonitorSaga } from "./sagas/monitor";
import { watchCommisionsSaga } from "./sagas/commissions";
import { watchNotificationsSaga } from "./sagas/notifications";
import { watchUsersSaga } from "./sagas/users";

function* RootSaga() {
  yield all([fork(watchAuthSaga)]);
  yield all([fork(watchOperationSaga)]);
  yield all([fork(watchLayoutSaga)]);
  yield all([fork(watchAlertsSaga)]);
  yield all([fork(watchMonitorSaga)]);
  yield all([fork(watchCommisionsSaga)]);
  yield all([fork(watchNotificationsSaga)]);
  yield all([fork(watchUsersSaga)]);
}

const sagaMiddleware = saga();

const store = configureStore({
  reducer: RootReducer,
  middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(RootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
