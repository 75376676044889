import instance from "src/api/axios-instance";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import "./Commissions.scss";
import EMatInput from "src/components/UI/EMatInput/EMatInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as actions from "../../store/actions";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import DateRangePicker from "src/components/UI/DateRangePicker/DateRangePicker";
import { EMatButton, EMatTable } from "Components";
import { Col, Row } from "antd";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { ClientType } from "src/types/monitor";
import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { onDatePickerChange } from "src/helpers/dates";
import { downloadFile } from "src/common/utils";

const Commissions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const commissionList = useAppSelector<any>((state) => state.commissions.commissionList);
  const commisionsCsv = useAppSelector<any>((state) => state.commissions.commisionsCsv || []);
  const masterClients = useAppSelector<ClientType[]>(
    (state) => state.commissions.masterClients || []
  );
  const loadingCommissions = useAppSelector<boolean>(
    (state) => state.commissions.loadingCommissions
  );
  const loadingCsv = useAppSelector<boolean>((state) => state.commissions.loadingCsv);
  const loadingInput = useAppSelector<boolean>((state) => state.commissions.loadingInput);
  const currentDate = moment().startOf("day");
  const aWeekAgo = moment().startOf("day").subtract(1, "week");
  const [data, setData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<{ from: Moment | null; to: Moment | null }>({
    from: aWeekAgo,
    to: currentDate,
  });
  const [selectedClientId, setSelectedClientId] = useState<number | "">("");

  useEffect(() => {
    dispatch(actions.getMasterClients());
  }, [dispatch]);

  useEffect(() => {
    if (selectedClientId !== "" && dateRange.from !== null && dateRange.to !== null) {
      const from = dateRange.from.format("YYYY-MM-DD");
      const to = dateRange.to.format("YYYY-MM-DD");

      const date = { from, to };
      const clientId = selectedClientId.toString();
      dispatch(actions.getCommissions({ clientId, date }));
      dispatch(actions.getCommissionsCsv({ clientId, date }));
    }
  }, [dispatch, dateRange, selectedClientId]);

  useEffect(() => {
    if (commissionList && commissionList.data) {
      setData(commissionList.data);
    } else {
      setData([]);
    }
  }, [commissionList]);

  const columns = [
    {
      title: "Instrumento",
      dataIndex: "instrument_name",
      key: "instrument_name",
    },
    {
      title: "Tipo",
      dataIndex: "instrument_type",
      key: "instrument_type",
      render: (value: string) => t(`operation:common.option-type.${value.toLowerCase()}`),
    },
    {
      title: "Fecha",
      dataIndex: "trade_date",
      key: "trade_date",
    },
    {
      title: "Tonelada operada",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Comision",
      dataIndex: "commission",
      key: "commission",
    },
  ];

  const filterClients = [
    {
      label: t("accounts:filter.client"),
      value: "",
    },
    ...masterClients.map((client: ClientType) => ({
      label: client.company_name,
      value: client.id,
    })),
  ];

  const onChange = (value: RangePickerProps["value"]) => {
    onDatePickerChange(value, setDateRange, t);
  };

  const getFileName = () => {
    const from = dateRange.from?.format("DD-MM-YY");
    const to = dateRange.to?.format("DD-MM-YY");
    const companyName = masterClients.find(
      (client: ClientType) => client.id === selectedClientId
    )?.company_name;

    return `Comisiones ${companyName} ${from}_${to}.csv`;
  };

  return (
    <div className="content commissions-container background light-3" id="commissions-container">
      <Row gutter={16}>
        <div className="filter-positions-row">
          <div>
            {(selectedClientId === "" || selectedClientId === null) && (
              <h2 className="total-positions">{t("accounts:startWording")}</h2>
            )}
            {selectedClientId !== "" && data.length === 0 && !loadingCommissions && (
              <h2 className="total-positions">{t("commissions:noData")}</h2>
            )}
          </div>
          <div className="filter">
            <EMatInput
              className="filters-accounts"
              eMatType={EMAT_INPUT_TYPES.SELECT}
              options={filterClients}
              onSelect={(value: any) => setSelectedClientId(value)}
              placeholder={t("accounts:filter.crops")}
              defaultValue={selectedClientId}
              loading={loadingInput}
            />
            <DateRangePicker
              className="filters-date"
              format="DD-MM-YYYY"
              onChange={onChange}
              value={[dateRange.from, dateRange.to]}
            />
            <EMatButton
              className="download-csv"
              type="primary"
              disabled={commisionsCsv.length === 0 ||  loadingCsv}
              onClick={() =>
                downloadFile({
                  data: commisionsCsv,
                  fileName: getFileName(),
                  fileType: "text/csv",
                })
              }
            >
              Descargar datos
            </EMatButton>
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={24}>
          <div className="prices-container">
            <EMatTable
              id={"tableId"}
              columns={columns}
              dataSource={data.length > 0 ? data : []}
              loading={loadingCommissions}
              rowKey="_id"
              pagination={{
                position: ["bottomRight"],
                pageSize: 13,
              }}
            />
            {commissionList && commissionList.total_price && (
              <div className="totals-container">
                <p>
                  {t("commissions:totals.futures")}
                  {commissionList.total_price.FUTURE}
                </p>
                <p>
                  {t("commissions:totals.spreads")}
                  {commissionList.total_price.SPREAD}
                </p>
                <p>
                  {t("commissions:totals.options")}
                  {commissionList.total_price.OPTION}
                </p>
                <p className="total">
                  {t("commissions:totals.total")}
                  {commissionList.total_price.OPTION +
                    commissionList.total_price.SPREAD +
                    commissionList.total_price.FUTURE}
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorHandler(Commissions, instance);
