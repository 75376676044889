import { FC, useEffect, useState } from "react"
import { notification } from "antd"
import { onMessageListener } from "src/firebase/firebase"
import * as actions from "../../store/actions";
import { useAppDispatch } from "src/hooks/reduxHooks";

interface OpenNotificationProps {
  title: string
  body: string
}

const openNotification = ({ title, body }: OpenNotificationProps) => {
  notification.open({
    message: title,
    description: body,
  })
}

const Notification: FC = () => {
  const dispatch = useAppDispatch();
  const [notification, setNotification] = useState({ title: "", body: "" })

  useEffect(() => {
    if (notification?.title) {
      openNotification({
        title: notification?.title,
        body: notification?.body,
      })
      dispatch(actions.getNotifications(false))
    }
  }, [notification])

  onMessageListener()
    .then((payload: any) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body })
    })
    .catch((err) => console.log("failed: ", err))

  return <></>
}

export default Notification
