import { TableWithFilter } from "Components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FuturesType } from "src/types/operation";
import { formatCurrency } from "src/helpers/formatCurrency";
import "./Prices.scss";

const Prices = ({
  data,
  loading,
  onSelectType,
  typeOptions,
  defaultSelected,
}: {
  data: FuturesType | null;
  loading: boolean;
  onSelectType: (type: any) => void;
  typeOptions: any;
  defaultSelected: string;
}) => {
  const [t] = useTranslation();

  const renderValue = (text: any, row: object, index: number) => {
    return formatCurrency(text);
  };

  const renderLast = (text: any, row: any, index: number) => {
    if (text !== "") {
      return formatCurrency(text);
    }
    if (row.settelement_price !== "") {
      return formatCurrency(row.settelement_price);
    }
    return "-";
  };

  const columns = [
    {
      title: t("dashboard:prices.instrument"),
      dataIndex: "instrument_name",
      key: "instrument_name",
    },
    {
      title: t("dashboard:prices.buy"),
      dataIndex: "bid",
      key: "bid",
      render: renderValue,
      align: "right" as "right",
    },
    {
      title: t("dashboard:prices.sell"),
      dataIndex: "offer",
      key: "offer",
      render: renderValue,
      align: "right" as "right",
    },
    {
      title: t("dashboard:prices.last"),
      dataIndex: "last",
      key: "last",
      render: renderLast,
      align: "right" as "right",
    },
  ];

  const handleOnSelect = (value: any) => {
    onSelectType(value);
  };

  return (
    <div className="prices-container">
      <TableWithFilter
        selectId="select-type"
        tableId="prices-table"
        selectOptions={typeOptions}
        onFilter={handleOnSelect}
        columns={columns}
        data={data?.data || []}
        lastUpdateDate={data?.last_update}
        rowKey="_id"
        loading={loading}
        defaultSelected={defaultSelected}
      />
    </div>
  );
};

export default Prices;
