import { Button, ButtonProps } from "antd";
import "./EMatButton.scss";

const EMatButton = (props: ButtonProps) => {
  const { children, className, ...rest } = props;
  let classes = "emat-button ";
  
	if (className) classes += className;
  
  return (
    <Button className={classes} {...rest}>
      {children}
    </Button>
  );
};

export default EMatButton;
