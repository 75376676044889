import API_ROUTES from "src/constants/apiRoutes";
import { requestForToken } from "src/firebase/firebase";
import { ChangePasswordBody, SignInBody } from "src/types/auth";
import axios from "../api/axios-instance";

export const authService = {
  signIn: (body: SignInBody) => {
    return axios.post(API_ROUTES.AUTH.LOGIN, {
      email: body.email,
      password: body.password,
      device_id: body.device_id,
      firebase_token: body.firebase_token,
    });
  },
  refreshToken: (refreshToken: string | null, device_id: string | null) => {
    return axios.post(API_ROUTES.AUTH.REFRESH_TOKEN, {
      refreshToken,
      device_id,
    });
  },
  recoverPassword: (email: string) => {
    return axios.post(API_ROUTES.AUTH.RECOVER_PASSWORD, {
      email,
    });
  },
  validateToken: (token: string) => {
    return axios.post(API_ROUTES.AUTH.VALIDATE_TOKEN, {
      token,
    });
  },
  changePassword: (body: ChangePasswordBody) => {
    return axios.post(API_ROUTES.AUTH.CHANGE_PASSWORD, body);
  },
  logout: (token: string) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.post(API_ROUTES.AUTH.LOGOUT);
  },
  getFirebaseToken: async () => {
    const token = await requestForToken();
    return token;
  },
};
