import { createAction } from "@reduxjs/toolkit";
import {
  ChangePasswordBody,
  SignInBody,
  TokenResponse,
} from "src/types/auth";

export const signIn = createAction<SignInBody>("signIn");
export const refreshToken = createAction<TokenResponse>("refreshToken");
export const recoverPassword = createAction<string>("recoverPassword");
export const validateToken = createAction<string>("validateToken");
export const changePassword = createAction<ChangePasswordBody>("changePassword");
export const logout = createAction<string | null | undefined>("logout");
export const tryAutoSignUp = createAction<string>("tryAutoSignUp");
export const getFirebaseToken = createAction("getFirebaseToken");
