import { columns } from "src/containers/IssuedOrders/utils";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import EMatSummaryModal from "../../EMatSummaryModal/EMatSummaryModal";
import * as actions from "../../../store/actions";
import { useTranslation } from "react-i18next";
import EMatButton from "src/components/UI/EMatButton/EMatButton";
import { Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const ReceivedOrdersActions = (props: any) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { ordersData, status, clientId, order, isOwner } = props;

  const [dataById, setDataById] = useState();
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [position, setPosition] = useState("");
  const [titleSummary, setTitleSummary] = useState("");
  const [buttonAcceptSummary, setButtonAcceptSummary] = useState("");
  const [buttonCancelSummary, setButtonCancelSummary] = useState("");
  const [orderId, setOrderId] = useState("");
  const [contrapositions, setContrapositions] = useState();
  const [info, setInfo] = useState();
  const loadingDelete = useAppSelector<any>((state) => state.operation.loadingOrders);
  const [loadingButtons, setLoadingButtons] = useState(false);

  const handleShowModalSummary = (id: string, type: string, data: any, info: any) => {
    setDataById(data);
    setPosition(info.position);
    setOrderId(id);
    setContrapositions(info.contrapositions);
    setInfo(info);
    if (type === "delete") {
      setTitleSummary(t(`received_orders:summary-delete.open-title`));
      setButtonAcceptSummary(t(`received_orders:summary-delete.confirm`));
      setButtonCancelSummary(t(`received_orders:summary-delete.cancel`));
      setLoadingButtons(loadingDelete);
    }
    setShowModalSummary(true);
  };

  const receivedSelectButton = (id: string) => {
    dispatch(actions.deleteReceivedOrder({ id, filter: clientId }));
    setShowModalSummary(false);
  };

  const menu = (
    <Menu className="order-card">
      <EMatButton
        id="delete"
        className="text-secondary danger button-margins"
        type="primary"
        onClick={() => handleShowModalSummary(ordersData.id, "delete", order, info)}
      >
        {t(`received_orders:buttons.delete`)}
      </EMatButton>
    </Menu>
  );

  return (
    <>
      {showModalSummary && (
        <EMatSummaryModal
          order={dataById}
          onCancel={() => setShowModalSummary(false)}
          getContainer={document.getElementById("received-orders-container") || false}
          onClick={receivedSelectButton}
          position={position}
          labelTitle={titleSummary}
          buttonAccept={buttonAcceptSummary}
          buttonCancel={buttonCancelSummary}
          orderId={orderId}
          contrapositions={contrapositions}
          columns={columns}
          info={info}
          loading={loadingButtons}
        />
      )}
      <div className="actions-buttons">
        {status === 1 && isOwner && (
          <Dropdown overlay={menu} className="dropdown" trigger={["click", "hover"]}>
            <MoreOutlined style={{ fontSize: "26px", position: "absolute", top: 10, right: 5 }} />
          </Dropdown>
        )}
      </div>
    </>
  );
};

export default ReceivedOrdersActions;
