import { useCallback, useState } from "react";

/* 
  It prevents de body from scrolling by applying an overflow: hidden style to the body element.
  Example usage:

  import { useLockBodyScroll } from '...hooks/useLockBodyScroll'
  const { lockScroll, unlockScroll, isLocked } = useLockBodyScroll()

  - To lock the scroll: lockScroll()
  - To unlock the scroll: unlockScroll()
  - To check if the scroll is locked: isLocked
  
  Useful for modals, popups, sidebar.

  Note: 
  Always remember call unlockScroll() when you close a modal, sidebar, popup.
  Also if used in a useEffect, can call unlockScroll() in the cleanup function.
*/

const useLockBodyScroll = () => {
  const [isLocked, setIsLocked] = useState(false);

  const lockScroll = useCallback(() => {
    document.body.style.overflow = "hidden";
    setIsLocked(true);
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = "";
    setIsLocked(false);
  }, []);

  return {
    lockScroll,
    unlockScroll,
    isLocked,
  };
};

export default useLockBodyScroll;
