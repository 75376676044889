import { Spinner } from "Components";
import { useEffect } from "react";
import { OPERATION_POSITION } from "src/constants/operation";
import CloseSummary from "./CloseSummary";
import OpenSummary from "./OpenSummary";

import "../../BuyOrSellModal/BuyOrSellModal.scss";

//TODO prop types
const Summary = ({
  operation,
  onClickConfirm,
  onClickEdit,
  contraPositions,
  onClickGoToOperate,
  loading,
  submitting,
  onGetContraPosition,
  optionType,
}: any) => {
  const isOpen = operation.position === OPERATION_POSITION.OPEN;

  useEffect(() => {
    if (operation.position === OPERATION_POSITION.CLOSE) onGetContraPosition();
  }, [operation]);

  return (
    <div className={isOpen ? "summary-container" : "summary-container close"}>
      {loading && <Spinner className="close" spinning={true} size="large" />}
      {isOpen ? (
        <OpenSummary
          operation={operation}
          onClickConfirm={onClickConfirm}
          onClickEdit={onClickEdit}
          submitting={submitting}
          optionType={optionType}
        />
      ) : (
        <CloseSummary
          operation={operation}
          onClickConfirm={onClickConfirm}
          onClickEdit={onClickEdit}
          loading={submitting}
          contraPositions={contraPositions}
          onClickGoToOperate={onClickGoToOperate}
          optionType={optionType}
        />
      )}
    </div>
  );
};

export default Summary;
