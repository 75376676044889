import { ReactNode, useEffect } from 'react'
import { Modal, ModalProps } from 'antd'
import useLockBodyScroll from '../../../hooks/useLockBodyScroll'
import './EMatModal.scss'

export type EMatModalProps = ModalProps & { children: ReactNode }

const EMatModal = (props: EMatModalProps) => {
  const { lockScroll, unlockScroll } = useLockBodyScroll()

  const { className, children, ...rest } = props
  const classes = className + ' emat-modal'
  const { visible } = rest

  useEffect(() => {
    visible && lockScroll()

    return () => unlockScroll()
  }, [visible])

  return (
    <Modal className={classes} {...rest}>
      {children}
    </Modal>
  )
}

export default EMatModal
