import { OPERATION_POSITION } from "src/constants/operation";

export const hasPermissions = (
  userPermissions: string[] | undefined,
  permissions: string[] | undefined
) => {
  //check if at least one element of userPermissions array is include in permissions
  if (!permissions || permissions.length === 0) return true;
  if (!userPermissions || userPermissions?.length === 0) return false;

  return Array.isArray(userPermissions)
    ? userPermissions.some((p) => permissions.indexOf(p) >= 0)
    : permissions.indexOf(userPermissions) >= 0;
};

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return Object.assign({}, oldObject, updatedProperties);
  //  {
  //   ...oldObject,
  //   ...updatedProperties
  // };
};
const isBoolean = (val: any) => "boolean" === typeof val;
/*
  Useful for handle the position of an operation.
  From 'Open' to false and vice versa.
  From 'Closed' to true and vice versa.
*/
export const handlePosition = (value: boolean) => {
  //If handling a string we will convert it to boolean
  if (typeof value === "string") {
    return value === OPERATION_POSITION.CLOSE ? true : false;
  }

  //If we are handling boolean value we want to transform it to string
  if (isBoolean(value)) {
    return value ? OPERATION_POSITION.CLOSE : OPERATION_POSITION.OPEN;
  }
};

export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
