import { Empty, Table, TableProps } from "antd";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateObject } from "src/common/utils";
import "./EMatTable.scss";

const EMatTable = (props: TableProps<DefaultRecordType>) => {
  const [t] = useTranslation();
  const table = useRef<HTMLDivElement>(null);
  const { scroll, className } = props;
  const [fixedScroll, setFixedScroll] = useState<TableProps<any>["scroll"]>();

  const { current } = table;
  useLayoutEffect(() => {
    if (scroll) {
      //Handle show/hide scroll
      if (table && table.current && scroll) {
        const tbody = table?.current?.getElementsByClassName("ant-table-tbody")[0];
        if (scroll?.y && tbody.clientHeight >= scroll?.y) {
          setFixedScroll({ y: scroll.y });
        }
        if (scroll?.x && tbody.clientWidth >= scroll?.x) {
          setFixedScroll(updateObject(fixedScroll, { x: scroll.x }));
        }
      }
    }
  }, [current, scroll]);

  return (
    <Table
      ref={table}
      {...props}
      locale={{
        emptyText: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common:noData")}>
            {" "}
          </Empty>
        ),
        triggerDesc: t("common:sort.descent"),
        triggerAsc: t("common:sort.ascent"),
        cancelSort: t("common:sort.cancel"),
      }}
      className={className ? "eMat-table " + className : "eMat-table"}
      scroll={scroll && fixedScroll}
    />
  );
};

export default EMatTable;
