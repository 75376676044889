import { createAction } from "@reduxjs/toolkit";
import { AlertBodyType } from "src/types/alerts";

type GetInstrumentsBody = {
  operation: string;
  grain: string;
};

export type GetAlertsBody = {
  grain?: string;
  status?: number;
};

export const getAlerts = createAction<GetAlertsBody>("getAlerts");
export const getInstruments = createAction<GetInstrumentsBody>("getInstruments");
export const submitAlert = createAction<AlertBodyType>("submitAlert");
export const deleteAlert = createAction<{ id: number }>("deleteAlert");
