import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./SpinnerInParent.scss";

//Documentation

/* 
    Usefull when we want the spinner to fill his parent component.
    Note: The parent must be position relative.
*/

interface SpinnerProps extends SpinProps {
  backgroundColor?: "blue" | "green" | "black" | "white";
  withOpacity?: boolean;
  iconColor?: "blue" | "green" | "black" | "white";
}

const SpinnerInParent = ({ backgroundColor, withOpacity, iconColor, ...rest }: SpinnerProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      className={`spinner-in-parent 
      ${backgroundColor ? backgroundColor : ""} 
      ${withOpacity ? "with-opacity" : ""}
      ${iconColor ? `icon-${iconColor}` : ""}`}
    >
      <Spin indicator={antIcon} {...rest} />
    </div>
  );
};

export default SpinnerInParent;
