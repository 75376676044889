import i18next from "i18next";
import { Order } from "src/types/order";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DefaultRecordType } from "rc-table/lib/interface";
import { ColumnsType } from "antd/lib/table";
import { formatCurrency } from "src/helpers/formatCurrency";

export const parseOrderCardData = (orders: Order[]) => {
  return orders.map((order: Order) => [
    order.option_type === "Option"
      ? {
          label: i18next.t(`issued_orders:${order.option_type}.operation`),
          value:
            i18next.t(`issued_orders:${order.option_type}.${order.operation}`) +
            " " +
            i18next.t(`issued_orders:option.${order.agreement_type}`),
        }
      : {
          label: i18next.t(`issued_orders:${order.option_type}.operation`),
          value:
            i18next.t(`issued_orders:${order.option_type}.${order.operation}`) +
            " " +
            i18next.t(`issued_orders:operation-options.${order.option_type}`),
        },
    order.option_type === "Option" && {
      label: i18next.t(`issued_orders:Option.strike`),
      value: order.strike,
    },
    {
      label: i18next.t(`issued_orders:${order.option_type}.instrument`),
      value: <span className="value-summary-instrument">{order.instrument}</span>,
    },
    {
      label: i18next.t(`issued_orders:${order.option_type}.quantity`),
      value: `${order.quantity} Tn.`,
    },
    order.option_type !== "Option" && {
      label: i18next.t(`issued_orders:${order.option_type}.price`),
      value: order.price
        ? `${formatCurrency(order.price)} US$`
        : i18next.t(`issued_orders:${order.option_type}.to-market`),
    },
    order.option_type === "Option" && {
      label: i18next.t(`issued_orders:Option.prime`),
      value: order.bonus
        ? `$${order.bonus}`
        : i18next.t(`issued_orders:${order.option_type}.to-market`),
    },
    (order.price_from && order.price_to) != null && {
      label: i18next.t(`issued_orders:${order.option_type}.range`),
      value: <span className="range-elipsis">{`${formatCurrency(order.price_from)} US$ / ${formatCurrency(order.price_to)} US$`}</span>,
    },
    {
      label: i18next.t(`issued_orders:${order.option_type}.position`),
      value: i18next.t(`issued_orders:positionOptions.${order.position}`),
    },
  ]);
};

export const parceStatus = (status: string, parcialQuantity?: number) => {
  switch (status) {
    case "6":
      return (
        <span>
          {i18next.t(`received_orders:stateOptions.${status}`) + ` ${parcialQuantity} Tn.`}
          <span style={{ color: "#1cc3a7" }}>
            <CheckOutlined />
          </span>
        </span>
      );
    case "5":
      return i18next.t(`issued_orders:common.stateOptions.${status}`);

    case "4":
      return (
        <span>
          {i18next.t(`received_orders:stateOptions.${status}`)} <ExclamationCircleOutlined />
        </span>
      );
    case "3":
      return (
        <span>
          {i18next.t(`received_orders:stateOptions.${status}`) + " "}
          <span style={{ color: "#1cc3a7" }}>
            <CheckOutlined />
            <CheckOutlined />
          </span>
        </span>
      );
    case "2":
      return (
        <span>
          {i18next.t(`received_orders:stateOptions.${status}`)} <CheckOutlined />
          <CheckOutlined />
        </span>
      );
    case "1":
      return (
        <span>
          {i18next.t(`received_orders:stateOptions.${status}`)} <CheckOutlined />
        </span>
      );
    default:
      break;
  }
};

const renderValue = (text: any, row: object, index: number) => {
  return text === "" ? "-" : text;
};

export const columns: ColumnsType<DefaultRecordType> = [
  {
    title: i18next.t("issued_orders:table-contrapositions.price"),
    dataIndex: "price",
    key: "price",
    render: renderValue,
  },
  {
    title: i18next.t("issued_orders:table-contrapositions.quantity"),
    dataIndex: "quantity",
    key: "quantity",
    render: renderValue,
  },
];
