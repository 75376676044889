import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { ClientTons, ResultReportType } from "src/types/monitor";
import { clearAllStores } from "../actions/common";

const initialState = {
  loadingClients: false,
  clientsList: null,
  error: null,
  loadingMonitor: false,
  clientTonsData: null,
  resultReportData: null,
};

const monitor = createSlice({
  name: "monitor",
  initialState,
  reducers: {
    getClientsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingClients: true, clientsList: null, error: null }),
    getClientsSuccess: (state, { payload }: PayloadAction<ClientTypes[]>) =>
      updateObject(state, { loadingClients: false, clientsList: payload }),
    getClientsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingClients: false, error: payload }),

    getClientTonsStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingMonitor: true, clientTonsData: null, error: null }),
    getClientTonsSuccess: (state, { payload }: PayloadAction<ClientTons>) =>
      updateObject(state, { loadingMonitor: false, clientTonsData: payload }),
    getClientTonsFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingMonitor: false, error: payload }),

    getReultReportDataStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loadingMonitor: true, resultReportData: null, error: null }),
    getReultReportDataSuccess: (state, { payload }: PayloadAction<ResultReportType>) =>
      updateObject(state, { loadingMonitor: false, resultReportData: payload }),
    getReultReportDataFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loadingMonitor: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getClientsStart,
  getClientsSuccess,
  getClientsFail,
  getClientTonsStart,
  getClientTonsSuccess,
  getClientTonsFail,
  getReultReportDataStart,
  getReultReportDataSuccess,
  getReultReportDataFail,
} = monitor.actions;

export default monitor;
