import { put } from "redux-saga/effects";
import { usersService } from "Services";
import * as actions from "../../actions";
import * as sliceActions from "../../slices/users";
import { AxiosResponse } from "axios";

export function* getUsersSaga(action: ReturnType<typeof actions.getUsers>) {
  yield put(sliceActions.getUsersStart());
  try {
    const { data } = yield usersService.getClients();
    yield put(sliceActions.getUsersSuccess(data.data));
  } catch (error: any) {
    yield put(sliceActions.getUsersFail(error.message));
  }
}

export function* getUserByIdSaga(action: ReturnType<typeof actions.getUserById>) {
  yield put(sliceActions.getUserStart());
  try {
    const { data } = yield usersService.getUserById(action.payload.id);
    yield put(sliceActions.getUserSuccess(data));
  } catch (error: any) {
    yield put(sliceActions.getUserFail(error.message));
  }
}

export function* createUserSaga(action: ReturnType<typeof actions.createUser>) {
  yield put(sliceActions.createUserStart());
  try {
    const response: AxiosResponse<any> = yield usersService.createUser(action.payload);
    yield put(sliceActions.createUserSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.createUserFail("common:errors.somethingWentWrong"));
  }
}

export function* updateUser(action: ReturnType<typeof actions.updateUser>) {
  yield put(sliceActions.updateUserStart());
  try {
    const response: AxiosResponse<any> = yield usersService.updateUser(action.payload);
    yield put(sliceActions.updateUserSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.updateUserFail("common:errors.somethingWentWrong"));
  }
}

export function* updatePassword(action: ReturnType<typeof actions.updatePassword>) {
  yield put(sliceActions.updatePasswordStart());
  try {
    const response: AxiosResponse<any> = yield usersService.updatePassword(action.payload);
    yield put(sliceActions.updatePasswordSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.updatePasswordFail("common:errors.somethingWentWrong"));
  }
}
