export const ROUTE_PATHS = {
  DASHBOARD: "/dashboard",
  SIGN_UP: "/sign-up",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  RECOVER_PASSWORD: "/auth/recover-password",
  NEW_PASSWORD: "/auth/new-password",
  OPERATION: "/operation/:tab",
  OPERATION_FUTURES: "/operation/futures",
  OPERATION_OPTIONS: "/operation/options",
  OPERATION_SPREAD: "/operation/spread",
  ISSUED_ORDERS: "/issued-orders/:tab",
  ISSUED_ORDERS_CURRENT: "/issued-orders/current",
  ISSUED_ORDERS_HISTORY: "/issued-orders/history", 
  RECEIVED_ORDERS: "/received-orders",
  OWN_ACCOUNTS: "/own-accounts/:tab",
  OWN_ACCOUNTS_OPEN: "/own-accounts/open",
  OWN_ACCOUNTS_CLOSED: "/own-accounts/closed",
  CLIENTS_ACCOUNTS: "/clients-accounts/:tab",
  CLIENTS_ACCOUNTS_OPEN: "/clients-accounts/open",
  CLIENTS_ACCOUNTS_CLOSED: "/clients-accounts/closed",
  ALERTS: "/alerts",
  ALERTS_CREATE: "/alerts/create",
  MONITOR: "/monitor",
  MONITOR_VIEW: "/monitor/view",
  COMMISSIONS: "/commissions",
  NOTIFICATIONS: "/notifications",
  CLIENTS: "/clients",
  PROFILE: "/profile"
};
