import { errorIco } from "Assets";
import { EMatButton } from "Components";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

//TODO prop types
const FailSummary = ({ onClickGoToOperate, onClickEdit }: any) => {
  const [t] = useTranslation();

  return (
    <div className="fail-summary-container">
      <ReactSVG src={errorIco} />
      <h1>{t("operation:common.summary.no-operations")}</h1>
      <div className="buttons-container">
        <EMatButton type="primary" onClick={onClickGoToOperate}>
          {t("operation:common.summary.go-to-operate")}
        </EMatButton>
      </div>
    </div>
  );
};

export default FailSummary;
