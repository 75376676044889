import { EMatModal, EMatTabs } from "Components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OPERATION_POSITION, OPERATION_TYPES } from "src/constants/operation";
import Summary from "./EditSummary/Summary";
import EditBuyOrSellForm from "../EditBuyOrSellModal/EditFormBuyOrSell/EditFormBuyOrSell";
import "../BuyOrSellModal/BuyOrSellModal.scss";

const EditBuyOrSellModal = ({
  onCancel,
  onBuy,
  onSell,
  getContainer,
  issuedOrder,
  loading,
  isUpdate,
  contraPositions,
  onGetContraPosition,
  loadingContrapositions,
  defaultTab,
  optionType,
}: {
  onCancel: () => void;
  onBuy: (values: any) => void;
  onSell: (values: any) => void;
  getContainer?: string | HTMLElement | (() => HTMLElement) | false;
  issuedOrder: any;
  loading: boolean;
  isUpdate: boolean;
  contraPositions?: any[] | undefined | null;
  onGetContraPosition: (operation: string) => void;
  loadingContrapositions: boolean;
  defaultTab: string;
  optionType: string;
}) => {
  const [t] = useTranslation();
  const [operationType, setOperationType] = useState(OPERATION_TYPES.BUY);
  const [operationToSubmit, setOperationToSubmit] = useState<any>();
  const [operationToEdit, setOperationToEdit] = useState<any>();

  const handleSubmitSell = (values: any) => {
    const operation = values;
    operation.instrument = issuedOrder.instrument;
    operation.operation = OPERATION_TYPES.SELL;
    operation.optionType = issuedOrder.option_type;
    setOperationToSubmit(operation);
  };

  const handleSubmitBuy = (values: any) => {
    const operation = values;
    operation.instrument = issuedOrder.instrument;
    operation.operation = OPERATION_TYPES.BUY;
    operation.optionType = issuedOrder.option_type;
    setOperationToSubmit(operation);
  };

  const handleConfirmOperation = (values: any) => {
    values.operation.operation === OPERATION_TYPES.BUY ? onBuy(values) : onSell(values);
  };

  const tabs = [
    {
      tab: t("operation:futures.buy"),
      key: OPERATION_TYPES.BUY,
      component: (
        <EditBuyOrSellForm
          id="buy-form"
          instrument={issuedOrder.instrument}
          price={parseFloat(issuedOrder.bid)}
          onCancel={onCancel}
          onSubmit={handleSubmitBuy}
          loading={loading}
          isBuy={true}
          operationToEdit={issuedOrder}
          optionType={optionType}
        />
      ),
    },
    {
      tab: t("operation:futures.sell"),
      key: OPERATION_TYPES.SELL,
      component: (
        <EditBuyOrSellForm
          id="sell-form"
          instrument={issuedOrder.instrument}
          price={parseFloat(issuedOrder.offer)}
          onCancel={onCancel}
          onSubmit={handleSubmitSell}
          loading={loading}
          isBuy={false}
          operationToEdit={issuedOrder}
          optionType={optionType}
        />
      ),
    },
  ];

  const handleClickTab = (key: string) => {
    setOperationType(key);
  };

  const handleEdit = () => {
    setOperationToEdit(operationToSubmit);
    setOperationToSubmit(null);
  };

  const handleGoToOperate = () => {
    setOperationToSubmit(null);
    setOperationToEdit(null);
    onCancel();
  };

  return (
    <EMatModal
      closable={true}
      visible={true}
      title=""
      className={
        operationToSubmit
          ? operationToSubmit?.position === OPERATION_POSITION.CLOSE
            ? "buy-sell-modal background close"
            : "buy-sell-modal background"
          : "buy-sell-modal"
      }
      onCancel={onCancel}
      footer={null}
      getContainer={getContainer}
    >
      {operationToSubmit ? (
        <Summary
          operation={operationToSubmit}
          onClickConfirm={handleConfirmOperation}
          contraPositions={contraPositions}
          onClickEdit={handleEdit}
          onClickGoToOperate={handleGoToOperate}
          submitting={loading}
          onGetContraPosition={() => onGetContraPosition(operationType)}
          loading={loadingContrapositions}
          optionType={optionType}
        />
      ) : (
        <EMatTabs
          defaultActiveKey={defaultTab}
          size="large"
          className="operations-tabs"
          centered
          moreIcon={<div></div>}
          onTabClick={handleClickTab}
          tabs={tabs}
        />
      )}
    </EMatModal>
  );
};

export default EditBuyOrSellModal;
