import { useTranslation } from "react-i18next";
import "./Current.scss";
import ListOrderGroups from "src/components/ListOrdersGroups/ListOrdersGroups";
import { Pagination } from "Components";

const Current = (props: any) => {
  const { dataOrders, loadingOrders, setPage } = props;
  const [t] = useTranslation();
  return (
    <div className="background light-1 issued-orders-container" id="issued-orders-container">
      <ListOrderGroups
        groups={dataOrders.groups}
        loading={loadingOrders}
        labelCount={t(`issued_orders:issuedOrdersGroups`)}
        pagination={
          dataOrders &&
          dataOrders.current_page && (
            <Pagination
              pagination={{
                count: dataOrders.total,
                next_page:
                  dataOrders.current_page === dataOrders.last_page
                    ? null
                    : dataOrders.current_page + 1,
                prev_page: dataOrders.current_page !== 1 ? dataOrders.current_page - 1 : null,
                page_size: dataOrders.per_page,
              }}
              setPrevPage={() => setPage(dataOrders.current_page + 1)}
              setNextPage={() =>
                setPage(dataOrders.current_page - 1 !== 0 ? dataOrders.current_page - 1 : 1)
              }
            />
          )
        }
        clientId=""
      />
    </div>
  );
};

export default Current;
