import { Logo, TextLogo } from "Assets";
import "./EMatLogo.scss";

const EMatLogo = ({ collapsed }: { collapsed?: boolean }) => {
  return (
    <div className="logo-container">
      <img id="logo" src={Logo} />
      {!collapsed && <img id="text-logo" src={TextLogo} />}
    </div>
  );
};

export default EMatLogo;
