import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AntSpin from "src/components/UI/AntSpinner/AntSpin";
import { ROUTE_PATHS } from "src/constants/routePaths";
import "./Orders.scss";

const Orders = ({
  emitted,
  received,
  loading,
}: {
  emitted?: number;
  received?: number;
  loading: boolean;
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleClickIssuedOrders = () => {
    navigate(ROUTE_PATHS.ISSUED_ORDERS);
  };

  const handleClickReceivedOrdes = () => {
    navigate(ROUTE_PATHS.RECEIVED_ORDERS);
  };

  return (
    <div className="orders-container">
      {loading ? (
        <AntSpin />
      ) : (
        <>
          <div id="emitted-orders" onClick={handleClickIssuedOrders}>
            <span className="order-count">{emitted ? emitted : 0}</span>
            <h3>{t("dashboard:orders.emitted")}</h3>
          </div>

          {received !== undefined && (
            <>
              <div className="divider-container">
                <div className="divider vertical"></div>
              </div>
              <div id="received-orders" onClick={handleClickReceivedOrdes}>
                <span className="order-count">{received}</span>
                <h3>{t("dashboard:orders.received")}</h3>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
