import { lazy } from "react";
import PERMISSIONS from "src/constants/permissions";
import { ROUTE_PATHS } from "../constants/routePaths";
import { Clients, Notifications } from "Pages";

const PageNotfound = lazy(() => import("../containers/PageNotFound/PageNotFound"));
const SignIn = lazy(() => import("../containers/Auth/SignIn/SignIn"));
const SignUp = lazy(() => import("../containers/Users/SignUp/SignUp"));
const RecoverPassword = lazy(() => import("../containers/Auth/RecoverPassword/RecoverPassword"));
const ChangePassword = lazy(() => import("../containers/Auth/ChangePassword/ChangePassword"));
const Dashboard = lazy(() => import("../containers/Dashboard/Dashboard"));
const Logout = lazy(() => import("../containers/Auth/SignOut/SignOut"));
const Operation = lazy(() => import("../containers/Operation/Operation"));
const IssuedOrders = lazy(() => import("../containers/IssuedOrders/IssuedOrders"));
const ReceivedOrders = lazy(() => import("../containers/ReceivedOrders/ReceivedOrders"));
const OwnAccounts = lazy(() => import("../containers/Accounts/Own"));
const ClientsAccounts = lazy(() => import("../containers/Accounts/Clients"));
const Alerts = lazy(() => import("../containers/Alerts/Alerts"));
const Monitor = lazy(() => import("../containers/Monitor/Monitor"));
const Commissions = lazy(() => import("../containers/Commissions/Commissions"));
const Profile = lazy(() => import("../containers/Profile/Profile"));

const routeArray = [
  {
    exact: true,
    path: "/",
    component: SignIn,
  },
  {
    path: ROUTE_PATHS.LOGIN,
    component: SignIn,
  },
  {
    path: ROUTE_PATHS.RECOVER_PASSWORD,
    component: RecoverPassword,
  },
  {
    path: ROUTE_PATHS.NEW_PASSWORD,
    component: ChangePassword,
  },
  {
    path: ROUTE_PATHS.LOGOUT,
    component: Logout,
  },
  {
    path: ROUTE_PATHS.SIGN_UP,
    private: true,
    component: SignUp,
    permissions: PERMISSIONS.DASHBOARD,
    nameKey: "signUp",
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    private: true,
    component: Dashboard,
    permissions: PERMISSIONS.DASHBOARD,
    nameKey: "dashboard",
  },
  {
    exact: true,
    path: ROUTE_PATHS.OPERATION,
    private: true,
    component: Operation,
    permissions: PERMISSIONS.DASHBOARD,
    nameKey: "operation",
    routes: [
      {
        path: ROUTE_PATHS.OPERATION_FUTURES,
        private: true,
        component: Operation,
        permissions: PERMISSIONS.DASHBOARD,
        nameKey: "operation",
      },
      {
        path: ROUTE_PATHS.OPERATION_OPTIONS,
        private: true,
        component: Operation,
        permissions: PERMISSIONS.DASHBOARD,
        nameKey: "operation",
      },
      {
        path: ROUTE_PATHS.OPERATION_SPREAD,
        private: true,
        component: Operation,
        permissions: PERMISSIONS.DASHBOARD,
        nameKey: "operation",
      },
    ],
  },
  {
    exact: true,
    path: ROUTE_PATHS.ISSUED_ORDERS,
    private: true,
    component: IssuedOrders,
    permissions: PERMISSIONS.ISSUED_ORDERS,
    nameKey: "issued-orders",
    routes: [
      {
        path: ROUTE_PATHS.ISSUED_ORDERS_CURRENT,
        private: true,
        component: IssuedOrders,
        permissions: PERMISSIONS.ISSUED_ORDERS,
        nameKey: "issued-orders",
      },
      {
        path: ROUTE_PATHS.ISSUED_ORDERS_HISTORY,
        private: true,
        component: IssuedOrders,
        permissions: PERMISSIONS.ISSUED_ORDERS,
        nameKey: "issued-orders",
      },
    ],
  },
  {
    path: ROUTE_PATHS.RECEIVED_ORDERS,
    private: true,
    component: ReceivedOrders,
    permissions: PERMISSIONS.RECEIVED_ORDERS,
    nameKey: "received-orders",
  },
  {
    exact: true,
    path: ROUTE_PATHS.OWN_ACCOUNTS,
    private: true,
    component: OwnAccounts,
    permissions: PERMISSIONS.OWN_ACCOUNTS,
    nameKey: "own-accounts",
    routes: [
      {
        path: ROUTE_PATHS.OWN_ACCOUNTS_OPEN,
        private: true,
        component: OwnAccounts,
        permissions: PERMISSIONS.OWN_ACCOUNTS,
        nameKey: "own-accounts",
      },
      {
        path: ROUTE_PATHS.OWN_ACCOUNTS_CLOSED,
        private: true,
        component: OwnAccounts,
        permissions: PERMISSIONS.OWN_ACCOUNTS,
        nameKey: "own-accounts",
      },
    ],
  },
  {
    exact: true,
    path: ROUTE_PATHS.CLIENTS_ACCOUNTS,
    private: true,
    component: ClientsAccounts,
    permissions: PERMISSIONS.CLIENTS_ACCOUNTS,
    nameKey: "client-accounts",
    routes: [
      {
        path: ROUTE_PATHS.CLIENTS_ACCOUNTS_OPEN,
        private: true,
        component: ClientsAccounts,
        permissions: PERMISSIONS.CLIENTS_ACCOUNTS,
        nameKey: "client-accounts",
      },
      {
        path: ROUTE_PATHS.CLIENTS_ACCOUNTS_CLOSED,
        private: true,
        component: ClientsAccounts,
        permissions: PERMISSIONS.CLIENTS_ACCOUNTS,
        nameKey: "client-accounts",
      },
    ],
  },
  {
    path: ROUTE_PATHS.MONITOR,
    private: true,
    component: Monitor,
    permissions: PERMISSIONS.MONITOR,
    nameKey: "monitor",
    routes: [
      {
        path: ROUTE_PATHS.MONITOR_VIEW,
        private: true,
        component: Monitor,
        permissions: PERMISSIONS.MONITOR,
        nameKey: "monitor",
      },
    ],
  },
  {
    path: ROUTE_PATHS.ALERTS,
    private: true,
    component: Alerts,
    permissions: PERMISSIONS.ALERTS,
    nameKey: "alerts",
    routes: [],
  },
  {
    path: ROUTE_PATHS.COMMISSIONS,
    private: true,
    component: Commissions,
    permissions: PERMISSIONS.COMMISSIONS,
    nameKey: "commissions",
    routes: [],
  },
  {
    path: ROUTE_PATHS.NOTIFICATIONS,
    private: true,
    component: Notifications,
    permissions: PERMISSIONS.NOTIFICATIONS,
    nameKey: "notifications",
    routes: [],
  },
  {
    path: ROUTE_PATHS.CLIENTS,
    private: true,
    component: Clients,
    permissions: PERMISSIONS.CLIENTS,
    nameKey: "commissions",
    routes: [],
  },
  {
    path: ROUTE_PATHS.PROFILE,
    private: true,
    component: Profile,
    permissions: PERMISSIONS.PROFILE,
    nameKey: "profile",
    routes: [],
  },
  {
    path: "*",
    component: PageNotfound,
  },
];

export default routeArray;
