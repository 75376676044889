import { EMatButton, EMatFormItem, EMatInput, EMatModal } from "Components";
import { Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { validatePartialQuantity } from "src/common/orderValidations";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";

const EMatGroupSummay = ({
  onCancel,
  getContainer,
  onClick,
  order,
  position,
  labelTitle,
  buttonAccept,
  buttonCancel,
  orderId,
  loading,
  partiallyOperated,
}: {
  order: any;
  onCancel: () => void;
  getContainer?: string | HTMLElement | (() => HTMLElement) | false;
  onClick: (id: any, quantity?: any) => void;
  position: string;
  labelTitle: string;
  buttonAccept: string;
  buttonCancel: string;
  orderId: string;
  contrapositions: any;
  columns: any;
  info: any;
  loading: boolean;
  partiallyOperated?: boolean;
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { quantity } = order

  const max = quantity

  const initialValues = {
    quantity: null,
  };

  return (
    <EMatModal
      closable={true}
      visible={true}
      title=""
      className="buy-sell-modal background close"
      onCancel={onCancel}
      footer={() => <div></div>}
      getContainer={getContainer}
    >
      <div className="summary-container">
        <h1>{labelTitle}</h1>

        {partiallyOperated && (
          <Row>
            <h2>{t("received_orders:summary-operated.parcial-operated-title")}</h2>
            <Form
              id={"id"}
              form={form}
              onFinish={(values) => onClick(orderId, values.quantity)}
              onFinishFailed={(error) => console.log(error)}
              initialValues={initialValues}
            >
              <EMatFormItem
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t("common:errors:fieldRequiredDefault"),
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: t("common:errors.invalidValue", {
                      field: t("operation:futures.quantity"),
                    }),
                  },
                  {
                    validator: (rule, value) => validatePartialQuantity(value, t, max),
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <EMatInput
                  min="0"
                  step={100}
                  eMatType={EMAT_INPUT_TYPES.NUMBER}
                  id="quantity"
                  placeholder={t("operation:futures.quantity")}
                  suffix={` / ${quantity} Tn.`}
                />
              </EMatFormItem>
              <div className="buttons-container">
                <EMatButton type="primary" id="submit" htmlType="submit" loading={loading}>
                  {buttonAccept}
                </EMatButton>
                <EMatButton type="text" id="edit" onClick={onCancel}>
                  {buttonCancel}
                </EMatButton>
              </div>
            </Form>
          </Row>
        )}

        {!partiallyOperated && (
          <div className="buttons-container close">
            <EMatButton type="primary" onClick={() => onClick(orderId)} loading={loading}>
              {buttonAccept}
            </EMatButton>
            <EMatButton type="text" id="edit" onClick={onCancel}>
              {buttonCancel}
            </EMatButton>
          </div>
        )}
      </div>
    </EMatModal>
  );
};

export default EMatGroupSummay;
