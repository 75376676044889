import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { SelectedMenu } from "src/types/common";
import { clearAllStores } from "../actions/common";

const initialState = {
  selectedPage: "",
  selectedPath: undefined,
};

const layout = createSlice({
  name: "spinner",
  initialState: initialState,
  reducers: {
    onSelectMenu: (state, { payload }: PayloadAction<SelectedMenu, string>) =>
      updateObject(state, {
        selectedPage: payload.selectedPage,
        selectedPath: payload.selectedPath,
      }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const { onSelectMenu } = layout.actions;

export default layout;
