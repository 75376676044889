import { WifiOutlined, LineOutlined } from "@ant-design/icons";
import { EMatButton, EMatLogo } from "Components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useAppSelector } from "src/hooks/reduxHooks";
import "./OfflinePage.scss";

const OfflinePage = (props: any) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const previousPath = useAppSelector((state) => state.layout.selectedPath);

  const handleRetry = () => {
    navigate(previousPath || ROUTE_PATHS.DASHBOARD);
  };

  const WifiSlashed = () => (
    <span className="no-connection">
      <LineOutlined rotate={40} />
      <WifiOutlined />
    </span>
  );

  return (
    <div className="offline-page">
      <div className="offline-info-container">
        <WifiSlashed />
        <div>
          <h1>{t("common:noConnection")}</h1>
          <h3>{t("common:checkYourConnection")}</h3>
          <EMatButton type="primary" id="redirect-last-page" onClick={handleRetry}>
            {t("common:retryConnection")}
          </EMatButton>
          <EMatLogo />
        </div>
      </div>
    </div>
  );
};

export default OfflinePage;
