import { useEffect } from "react";
import Spinner from "src/components/UI/Spinner/Spinner";
import { OPERATION_POSITION } from "src/constants/operation";
import { OperationType } from "src/types/operation";
import CloseSummary from "./CloseSummary";
import OpenSummary from "./OpenSummary";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface Props {
  operation: any;
  loading: boolean;
  onClickConfirm: (operation: OperationType) => void;
  onClickEdit: () => void;
  onCancel: () => void;
  contraPositions: any;
  onGetContraPosition: (operation: string) => void;
  setIsSumaryVisible?: (value: boolean) => void;
}

const Summary = ({
  operation,
  loading,
  onClickConfirm,
  onClickEdit,
  contraPositions,
  onGetContraPosition,
  onCancel,
  setIsSumaryVisible,
}: Props) => {
  const isOpen = operation.position === OPERATION_POSITION.OPEN;

  useEffect(() => {
    if (!isOpen) {
      onGetContraPosition(operation.operation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, isOpen]);

  return (
    <div className="summary-container close">
      <ArrowLeftOutlined className="arrow" onClick={() => onClickEdit()} />
      {loading && <Spinner spinning={true} size="large" />}
      {isOpen ? (
        <OpenSummary
          operationInfo={operation}
          onClickEdit={onClickEdit}
          onClickConfirm={onClickConfirm}
          onFinalize={setIsSumaryVisible ? () => setIsSumaryVisible(true) : () => null}
          loading={loading}
        />
      ) : (
        <CloseSummary
          operationInfo={operation}
          onClickEdit={onClickEdit}
          onClickConfirm={onClickConfirm}
          onFinalize={setIsSumaryVisible ? () => setIsSumaryVisible(true) : () => null}
          onCancel={onCancel}
          contraPositions={contraPositions}
        />
      )}
    </div>
  );
};

export default Summary;
