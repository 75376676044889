import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { deleteFirebaseToken } from "src/firebase/firebase";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { logout } from "src/store/actions/auth";

const SignOut = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const navigate = useNavigate();

  useEffect(() => {
    !!token && navigate(ROUTE_PATHS.LOGIN);
  }, [token]);

  useEffect(() => {
    dispatch(logout(token));
    deleteFirebaseToken();
  }, [logout]);

  return <Navigate to={ROUTE_PATHS.LOGIN} />;
};

export default SignOut;
