import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import { EMatModal } from "Components";
import { OPERATION_POSITION } from "src/constants/operation";
import { Col, Row } from "antd";
import { arrowDown } from "Assets";
import { ReactSVG } from "react-svg";
import { DefaultOptionType } from "antd/lib/cascader";
import { EMatButton } from "Components";
import "../BuyOrSellModal/BuyOrSellForm/BuyOrSellForm.scss";

const SummaryExercise = ({
  infoPosition,
  totalQuantity,
  getContainer,
  position,
  labelTitle,
  buttonAccept,
  buttonCancel,
  onCancel,
  onClick,
}: {
  infoPosition: any;
  totalQuantity: any;
  getContainer: any;
  position: any;
  labelTitle: any;
  buttonAccept: any;
  buttonCancel: any;
  onCancel: () => void;
  onClick: (info: any, totalQuantity: any) => void;
}) => {
  const { t } = useTranslation();

  const content = [
    {
      label: t(`issued_orders:Future.business_name`),
      value: infoPosition.business_name,
    },
    {
      label: t(`issued_orders:Future.operation`),
      value:
        t(`issued_orders:Future.${infoPosition.side}`) +
        " " +
        t(`issued_orders:operation-options.Future`),
    },
    {
      label: t(`issued_orders:Future.instrument`),
      value: <span className="value-summary-instrument">{infoPosition.instrument}</span>,
    },
    {
      label: t(`issued_orders:Future.quantity`),
      value: `${totalQuantity} Tn.`,
    },
    {
      label: t(`issued_orders:Future.position`),
      value: t(`issued_orders:positionOptions.Open`),
    },
    {
      label: t(`issued_orders:Future.state`),
      value: (
        <span>
          {" "}
          {t(`received_orders:stateOptions.1`)} <CheckOutlined />
        </span>
      ),
    },
  ];

  return (
    <div>
      <EMatModal
        closable={true}
        visible={true}
        title=""
        className={
          position
            ? position === OPERATION_POSITION.CLOSE
              ? "buy-sell-modal background close"
              : "buy-sell-modal background"
            : "buy-sell-modal"
        }
        onCancel={onCancel}
        footer={() => <div></div>}
        getContainer={getContainer}
      >
        <div className="summary-container">
          <h1>{labelTitle}</h1>
          <div className="summary-values">
            {content?.map((item: DefaultOptionType, i: any) => {
              if (!item) {
                return null;
              }
              return (
                <Row gutter={8} key={i}>
                  <Col span={12} className="label">
                    {item.label}
                  </Col>
                  <Col span={12} className="value-summary">
                    {item.value}
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className="buttons-container">
            <EMatButton
              type="primary"
              onClick={() => onClick(infoPosition, totalQuantity)}
              // loading={loading}
            >
              {buttonAccept}
            </EMatButton>
            <EMatButton type="text" id="edit" onClick={onCancel}>
              {buttonCancel}
            </EMatButton>
          </div>
        </div>
      </EMatModal>
    </div>
  );
};

export default SummaryExercise;
