import { EMatButton, EMatFormItem, EMatInput, EMatModal, EMatTable } from "Components";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../store/actions";
import { useEffect } from "react";
import { clearOperationSubmitted } from "src/store/slices/operation";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useNavigate } from "react-router-dom";
import { Order } from "src/types/order";
import { validateQuantity } from "src/common/orderValidations";
import "./EmatOrderGroupModal.scss";
import { formatCurrency } from "src/helpers/formatCurrency";

const EmatOrderGroupModal = ({
  orders,
  onCancel,
  visible,
  quantity,
  isRollOver,
}: {
  visible: boolean;
  orders: any[];
  onCancel: () => void;
  quantity?: number;
  isRollOver?: boolean;
}) => {
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector((state) => state.operation.loadingOperation);
  const navigate = useNavigate();
  const operationGroupSubmitted = useAppSelector(
    (state) => state.operation.operationGroupSubmitted
  );
  const columns = [
    {
      title: t("operation:groups.table-titles.instrument"),
      dataIndex: "instrument",
      key: "instrument",
    },
    {
      title: "Op.",
      dataIndex: "operation",
      key: "operation",
      render: (value: string) => t(`operation:common.${value.toLowerCase()}`),
    },
    {
      title: t("operation:groups.table-titles.type"),
      dataIndex: "optionType",
      key: "optionType",
      render: (value: string) => t(`operation:common.option-type.${value.toLowerCase()}`),
    },
    {
      title: t("operation:groups.table-titles.price"),
      dataIndex: "price",
      key: "price",
      render: (value: string, row: any) =>
        row.toMarket === 1 ? t("operation:common.to-market") : formatCurrency(value),
    },
    {
      title: t("operation:groups.table-titles.position"),
      dataIndex: "position",
      key: "position",
      render: (value: string) => t(`operation:common.${value.toLowerCase()}`),
    },
    {
      title: t("operation:groups.table-titles.quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (value: string) => (quantity ? quantity : value),
      hidden: isRollOver,
    },
  ];

  const handleSubmit = (values: any) => {
    if (isRollOver) {
      const quantity = parseInt(values.quantity);
      values.orders = orders.map((order) => ({ ...order, quantity }));
      delete values.quantity;
    } else {
      values.orders = orders;
      if (quantity) {
        values.orders = orders.map((order) => ({ ...order, quantity }));
      }
    }
    dispatch(actions.createOperationGroup(values));
  };

  useEffect(() => {
    if (operationGroupSubmitted) {
      dispatch(clearOperationSubmitted());
      navigate(ROUTE_PATHS.ISSUED_ORDERS_CURRENT);
    }
  }, [operationGroupSubmitted]);

  return (
    <EMatModal
      closable={true}
      footer={null}
      visible={visible}
      title=""
      className=""
      onCancel={onCancel}
      width="70%"
    >
      <div className="">
        {columns.length > 0 && (
          <EMatTable
            id={"tableId"}
            columns={columns}
            dataSource={orders.length > 0 ? orders : []}
            rowKey="_id"
            pagination={false}
          />
        )}
        <Form form={form} onFinish={handleSubmit}>
          <div className="operations-group-form">
            {isRollOver && (
              <EMatFormItem
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t("common:errors:fieldRequiredDefault"),
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: t("common:errors.invalidValue", {
                      field: t("operation:futures.quantity"),
                    }),
                  },
                  { validator: (rule, value) => validateQuantity(rule, value, t, form) },
                ]}
                validateTrigger={["onBlur"]}
                initialValue={quantity}
                className="op-input"
              >
                <EMatInput
                  min="0"
                  step={100}
                  eMatType={EMAT_INPUT_TYPES.NUMBER}
                  id="quantity"
                  placeholder={t("operation:futures.quantity")}
                  suffix="Tn."
                />
              </EMatFormItem>
            )}

            <EMatFormItem name="description" validateTrigger={["onBlur"]} className="op-input">
              <EMatInput eMatType={EMAT_INPUT_TYPES.TEXT} placeholder={"Comentario..."} />
            </EMatFormItem>
          </div>

          <EMatButton
            type="primary"
            id="submit"
            htmlType="submit"
            loading={isSubmitting}
            className="submit-button"
          >
            {t("operation:groups.submit")}
          </EMatButton>
        </Form>
      </div>
    </EMatModal>
  );
};

export default EmatOrderGroupModal;
