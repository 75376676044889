import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Radio, Row, Space } from "antd";
import { EMatButton, EMatFormItem, EMatInput } from "Components";
import "./CallPutForm.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { AGREEMENT_TYPE, OPERATION_TYPES } from "src/constants/operation";
import { handlePosition } from "src/common/utils";
import {
  validatePrice,
  validatePriceFrom,
  validatePriceTo,
  validateQuantity,
} from "src/common/orderValidations";

interface Props {
  instrumentStrike: any;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  operationToEdit?: any;
  agreementType: string;
  operationType?: string;
  tons?: number;
}

const CallPutForm = ({
  instrumentStrike,
  onCancel,
  onSubmit,
  operationToEdit,
  agreementType,
  operationType,
  tons
}: Props) => {
  const { instrument, strike, call_sell, call_buy, put_buy, put_sell } = instrumentStrike;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [priceDisabled, setPriceDisabled] = useState<true | false>(
    operationToEdit?.toMarket || instrumentStrike?.toMarket ? true : false
  );
  const [isBuyType, setIsBuyType] = useState<boolean>(
    operationType || operationToEdit?.operation || instrumentStrike?.operation
      ? operationType === OPERATION_TYPES.BUY ||
        operationToEdit?.operation === OPERATION_TYPES.BUY ||
        instrumentStrike?.operation === OPERATION_TYPES.BUY
        ? true
        : false
      : true
  );

  const validatePrice = (rule: any, value: any) => {
    try {
      if (priceDisabled) return Promise.resolve();
      const intValue = parseFloat(value);
      if (intValue <= 0)
        return Promise.reject(new Error(t("common:errors.biggerThanValue", { min: "0" })));
    } catch (error) {
      return Promise.reject(
        new Error(
          t("common:errors.invalidValue", {
            field: t("operation:options.price"),
          })
        )
      );
    }
    return Promise.resolve();
  };

  const handleToMarket = (e: any) => {
    if (e.target.checked) {
      setPriceDisabled(true);
      form.resetFields(["bonus", "priceTo", "priceFrom"]);
      form.setFieldsValue({
        bonus: undefined,
        priceTo: undefined,
        priceFrom: undefined,
      });
      form.setFieldsValue({ toMarket: 1 });
    } else {
      setPriceDisabled(false);
      form.setFieldsValue({ toMarket: 0 });
    }
  };

  const handleBuyOrSell = ({ target }: any) => {
    const operationType = target.value;
    //Reset the rest of the inputs when switching between buy and sell
    //No to default values but to empty strings or preferred value
    form.setFieldsValue({
      quantity: tons,
      bonus: operationToEdit ? "" : handleBonusValue(agreementType, operationType),
      toMarket: 0,
      priceTo: "",
      priceFrom: "",
      position: false,
    });
    setIsBuyType(operationType === OPERATION_TYPES.BUY ? true : false);
    setPriceDisabled(false);
  };

  const handleBonusValue = (agreementType: string, operationType: string) => {
    if (operationToEdit) {
      if (operationToEdit.toMarket === 1) {
        return "";
      }
      if (operationToEdit.toMarket === 0) {
        return operationToEdit.bonus;
      }
    } else {
      if (agreementType === AGREEMENT_TYPE.CALL && operationType === OPERATION_TYPES.BUY) {
        return call_buy;
      }
      if (agreementType === AGREEMENT_TYPE.CALL && operationType === OPERATION_TYPES.SELL) {
        return call_sell;
      }
      if (agreementType === AGREEMENT_TYPE.PUT && operationType === OPERATION_TYPES.BUY) {
        return put_buy;
      }
      if (agreementType === AGREEMENT_TYPE.PUT && operationType === OPERATION_TYPES.SELL) {
        return put_sell;
      }
    }
  };

  const initialFormValues = {
    operation: isBuyType ? OPERATION_TYPES.BUY : OPERATION_TYPES.SELL,
    quantity: operationToEdit?.quantity || instrumentStrike?.quantity || tons || "",
    bonus:
      operationToEdit?.bonus ||
      instrumentStrike?.bonus ||
      handleBonusValue(agreementType, isBuyType ? OPERATION_TYPES.BUY : OPERATION_TYPES.SELL),
    toMarket: operationToEdit?.toMarket || instrumentStrike?.toMarket || 0,
    priceFrom: operationToEdit?.priceFrom || instrumentStrike?.priceFrom || "",
    priceTo: operationToEdit?.priceTo || instrumentStrike?.priceTo || "",
    position: operationToEdit?.position ? handlePosition(operationToEdit.position) : false,
  };

  return (
    <div className="call-put-form">
      <div className="strike-instrument">
        <h3>
          <span>
            {t("operation:options.strike")} {operationToEdit ? operationToEdit.strike : strike}
          </span>
          {operationToEdit ? operationToEdit.instrument : instrument}
        </h3>
      </div>
      <Form
        onFinish={onSubmit}
        onFinishFailed={(error) => console.log(error)}
        form={form}
        initialValues={initialFormValues}
      >
        {/* BUY OR SELL */}
        <EMatFormItem name="operation">
          <Radio.Group onChange={handleBuyOrSell} buttonStyle="solid">
            <Space>
              <EMatInput value={OPERATION_TYPES.BUY} eMatType={EMAT_INPUT_TYPES.RADIO}>
                {t("operation:options.buy")}
              </EMatInput>
              <EMatInput value={OPERATION_TYPES.SELL} eMatType={EMAT_INPUT_TYPES.RADIO}>
                {t("operation:options.sell")}
              </EMatInput>
            </Space>
          </Radio.Group>
        </EMatFormItem>

         {/* QUANTITY */}
         <EMatFormItem
          name="quantity"
          rules={[
            {
              required: true,
              message: t("common:errors:fieldRequiredDefault"),
            },
            {
              pattern: new RegExp(/^[0-9]+$/),
              message: t("common:errors.invalidValue", {
                field: t("operation:futures.quantity"),
              }),
            },
            { validator: (rule, value) => validateQuantity(rule, value, t, form) },
          ]}
          validateTrigger={["onBlur"]}
        >
          <EMatInput
            min="0"
            step={100}
            eMatType={EMAT_INPUT_TYPES.NUMBER}
            id="quantity"
            placeholder={t("operation:futures.quantity")}
            suffix="Tn."
            disabled={tons !== null}
          />
        </EMatFormItem>

        {/* PREMIUM PRICE  & TO-MARKET CHECKBOX */}
        <Row gutter={16} align="middle">
          <Col xs={12}>
            <EMatFormItem
              name="bonus"
              rules={[
                {
                  required: !priceDisabled,
                  message: t("common:errors:fieldRequiredDefault"),
                },
                {
                  validator: validatePrice,
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                min="0"
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="bonus"
                placeholder={t("operation:options.price")}
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
          <Col xs={12}>
            <EMatFormItem name="toMarket" valuePropName="checked">
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.CHECKBOX}
                id="bonus-to-Market"
                onChange={handleToMarket}
              >
                {t("operation:futures.to-market")}
              </EMatInput>
            </EMatFormItem>
          </Col>
        </Row>
        {/* PRICE-FROM & PRICE-TO */}
        <Row gutter={16}>
          <Col xs={12}>
            <EMatFormItem
              name="priceFrom"
              rules={[{ validator: (rule, value) => validatePriceFrom(rule, value, t, form) }]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                min="0"
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="price-from"
                placeholder={t("operation:futures.priceFrom")}
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
          <Col xs={12}>
            <EMatFormItem
              name="priceTo"
              rules={[
                {
                  required: !priceDisabled,
                  message: t("common:errors:fieldRequiredDefault"),
                },
                { validator: (rule, value) => validatePriceTo(rule, value, t, form) },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                min="0"
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="price-from"
                placeholder={t("operation:futures.priceTo")}
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
        </Row>
        {/* POSITION SWITCH */}
        <EMatFormItem className="open-close-switch">
          <Row align="middle" justify="center">
            <span className="option">{t("operation:futures.open")}</span>
            <EMatFormItem name="position" valuePropName="checked">
              <EMatInput eMatType={EMAT_INPUT_TYPES.SWITCH} id="open-close-switch" />
            </EMatFormItem>
            <span className="option">{t("operation:futures.close")}</span>
          </Row>
        </EMatFormItem>

        {/* ACTIONS BUTTONS */}
        <EMatFormItem className="buttons-container">
          <EMatButton type="text" id="cancel" onClick={onCancel}>
            {t("common:actions.cancel")}
          </EMatButton>
          <EMatButton type="primary" id="submit" htmlType="submit" loading={false}>
            {t("common:actions.accept")}
          </EMatButton>
        </EMatFormItem>
      </Form>
    </div>
  );
};

export default CallPutForm;
