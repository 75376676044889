import { put } from "redux-saga/effects";
import * as actions from "../../actions/common";
import { onSelectMenu } from "src/store/slices/layout";
import { ROUTE_PATHS } from "src/constants/routePaths";

export function* selectMenuSaga(action: ReturnType<typeof actions.onSelectMenu>) {
  // default route is Dashboards. Should be accessible for all users

  if (action.payload.selectedPath !== ROUTE_PATHS.LOGIN && action.payload.selectedPath !== "/") {
    yield localStorage.setItem(
      "previousPath",
      action.payload.selectedPath || ROUTE_PATHS.DASHBOARD
    );
    yield put(onSelectMenu(action.payload));
  }
}
