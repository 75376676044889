import instance from "src/api/axios-instance";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "./../../store/actions/index";
import { EMatButton, EMatTable } from "Components";
import { useEffect, useState } from "react";
import "./Notifications.scss";
import { useDispatch } from "react-redux";

const Commissions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useAppSelector((state) => state.notifications.loadingScreenNotifications);
  const notifications = useAppSelector((state) => state.notifications.screenNotifications || []);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();

  const columns = [
    {
      title: t("notifications:title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("notifications:message"),
      dataIndex: "body",
      key: "body",
      width: "75%",
    },
  ];

  const rowClassName = (record: any): string =>
    record.checked ? "notificationChecked" : "notificationUnchecked";

  const markAsReaded = () => {
    if (selectedRowKeys) {
      dispatch(actions.markAsChecked(selectedRowKeys));
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    dispatch(actions.getScreenNotifications(true));
  }, []);

  return (
    <div className="main-content background light-3" id="notifications-container">
      <div className="button-container">
        <EMatButton
          type="primary"
          id="submit"
          htmlType="submit"
          loading={loading}
          onClick={() => markAsReaded()}
        >
          {t("notifications:mark-cheked")}
        </EMatButton>
      </div>
      <EMatTable
        id="contra-positions"
        columns={columns}
        dataSource={notifications}
        rowKey={"id"}
        rowClassName={rowClassName}
        pagination={{ position: ["bottomRight"], pageSize: 10, size: "small" }}
        loading={loading}
        rowSelection={{ selectedRowKeys, onChange: (selected) => setSelectedRowKeys(selected) }}
      />
    </div>
  );
};

export default withErrorHandler(Commissions, instance);
