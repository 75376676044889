import { useState } from "react";
import { EMatButton, EMatTable } from "Components";
import { useTranslation } from "react-i18next";
import { UpCircleTwoTone, DownCircleTwoTone } from "@ant-design/icons";
import { Row } from "antd";

// estado 1 (enviada) puede pasar a estado 2 (recibida) o estado 5 (enviada a operador)
// estado 2 (recibida) puede pasar a estado 3 (operada) -- solo para user master
// estado 3 (operada) estado final -- solo para user master
// estado 4 (vencida) puede pasar a estado 1 (enviada)
// estado 5 (enviada a operador) puede pasar a estado 2 (recibida) o estado 5 (enviada a operador)
// estado 6 (parcialmente operada)
// eliminar solo en estado 1 (enviada)

const GroupActionsButtons = ({
  info,
  columns,
  showModalSummary,
  data,
  status,
  isOwner,
  groupId,
  isReceived,
  isFinalUser,
}: {
  info: any;
  status: number;
  columns: any;
  showModalSummary: (
    value: any,
    type: string,
    data: any,
    info: any,
    partiallyOperated?: boolean
  ) => void;
  data: any;
  isOwner?: boolean;
  groupId: number;
  isReceived: boolean;
  isFinalUser: boolean;
}) => {
  const [t] = useTranslation();
  const [showContent, setShowContent] = useState(false);

  const handlerShowContent = () => {
    setShowContent((prevState) => !prevState);
  };

  return (
    <div className="actions-buttons">
      {info.position === "Close" && (
        <div>
          <EMatButton className="text-secondary" onClick={handlerShowContent} type="text">
            <Row align="middle">
              {showContent ? (
                <UpCircleTwoTone
                  twoToneColor="#1cc3a7"
                  style={{ fontSize: "28px", marginRight: "6px" }}
                />
              ) : (
                <DownCircleTwoTone
                  twoToneColor="#1cc3a7"
                  style={{ fontSize: "28px", marginRight: "6px" }}
                />
              )}
              {t(`issued_orders:button.viewMore`)}
            </Row>
          </EMatButton>
          {showContent && (
            <EMatTable
              columns={columns}
              dataSource={info.contrapositions}
              rowKey="order_id"
              pagination={false}
              scroll={{ y: 200 }}
            ></EMatTable>
          )}
        </div>
      )}
      {status === 1 && isOwner && (
        <EMatButton
          id="delete"
          className="text-secondary danger button-margins"
          type="primary"
          onClick={() => showModalSummary(groupId, "delete", data, info)}
        >
          {t(`received_orders:buttons.delete-group`)}
        </EMatButton>
      )}
      {status === 4 && isOwner && !isReceived && (
        <div>
          {" "}
          <EMatButton
            id="delete"
            className="text-secondary danger button-margins"
            type="primary"
            onClick={() => showModalSummary(groupId, "delete", data, info)}
          >
            {t(`received_orders:buttons.delete-group`)}
          </EMatButton>
          <EMatButton
            id="edit"
            className="text-secondary"
            type="ghost"
            onClick={() => showModalSummary(groupId, "renew", data, info)}
          >
            {t(`issued_orders:button.renew`)}
          </EMatButton>
        </div>
      )}
      {/* PASA A ESTADO 2 */}
      {(status === 1 || status === 5) && isFinalUser && isReceived && (
        <EMatButton
          className="text-secondary"
          type="primary"
          onClick={() => showModalSummary(groupId, "mark-received", data, info)}
        >
          {t(`received_orders:buttons.mark-received`)}
        </EMatButton>
      )}
      {/* PASA A ESTADO 5 */}
      {(status === 1 || status === 5) && !isFinalUser && isReceived && (
        <EMatButton
          className="text-secondary"
          type="primary"
          onClick={() => showModalSummary(groupId, "sent-to-operator", data, info)}
        >
          {t(`received_orders:buttons.send-to-operator`)}
        </EMatButton>
      )}
      {/* PASA A ESTADO 3 (estado final) O AL ESTADO 6 (parcialmente operada) */}
      {status === 2 && isFinalUser && isReceived && (
        <>
          <p className="text-acctions"> {t(`received_orders:buttons.operate-title`)}</p>

          <EMatButton
            className="text-secondary button-margins"
            type="primary"
            onClick={() => showModalSummary(groupId, "mark-operated", data, info, false)}
          >
            {t(`received_orders:buttons.operated`)}
          </EMatButton>
          <EMatButton
            className="text-secondary"
            type="primary"
            onClick={() => showModalSummary(groupId, "mark-operated", data, info, true)}
          >
            {t(`received_orders:buttons.parcialy-operated`)}
          </EMatButton>
        </>
      )}
    </div>
  );
};

export default GroupActionsButtons;
