import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";
import { clearAllStores } from "../actions/common";
import { User } from "src/types/auth";

type UsersStateType = {
  loading: boolean;
  users: User[] | null;
  createdUser: null;
  userData: User | null;
  error: null;
  passwordChanged: boolean;
};

const initialState: UsersStateType = {
  loading: false,
  users: null,
  userData: null,
  createdUser: null,
  error: null,
  passwordChanged: false
};

const users = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    getUsersStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, users: null, error: null }),
    getUsersSuccess: (state, { payload }: PayloadAction<any[]>) =>
      updateObject(state, { loading: false, users: payload }),
    getUsersFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    createUserStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    createUserSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, createdUser: payload }),
    createUserFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    getUserStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    getUserSuccess: (state, { payload }: PayloadAction<User>) =>
      updateObject(state, { loading: false, userData: payload }),
    getUserFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    updateUserStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    updateUserSuccess: (state, { payload }: PayloadAction<User>) =>
      updateObject(state, { loading: false, userData: payload }),
    updateUserFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),

    updatePasswordStart: (state, { payload }: PayloadAction) =>
      updateObject(state, { loading: true, error: null }),
    updatePasswordSuccess: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, passwordChanged: true}),
    updatePasswordFail: (state, { payload }: PayloadAction<any>) =>
      updateObject(state, { loading: false, error: payload }),
  },
  extraReducers: {
    [clearAllStores.type]: (state) => updateObject(state, initialState),
  },
});

export const {
  getUsersStart,
  getUsersSuccess,
  getUsersFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
  getUserStart,
  getUserSuccess,
  getUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFail,
} = users.actions;

export default users;
