import "./Alert.scss";
import { EMatLogo } from "Components";

const Alert = () => {
  return (
    <div className="alert-container">
      {/* {loading ? <Spin size="default" /> : <></>} */}
      <div className="temporal-info">
        <div className="temporal-info__title">
          <h1>BIENVENIDO a</h1>
          <EMatLogo />
        </div>

        <h3 className="">
          Lleva el control de las operaciones en el mercado de futuros y opciones agrícolas mediante
          una gestión inteligente y el seguimiento en tiempo real.
        </h3>
      </div>
      {/* <div className="alert-count">
        <div id="title-container">
          <ReactSVG src={clockIco} />
          <h3 id="title">{t("dashboard:alert.title")}</h3>
        </div>
        <div id="alert-count">
          + 5 <ArrowRightOutlined />
        </div>
      </div>
      <div className="alert-info">
        <div>
          <h3>OPERACIÓN</h3>
          <h3>INSTRUMENTO</h3>
        </div>
        <div>
          <p id="operation">Compra Futuro</p>
          <p id="instrument">Soja Ros May 21</p>
        </div>
      </div>
      <EMatButton type="primary" onClick={handleClickTrade} id="go-to-trade">
        {t("dashboard:alert:trade")}
      </EMatButton> */}
    </div>
  );
};

export default Alert;
