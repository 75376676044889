import { AxiosResponse } from "axios";
import axios from "../api/axios-instance";
import { CommissionsResponse } from "src/types/commissions";
import API_ROUTES from "src/constants/apiRoutes";
import { ClientsResponse } from "src/types/monitor";
import { GetCommissionsFilters } from "src/store/actions";

export const commissionService = {
  getCommissions: (filters: GetCommissionsFilters): Promise<AxiosResponse<CommissionsResponse>> => {
    const { from, to } = filters.date;

    return axios.get(`${API_ROUTES.COMMISSIONS.GET_BY_CLIENT}?userId=${filters.clientId}&dateFrom=${from}&dateTo=${to}`);
  },
  getMasterClients: (): Promise<AxiosResponse<ClientsResponse>> => {
    return axios.get(`${API_ROUTES.COMMISSIONS.GET_CLIENTS}`);
  },
  getCsv:  (filters: GetCommissionsFilters): Promise<AxiosResponse<CommissionsResponse>> => {
    const { from, to } = filters.date;
    return axios.get(`${API_ROUTES.COMMISSIONS.GET_CSV_BY_CLIENT}?userId=${filters.clientId}&dateFrom=${from}&dateTo=${to}`);
  },
};
