import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { authService } from "Services";
import API_ROUTES from "src/constants/apiRoutes";
import getDeviceId from "src/helpers/getDeviceId";
import { refreshTokenSuccess } from "src/store/slices/auth";
import { useAppDispatch } from "./reduxHooks";

const useHttpErrorHandler = (httpClient: AxiosInstance, onClearSession: Function) => {
  const [httpError, setHttpError] = useState<any>(null);

  let refreshTokenPromise: Promise<any>;
  localStorage.setItem("refreshing", "false");

  const dispatch = useAppDispatch();

  //TODO - buscar tipo de request de axios
  const reqInterceptor = httpClient.interceptors.request.use((req: any) => {
    if (req.headers.Authorization === undefined)
      req.headers.Authorization = `${localStorage.getItem("tokenType")} ${localStorage.getItem(
        "token"
      )}`;
    setHttpError(null);
    return req;
  });

  const resInterceptor = httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        error.response?.config.url !== API_ROUTES.AUTH.REFRESH_TOKEN
      ) {
        try {
          const response = await callRefreshToken();
          httpClient.defaults.headers.common.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;
          originalRequest.headers.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;
          return httpClient(originalRequest);
        } catch (error: any) {
          onClearSession();
          setHttpError(error.response ? error.response : error);
        }
      } else {
        //show error
        setHttpError(error.response ? error.response : error);
      }
      // If the error is due to other reasons, we just throw it back to axios
      throw error;
    }
  );

  const callRefreshToken = () => {
    if (localStorage.getItem("refreshing") === "false") {
      localStorage.setItem("refreshing", "true");

      const device_id = getDeviceId();

      refreshTokenPromise = new Promise(async (resolve, reject) => {
        await authService
          .refreshToken(localStorage.getItem("refreshToken"), device_id)
          .then(function (response) {
            const { data } = response;
            const payload = {
              token: data.access_token,
              tokenType: data.token_type,
              refreshToken: data.refresh_token,
            };
            dispatch(refreshTokenSuccess(payload));
            localStorage.setItem("refreshing", "false");
            resolve(response);
          })
          .catch(function (error) {
            localStorage.setItem("refreshing", "false");
            reject(error);
          });
      });
    }
    return refreshTokenPromise;
  };

  useEffect(() => {
    return () => {
      httpClient.interceptors.request.eject(reqInterceptor);
      httpClient.interceptors.response.eject(resInterceptor);
    };
  }, [reqInterceptor, resInterceptor]);

  const httpErrorClosedHandler = () => {
    setHttpError(null);
  };

  return [httpError, httpErrorClosedHandler];
};

export default useHttpErrorHandler;
