import { takeLatest } from "redux-saga/effects";

import * as actions from "../../actions";
import {
  getNotifications,
  getNotificationsCount,
  markAsChecked,
  getScreenNotifications,
} from "./notifications";

export function* watchNotificationsSaga() {
  yield takeLatest(actions.getNotificationsCount.type, getNotificationsCount);
  yield takeLatest(actions.getNotifications.type, getNotifications);
  yield takeLatest(actions.getScreenNotifications.type, getScreenNotifications);
  yield takeLatest(actions.markAsChecked.type, markAsChecked);
}
