import { AxiosResponse } from "axios";
import API_ROUTES from "src/constants/apiRoutes";
import { ClientsResponse, ClientTons } from "src/types/monitor";
import axios from "../api/axios-instance";

export const monitorService = {
  getClients: (): Promise<AxiosResponse<ClientsResponse>> =>
    axios.get(API_ROUTES.MONITOR.GET_CLIENTS),
  getClientTons: (userId: string): Promise<AxiosResponse<ClientTons>> =>
    axios.get(`${API_ROUTES.MONITOR.GET_CLIENT_TONS}?userId=${userId}`),
  getResultRequest: (userId: string, instrument: string): Promise<AxiosResponse<any>> =>
    axios.get(`${API_ROUTES.MONITOR.GET_RESULT}?userId=${userId}&instrument=${instrument}`),
};
