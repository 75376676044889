import { Navigate } from "react-router-dom";
import { hasPermissions } from "src/common/utils";
import { ROUTE_PATHS } from "src/constants/routePaths";

const privateRoute = (props) => {
  const { component, isAuth, userPermissions, permissions } = props;

  if (!isAuth) {
    return <Navigate to={ROUTE_PATHS.LOGIN} />;
  }

  return hasPermissions(userPermissions, permissions) ? (
    component
  ) : (
    <Navigate to={ROUTE_PATHS.DASHBOARD} />
  ); //TODO else show 403 page
};

export default privateRoute;
