import { OPERATION_POSITION } from "src/constants/operation";
import OpenSummary from "./OpenSummary";
import CloseSummary from "./CloseSummary";
import { EMatModal } from "Components";

const EMatSummaryModal = ({
  order,
  onCancel,
  getContainer,
  onClick,
  position,
  labelTitle,
  buttonAccept,
  buttonCancel,
  orderId,
  contrapositions,
  columns,
  info,
  loading,
  partiallyOperated,
}: {
  order: any;
  onCancel: () => void;
  getContainer?: string | HTMLElement | (() => HTMLElement) | false;
  onClick: (id: any) => void;
  position: string;
  labelTitle: string;
  buttonAccept: string;
  buttonCancel: string;
  orderId: string;
  contrapositions: any;
  columns: any;
  info: any;
  loading: boolean;
  partiallyOperated?: boolean;
}) => {
  const isOpen = position === OPERATION_POSITION.OPEN;

  return (
    <EMatModal
      closable={true}
      visible={true}
      title=""
      className={
        position
          ? position === OPERATION_POSITION.CLOSE
            ? "buy-sell-modal background close"
            : "buy-sell-modal background"
          : "buy-sell-modal"
      }
      onCancel={onCancel}
      footer={() => <div></div>}
      getContainer={getContainer}
    >
      <div className={isOpen ? "summary-container" : "summary-container close"}>
        {isOpen ? (
          <OpenSummary
            operation={order}
            onCancel={onCancel}
            onClick={onClick}
            labelTitle={labelTitle}
            buttonAccept={buttonAccept}
            buttonCancel={buttonCancel}
            orderId={orderId}
            loading={loading}
            partiallyOperated={partiallyOperated}
          />
        ) : (
          <CloseSummary
            operation={order}
            onCancel={onCancel}
            onClick={onClick}
            labelTitle={labelTitle}
            buttonAccept={buttonAccept}
            buttonCancel={buttonCancel}
            orderId={orderId}
            contrapositions={contrapositions}
            columns={columns}
            info={info}
            loading={loading}
            partiallyOperated={partiallyOperated}
          />
        )}
      </div>
    </EMatModal>
  );
};

export default EMatSummaryModal;
