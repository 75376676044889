import ActionsButtonsIssued from "src/containers/IssuedOrders/ActionsButtons";
import { columns } from "src/containers/IssuedOrders/utils";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { OperationType, StrikeInstrumentType, OptionType } from "src/types/operation";

import * as actions from "../../../store/actions";
import EMatSummaryModal from "src/components/EMatSummaryModal/EMatSummaryModal";
import EditBuyOrSellModal from "src/components/EditBuyOrSellModal/EditBuyOrSellModal";
import CallPutModal from "src/components/CallPutModal/CallPutModal";
import { STATE_OPTIONS, OPTION_TYPES } from "src/constants/operation";
import { clearGetIssuedOrderById } from "src/store/slices/operation";
import { Order } from "src/types/order";
import { useTranslation } from "react-i18next";

const IssuedOrdersActions = (props: any) => {
  const { ordersData, order, status, isOwner } = props;

  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const loadingDelete = useAppSelector((state) => state.operation.loadingOrders);
  const [filter, setFilter] = useState<string>("");
  const [showModalUpdate, setShowModalUpdate] = useState<boolean>(false);
  const isSubmitting = useAppSelector((state) => state.operation.loadingOperation);
  const operationSubmitted = useAppSelector((state) => state.operation.operationSubmitted);
  const contraPositions = useAppSelector((state) => state.operation.contraPositions);
  const loadingContraPositions = useAppSelector((state) => state.operation.loadingContraPositions);
  const [defaultTab, setDefaultTab] = useState<any>();
  const [typeButton, setTypeButton] = useState<string>();
  const [position, setPosition] = useState("");
  const [orderId, setOrderId] = useState("");
  const [dataByIdSummary, setDataByIdSummary] = useState();
  const [info, setInfo] = useState();
  const [contrapositions, setContrapositions] = useState();
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [titleSummary, setTitleSummary] = useState("");
  const [buttonAcceptSummary, setButtonAcceptSummary] = useState("");
  const [buttonCancelSummary, setButtonCancelSummary] = useState("");
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [instrumentStrike] = useState<StrikeInstrumentType | null>(null);
  const [dataToEdit, setDataToEdit] = useState<Order | null>(null);

  const handleShowModalSummary = (
    id: string,
    type: string,
    data: any,
    info: any,
    partiallyOperated?: boolean
  ) => {
    setTypeButton(type);
    setDataByIdSummary(data);
    setPosition(data.position);
    setOrderId(data.id);
    setContrapositions(data.contrapositions);
    setInfo(info);
    if (type === "delete") {
      setTitleSummary(t(`issued_orders:summary-delete.open-title`));
      setButtonAcceptSummary(t(`issued_orders:summary-delete.confirm`));
      setButtonCancelSummary(t(`issued_orders:summary-delete.cancel`));
      setLoadingButtons(loadingDelete);
    }
    setShowModalSummary(true);
  };

  const handlerShowModalEdit = (id: string, type: string, data: any, info: any) => {
    setDataToEdit(data);
    setShowModalUpdate(true);
    setDefaultTab(info.operation);
  };

  const handleCancel = () => {
    setShowModalSummary(false);
    setShowModalUpdate(false);
    setDefaultTab(null);
    dispatch(clearGetIssuedOrderById());
  };

  const selectButton = (id: string) => {
    dispatch(actions.deleteIssuedOrder({ id, filter }));
    setShowModalSummary(false);
  };

  const handleBuyInstrument = (values: any) => {
    const body = values;
    body.id = dataToEdit?.id;
    body.optionType = dataToEdit?.option_type;
    body.instrument = dataToEdit?.instrument;
    body.orderState = STATE_OPTIONS.SENT;
    body.quantity = dataToEdit?.quantity;
    dispatch(actions.submitOperation(body));
    setShowModalUpdate(false);
  };

  const handleSellInstrument = (values: any) => {
    const body = values;
    body.id = dataToEdit?.id;
    body.optionType = dataToEdit?.option_type;
    body.instrument = dataToEdit?.instrument;
    body.orderState = STATE_OPTIONS.SENT;
    body.quantity = dataToEdit?.quantity;
    dispatch(actions.submitOperation(body));
    setShowModalUpdate(false);
  };

  const getContraPosition = (operation: string) => {
    if (dataToEdit) {
      dispatch(
        actions.getContraPositions({
          operation: operation,
          symbol: encodeURIComponent(dataToEdit?.instrument),
        })
      );
    }
  };

  const getContraPositionOptions = (operation: string) => {
    if (instrumentStrike) {
      dispatch(
        actions.getContraPositions({
          operation: operation,
          symbol: encodeURIComponent(instrumentStrike._id),
        })
      );
    }
  };

  const handleConfirmOperationOptions = (operationInfo: OperationType) => {
    if (dataToEdit) {
      const data = {
        id: dataToEdit.id,
        orderState: dataToEdit.order_state,
        agreementType: operationInfo.agreementType,
        instrument: operationInfo.instrument,
        operation: operationInfo.operation,
        position: operationInfo.position,
        bonus: operationInfo.bonus,
        priceFrom: operationInfo.priceFrom,
        priceTo: operationInfo.priceTo,
        quantity: dataToEdit.quantity,
        strike: operationInfo.strike,
        toMarket: operationInfo.toMarket,
        optionType: OPTION_TYPES.OPTION as OptionType,
      };
      dispatch(actions.submitOperation(data));
    }
  };

  const handleFilter = (value: any) => {
    dispatch(actions.getIssuedOrders(value));
    setFilter(value);
  };

  useEffect(() => {
    if (operationSubmitted) {
      dispatch(clearGetIssuedOrderById());
      handleFilter(filter);
    }
  }, [operationSubmitted]);

  return (
    <>
      {showModalUpdate &&
        dataToEdit &&
        (dataToEdit?.option_type === "Future" || dataToEdit?.option_type === "Spread") && (
          <EditBuyOrSellModal
            onCancel={handleCancel}
            onBuy={handleBuyInstrument}
            onSell={handleSellInstrument}
            getContainer={document.getElementById("issued-orders-container") || false}
            issuedOrder={dataToEdit}
            loading={isSubmitting}
            isUpdate={showModalUpdate}
            contraPositions={contraPositions}
            onGetContraPosition={getContraPosition}
            loadingContrapositions={loadingContraPositions}
            defaultTab={defaultTab}
            optionType={dataToEdit?.option_type}
          />
        )}
      {showModalUpdate && dataToEdit && dataToEdit?.option_type === "Option" && (
        <CallPutModal
          instrumentStrike={{
            agreementType: dataToEdit.agreement_type,
            bonus: dataToEdit.bonus,
            contrapositions: dataToEdit.contrapositions,
            id: dataToEdit.id,
            idUserMaster: dataToEdit.id_user_master,
            instrument: dataToEdit.instrument,
            operation: dataToEdit.operation,
            optionType: dataToEdit.option_type,
            orderState: dataToEdit.order_state,
            position: dataToEdit.position,
            priceFrom: dataToEdit.price_from,
            priceTo: dataToEdit.price_to,
            quantity: dataToEdit.quantity,
            strike: dataToEdit.strike,
            toMarket: dataToEdit.to_market,
            userId: dataToEdit.user_id,
          }}
          getContainer={document.getElementById("issued-orders-container") || false}
          onCancel={handleCancel}
          onConfirm={handleConfirmOperationOptions}
          loading={isSubmitting}
          loadingContrapositions={loadingContraPositions}
          contraPositions={contraPositions}
          onGetContraPosition={getContraPositionOptions}
        />
      )}

      {showModalSummary && (
        <EMatSummaryModal
          order={info}
          onCancel={handleCancel}
          getContainer={document.getElementById("issued-orders-container") || false}
          onClick={selectButton}
          position={position}
          labelTitle={titleSummary}
          buttonAccept={buttonAcceptSummary}
          buttonCancel={buttonCancelSummary}
          orderId={orderId}
          contrapositions={contrapositions}
          columns={columns}
          info={dataByIdSummary}
          loading={loadingButtons}
        />
      )}
      <ActionsButtonsIssued
        info={order}
        columns={columns}
        showModalSummary={handleShowModalSummary}
        showModalEdit={handlerShowModalEdit}
        data={ordersData}
        status={status}
        isOwner={isOwner}
      />
    </>
  );
};

export default IssuedOrdersActions;
