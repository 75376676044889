import { takeLatest } from "redux-saga/effects";

import * as actions from "../../actions/commissions";
import { getCommissionsCsvSaga, getCommissionsSaga, getMasterClientsSaga } from "./commissions";

export function* watchCommisionsSaga() {
  yield takeLatest(actions.getCommissions.type, getCommissionsSaga);
  yield takeLatest(actions.getMasterClients.type, getMasterClientsSaga);
  yield takeLatest(actions.getCommissionsCsv.type, getCommissionsCsvSaga);
}
