import { BellOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useAppSelector } from "src/hooks/reduxHooks";
import { Notification } from "src/types/notifications";
import { useNavigate } from "react-router-dom";
import "./NotificationsBell.scss";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useState } from "react";

const NotificationsBell = () => {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.notifications.loading);
  const notifications = useAppSelector((state) => state.notifications.notifications || []);
  const receivedOrdersExists = useAppSelector((state) => state.operation.count.received || 0);
  const notificationsExists = notifications.length > 0 || receivedOrdersExists > 0;
  const [visible, setVisible] = useState<boolean>(false);
  const items = (
    <Menu className="notifications-box">
      {notifications.length > 0 &&
        notifications.slice(0, 5).map((n: Notification) => (
          <div
            key={n.id}
            className={`notification ${n.checked ? "readed" : "unreaded"}`}
            onClick={() => {
              navigate(ROUTE_PATHS.NOTIFICATIONS);
              setVisible(false);
            }}
          >
            <p className="title">{n.title}</p>
            <p className="message">{n.body}</p>
          </div>
        ))}
      {receivedOrdersExists > 0 && (
        <div
        className="notification unreaded"
          onClick={() => {
            navigate(ROUTE_PATHS.RECEIVED_ORDERS);
            setVisible(false);
          }}
        >
          {" "}
          <p className="title">Hay nuevas órdenes recibidas</p>
        </div>
      )}
    </Menu>
  );

  return (
    <div className="notifications-bell">
      {notificationsExists && !loading && (
        <ExclamationCircleFilled
          style={{ position: "absolute", color: "red", top: -2, right: -3 }}
        />
      )}
      <Dropdown
        visible={visible}
        disabled={!notificationsExists}
        overlay={items}
        trigger={["click"]}
        onVisibleChange={(visible) => setVisible(visible)}
      >
        <BellOutlined
          style={{ fontSize: "24px" }}
          onClick={() => {
            if (!notificationsExists) {
              navigate(ROUTE_PATHS.NOTIFICATIONS);
              setVisible(false);
            }
          }}
        />
      </Dropdown>
    </div>
  );
};

export default NotificationsBell;
