import en_common from "../translations/en/common.json";
import en_auth from "../translations/en/auth.json";
import en_dashboard from "../translations/en/dashboard.json";
import en_futures from "../translations/en/futures.json";
import en_operation from "../translations/en/operation.json";
import en_issued_orders from "../translations/en/issued_orders.json";
import en_received_orders from "../translations/en/received_orders.json";
import en_accounts from "../translations/en/accounts.json";
import en_alerts from "../translations/en/alerts.json";
import en_monitor from "../translations/en/monitor.json";
import en_notifications from "../translations/en/notifications.json";
import en_commissions from "../translations/en/commissions.json";
import en_users from "../translations/en/users.json";

import es_common from "../translations/es/common.json";
import es_auth from "../translations/es/auth.json";
import es_dashboard from "../translations/es/dashboard.json";
import es_futures from "../translations/es/futures.json";
import es_operation from "../translations/es/operation.json";
import es_issued_orders from "../translations/es/issued_orders.json";
import es_received_orders from "../translations/es/received_orders.json";
import es_accounts from "../translations/es/accounts.json";
import es_alerts from "../translations/es/alerts.json";
import es_notifications from "../translations/es/notifications.json";
import es_monitor from "../translations/es/monitor.json";
import es_commissions from "../translations/es/commissions.json";
import es_users from "../translations/es/users.json";

const resources = {
  en: {
    common: en_common,
    auth: en_auth,
    dashboard: en_dashboard,
    futures: en_futures,
    operation: en_operation,
    issued_orders: en_issued_orders,
    received_orders: en_received_orders,
    accounts: en_accounts,
    alerts: en_alerts,
    notifications: en_notifications,
    monitor: en_monitor,
    commissions: en_commissions,
    users: en_users,
  },
  es: {
    common: es_common,
    auth: es_auth,
    dashboard: es_dashboard,
    futures: es_futures,
    operation: es_operation,
    issued_orders: es_issued_orders,
    received_orders: es_received_orders,
    accounts: es_accounts,
    alerts: es_alerts,
    notifications: es_notifications,
    monitor: es_monitor,
    commissions: es_commissions,
    users: es_users,
  },
};

export default resources;
