import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import { authService } from "Services";
import * as sliceActions from "src/store/slices/auth";
import { clearAllStores } from "src/store/actions/common";
import { setIsLoading } from "src/store/slices/spinner";

export function* signInSaga(action: ReturnType<typeof actions.signIn>) {
  yield put(sliceActions.signInStart());
  yield put(setIsLoading(true));

  // Get Firebase Token
  const token: AxiosResponse<any> = yield authService.getFirebaseToken();
  yield put(sliceActions.getFirebaseSuccess({ token: token.toString() }));
  action.payload.firebase_token = token.toString();
  try {
    const { data }: AxiosResponse<any> = yield authService.signIn(action.payload);
    const authPayload = {
      token: data.token.access_token,
      tokenType: data.token.token_type,
      refreshToken: data.token.refresh_token,
      userPermissions: data.data.role,
      userData: { ...data.data },
    };

    localStorage.setItem("token", authPayload.token);
    localStorage.setItem("tokenType", authPayload.tokenType);
    localStorage.setItem("refreshToken", authPayload.refreshToken);
    localStorage.setItem("permissions", authPayload.userPermissions);
    localStorage.setItem("userId", data.data.id);
    yield put(sliceActions.signInSuccess(authPayload));
    yield put(setIsLoading(false));
    yield put(actions.getNotifications(false));
    yield put(actions.getUserById({ id: parseInt(data.data.id) }));
  } catch (err: any) {
    switch (err.response?.status) {
      case 400:
      case 401:
        yield put(sliceActions.signInError("auth:incorrectCredentials"));
        break;
      default:
        console.error(err.message);
        yield put(sliceActions.signInError("common:errors.somethingWentWrong"));
        break;
    }
    yield put(setIsLoading(false));
  }
}

export function* recoverPasswordSaga(action: ReturnType<typeof actions.recoverPassword>) {
  yield put(sliceActions.recoverPasswordStart());
  try {
    const { data }: AxiosResponse<any> = yield authService.recoverPassword(action.payload);

    yield put(sliceActions.recoverPasswordSuccess(data));
  } catch (err: any) {
    switch (err.response?.status) {
      case 400:
      case 401:
        yield put(sliceActions.recoverPasswordError("auth:nonExistingUser"));
        break;
      default:
        console.error(err.message);
        yield put(sliceActions.recoverPasswordError("common:errors.somethingWentWrong"));
        break;
    }
  }
}

export function* validateTokenSaga(action: ReturnType<typeof actions.validateToken>) {
  yield put(sliceActions.validateTokenStart());
  try {
    const { data }: AxiosResponse<any> = yield authService.validateToken(action.payload);

    yield put(sliceActions.validateTokenSuccess(data));
  } catch (err: any) {
    switch (err.response?.status) {
      case 401:
        yield put(sliceActions.validateTokenError("auth:changePassword.expiredToken"));
        break;
      default:
        console.error(err.message);
        yield put(sliceActions.validateTokenError("common:errors.somethingWentWrong"));
        break;
    }
  }
}

export function* changePasswordSaga(action: ReturnType<typeof actions.changePassword>) {
  yield put(sliceActions.changePasswordStart());
  try {
    const { data }: AxiosResponse<any> = yield authService.changePassword(action.payload);

    yield put(sliceActions.changePasswordSuccess(data));
  } catch (err: any) {
    switch (err.response?.status) {
      case 401:
        yield put(sliceActions.validateTokenError("auth:changePassword.expiredToken"));
        break;
      default:
        yield put(sliceActions.changePasswordError("common:errors.somethingWentWrong"));
        break;
    }
  }
}

export function* logoutSaga(action: ReturnType<typeof actions.logout>) {
  try {
    yield put(setIsLoading(true));
    if (action.payload) yield authService.logout(action.payload);
    yield localStorage.clear();
    yield put(clearAllStores());
    yield put(setIsLoading(false));
  } catch (err: any) {
    yield localStorage.clear();
    yield put(clearAllStores());
    yield put(setIsLoading(false));
  }
}

export function* authCheckStateSaga(action: ReturnType<typeof actions.logout>) {
  try {
    yield put(setIsLoading(true));
    yield put(sliceActions.authCheckStart());
    const token: string = yield localStorage.getItem("token");
    const tokenType: string = yield localStorage.getItem("tokenType");
    const refreshToken: string = yield localStorage.getItem("refreshToken");
    const userPermissions: string = yield localStorage.getItem("permissions");
    const id: string = yield localStorage.getItem("userId");

    if (!token) {
      localStorage.clear();
    } else {
      yield put(
        sliceActions.authCheckSuccess({
          token,
          tokenType,
          refreshToken,
          userPermissions,
          userData: { id: parseInt(id) },
        })
      );
    }
    yield put(setIsLoading(false));
  } catch (error) {
    console.error(error);
  }
}

export function* getFirebaseTokenSaga() {
  yield put(sliceActions.getFirebaseTokenStart());
  yield put(setIsLoading(true));
  try {
    const token: AxiosResponse<any> = yield authService.getFirebaseToken();
    yield put(sliceActions.getFirebaseSuccess({ token: token.toString() }));
    yield put(setIsLoading(false));
  } catch (error) {
    console.error(error);
    yield put(sliceActions.getFirebaseError(error));
    yield put(setIsLoading(false));
  }
}
