import { Rule } from "antd/lib/form";
import { useTranslation } from "react-i18next";

const useValidationRules = () => {
  const { t } = useTranslation();

  const nameRules: Rule[] = [
    {
      required: true,
      message: t("common:errors:fieldRequiredDefault"),
    },
    {
      pattern: /^[A-Za-z]+$/,
      message: t("common:errors:lettersOnly"),
    },
    {
      max: 255,
      message: t("common:errors:maxLength", { max: 255 }),
    },
  ];

  const lastNameRules: Rule[] = [
    {
      required: true,
      message: t("common:errors:fieldRequiredDefault"),
    },
    {
      pattern: /^[A-Za-z]+$/,
      message: t("common:errors:lettersOnly"),
    },
    {
      max: 255,
      message: t("common:errors:maxLength", { max: 255 }),
    },
  ];

  const emailRules: Rule[] = [
    { required: true, message: t("common:errors:fieldRequiredDefault") },
    {
      type: "email",
      message: t("auth:invalidEmail"),
    },
    {
      max: 255,
      message: t("common:errors:maxLength", { max: 255 }),
    },
  ];

  const phoneNumberRules: Rule[] = [
    {
      required: true,
      message: t("common:errors:fieldRequiredDefault"),
    },
    {
      pattern: /^[0-9]+$/,
      message: t("common:errors:numbersOnly"),
    },
    {
      max: 255,
      message: t("common:errors:maxLength", { max: 255 }),
    },
  ];

  const companyNameRules: Rule[] = [
    {
      required: true,
      message: t("common:errors:fieldRequiredDefault"),
    },
    {
      max: 255,
      message: t("common:errors:maxLength", { max: 255 }),
    },
  ];

  const concomitentNumberRules: Rule[] = [
    {
        required: true,
        message: t("common:errors:fieldRequiredDefault"),
      },
      {
        pattern: /^[0-9]+$/,
        message: t("common:errors:numbersOnly"),
      },
      {
        max: 255,
        message: t("common:errors:maxLength", { max: 255 }),
      },
  ];

  const primaryUserRules: Rule[] = [
    {
        required: true,
        message: t("common:errors:fieldRequiredDefault"),
      },
      {
        max: 255,
        message: t("common:errors:maxLength", { max: 255 }),
      },
  ];

  const primaryPasswordRules: Rule[] = [
    {
        required: true,
        message: t("common:errors:fieldRequiredDefault"),
      },
      {
        max: 255,
        message: t("common:errors:maxLength", { max: 255 }),
      },
  ];

  return {
    nameRules,
    lastNameRules,
    emailRules,
    phoneNumberRules,
    companyNameRules,
    concomitentNumberRules,
    primaryPasswordRules,
    primaryUserRules
  };
};

export default useValidationRules;
