import { createAction } from "@reduxjs/toolkit";
import {
  OperationType,
  ContraPositionBody,
  HistoricIssuedOrdersFilters,
  OperationGroupBody,
} from "src/types/operation";

export const getFutures = createAction<string>("getFutures");
export const getOptions = createAction<string>("getOptions");
export const getSpreads = createAction<string>("getSpreads");
export const submitOperation = createAction<OperationType>("submitOperation");
export const getContraPositions = createAction<ContraPositionBody>("getContraPositions");
// ORDERS GROUPS
export const createOperationGroup = createAction<OperationGroupBody>("createOperationGroup");
export const getIssuedOrdersGroups = createAction<{page: number, from?: string, to?: string}>("getIssuedOrdersGroups");
export const getReceivedOrdersGroups = createAction<{page: number, clientId: string}>("getReceivedOrdersGroups");

//issued orders
export const getIssuedOrders = createAction<string>("getIssuedOrders");
export const getHistoricIssuedOrders =
  createAction<HistoricIssuedOrdersFilters>("getHistoricIssuedOrder");
export const deleteIssuedOrder = createAction<{ id: string; filter: string }>("deleteIssuedOrder");
export const getOrderById = createAction<string>("getOrderById");
export const setStatusSent = createAction<{ id: string; filter: string }>("setStatusSent");

//received orders
export const getClients = createAction("getClients");
export const getReceivedOrders = createAction<string>("getReceivedOrders");
export const deleteReceivedOrder = createAction<{ id: string; filter: string; isGroup?: boolean }>(
  "deleteReceivedOrder"
);
export const markReceived = createAction<{ id: string; filter: string }>("markReceived");
export const markOperated = createAction<{ id: string; filter: string }>("markOperated");
export const markPartiallyOperated = createAction<{ id: string; filter: string; quantity: number }>(
  "markPartiallyOperated"
);
export const sentToOperator = createAction<{ id: string; filter: string }>("sentToOperator");

//Clear positions csv
export const clearPositionsCsv = createAction("clearPositionsCsv");

//Own positions
export const getOwnOpenPositions = createAction<any>("getOwnOpenPositions");
export const getOwnOpenPositionsCsv = createAction("getOwnOpenPositionsCsv");
export const getOwnClosedPositions = createAction<any>("getOwnClosedPositions");
export const getOwnClosedPositionsCsv = createAction<any>("getOwnClosedPositionsCsv");

//Clients positions
export const getClientsOpenPositions = createAction<any>("getClientsOpenPosition");
export const getClientsOpenPositionsCsv = createAction<any>("getClientsOpenPositionsCsv");
export const getClientsClosedPositions = createAction<any>("getClientsClosedPositions");
export const getClientsClosedPositionsCsv = createAction<any>("getClientsClosedPositionsCsv");
export const clearClientOpenPositions = createAction("clearClientOpenPositions");
export const clearClientClosedPositions = createAction("clearClientClosedPositions");

//Clients positions and Own positions
export const getPositions = createAction<any>("getPositions");
