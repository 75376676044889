import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { updateObject } from "src/common/utils";

const spinner = createSlice({
  name: "spinner",
  initialState: { isLoading: false },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) =>
      updateObject(state, { isLoading: payload }),
  },
});

export const { setIsLoading } = spinner.actions;

export default spinner;
