import { take, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions";
import {
  getFuturesSaga,
  getOptionsSaga,
  getSpreadsSaga,
  submitOperationSaga,
  getContraPositionsSaga,
  getIssuedOrdersSaga,
  deleteIssuedOrderSaga,
  getIssuedOrderByIdSaga,
  getHistoricIssuedOrdersSaga,
  setStatusSentSaga,
  getClientsSaga,
  getReceivedOrdersSaga,
  deleteReceivedOrderSaga,
  markReceivedSaga,
  markOperatedSaga,
  markPartiallyOperatedSaga,
  sentToOperatorSaga,
  getOwnOpenPositionsSaga,
  getOwnClosedPositionsSaga,
  getClientsOpenPositionsSaga,
  clearClientOpenPositionsSaga,
  clearClientClosedPositionsSaga,
  getClientsClosedPositionsSaga,
  getPositionsSaga,
  getCountSaga,
  getOwnClosedPositionsCsvSaga,
  getClientsOpenPositionsCsvSaga,
  getOwnOpenPositionsCsvSaga,
  getClientsClosedPositionsCsvSaga,
  clearPositionsCsvSaga,
} from "./operation";
import {
  createOperationGroupSaga,
  getIssuedOrdersGroupsSaga,
  getReceivedOrdersGroupsSaga,
} from "./operationGroups";

export function* watchOperationSaga() {
  yield takeLatest(actions.getFutures.type, getFuturesSaga);
  yield takeLatest(actions.getOptions.type, getOptionsSaga);
  yield takeLatest(actions.getSpreads.type, getSpreadsSaga);
  yield takeLatest(actions.submitOperation.type, submitOperationSaga);
  /** ORDER GROUPS */
  yield takeLatest(actions.createOperationGroup.type, createOperationGroupSaga);
  yield takeLatest(actions.getIssuedOrdersGroups.type, getIssuedOrdersGroupsSaga);
  yield takeLatest(actions.getReceivedOrdersGroups.type, getReceivedOrdersGroupsSaga);
  /** ------------ */
  yield takeLatest(actions.getContraPositions.type, getContraPositionsSaga);
  yield takeLatest(actions.getIssuedOrders.type, getIssuedOrdersSaga);
  yield takeLatest(actions.deleteIssuedOrder.type, deleteIssuedOrderSaga);
  yield takeLatest(actions.getOrderById.type, getIssuedOrderByIdSaga);
  yield takeLatest(actions.getHistoricIssuedOrders.type, getHistoricIssuedOrdersSaga);
  yield takeLatest(actions.setStatusSent.type, setStatusSentSaga);
  yield takeLatest(actions.getClients.type, getClientsSaga);
  yield takeLatest(actions.getReceivedOrders.type, getReceivedOrdersSaga);
  yield takeLatest(actions.deleteReceivedOrder.type, deleteReceivedOrderSaga);
  yield takeLatest(actions.markOperated.type, markOperatedSaga);
  yield takeLatest(actions.markPartiallyOperated.type, markPartiallyOperatedSaga);
  yield takeLatest(actions.markReceived.type, markReceivedSaga);
  yield takeLatest(actions.sentToOperator.type, sentToOperatorSaga);
  yield takeLatest(actions.getOwnOpenPositions.type, getOwnOpenPositionsSaga);
  yield takeLatest(actions.getOwnOpenPositionsCsv.type, getOwnOpenPositionsCsvSaga);
  yield takeLatest(actions.getOwnClosedPositions.type, getOwnClosedPositionsSaga);
  yield takeLatest(actions.getOwnClosedPositionsCsv.type, getOwnClosedPositionsCsvSaga);
  yield takeLatest(actions.getClientsOpenPositions.type, getClientsOpenPositionsSaga);
  yield takeLatest(actions.getClientsOpenPositionsCsv.type, getClientsOpenPositionsCsvSaga);
  yield takeLatest(actions.clearPositionsCsv.type, clearPositionsCsvSaga);
  yield takeLatest(actions.clearClientOpenPositions.type, clearClientOpenPositionsSaga);
  yield takeLatest(actions.clearClientClosedPositions.type, clearClientClosedPositionsSaga);
  yield takeLatest(actions.getClientsClosedPositions.type, getClientsClosedPositionsSaga);
  yield takeLatest(actions.getClientsClosedPositionsCsv.type, getClientsClosedPositionsCsvSaga);
  yield takeLatest(actions.getPositions.type, getPositionsSaga);
  yield takeLatest(actions.getCount.type, getCountSaga);
}
