import { Button } from "antd";
import "./Pagination.scss"

type PaginationType = {
  count: number;
  next_page: number | null;
  prev_page: number | null;
  page_size: number;
};

interface PaginationProps {
  pagination: PaginationType;
  setPrevPage: () => void;
  setNextPage: () => void;
}

const Pagination = (props: PaginationProps) => {
  const { pagination, setPrevPage, setNextPage } = props;
  const { count, prev_page, next_page, page_size } = pagination;
  return (
    <div className="pagination">
      <Button disabled={prev_page === null} onClick={setPrevPage}>
        {"<"}
      </Button>
      <h2 className="page-number">
        {next_page === null ? 1 : next_page - 1}/{Math.ceil(count / page_size)}
      </h2>
      <Button disabled={next_page === null} onClick={setNextPage}>
        {">"}
      </Button>
    </div>
  );
};

export default Pagination;
