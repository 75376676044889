import { EMatButton, EMatInput, EMatModal, EmatOrderGroupModal } from "Components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getFutures } from "src/store/actions/operation";
import { FutureType, FuturesType } from "src/types/operation";
import "./RollOverModal.scss";

const RollOverModal = ({
  position,
  onCancel,
  loading,
  isOpen,
}: {
  position: any;
  onCancel: () => void;
  loading: boolean;
  isOpen: boolean;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const futures = useAppSelector<FuturesType | null>((state) => state.operation.futures);
  const loadingFutures = useAppSelector((state) => state.operation.loadingFutures);
  const [selectedInstrument, setSelectedInstrument] = useState("");
  const [isSumaryVisible, setIsSumaryVisible] = useState(false);
  const [ordersGroup, setOrdersGroup] = useState<any[]>([]);

  useEffect(() => {
    const grain = position.symbol_id.slice(0, 3);
    dispatch(getFutures(grain));
  }, [position]);

  const futuresOptions = futures?.data
    .map((i: FutureType) => {
      return { value: i._id, label: i.instrument_name };
    })
    .filter((i: any) => i.value !== position.instrument);

  futuresOptions?.unshift({ value: "", label: "Selecionar" });

  const closeModal = () => {
    setSelectedInstrument("");
    onCancel();
  };

  const onClick = () => {
    setOrdersGroup([
      {
        instrument: position.instrument,
        toMarket: 1,
        position: "Close",
        operation: position.side === "BUY" ? "Sell" : "Buy",
        optionType: "Future",
        orderState: 1,
      },
      {
        instrument: selectedInstrument,
        toMarket: 1,
        position: "Open",
        operation: position.side === "BUY" ? "Buy" : "Sell",
        optionType: "Future",
        orderState: 1,
      },
    ]);
    setIsSumaryVisible(true);
  };

  return (
    <EMatModal
      closable={true}
      visible={isOpen}
      title=""
      className="buy-sell-modal background close"
      onCancel={closeModal}
      footer={() => <div></div>}
    >
      <div className="summary-container">
        <h1 className="title">Roll Over</h1>

        <p className="labels">
          {t("accounts:rollOver.from")}
          {position.instrument_name}
        </p>
        <p className="labels">{t("accounts:rollOver.to")}</p>

        <EmatOrderGroupModal
          orders={ordersGroup}
          onCancel={() => setIsSumaryVisible(false)}
          visible={isSumaryVisible}
          quantity={position.order_quantity}
          isRollOver={true}
        />

        <div className="buttons-container close">
          <EMatInput
            className="filter"
            eMatType={EMAT_INPUT_TYPES.SELECT}
            options={futuresOptions}
            onSelect={(value: any) => setSelectedInstrument(value)}
            value={selectedInstrument}
            loading={loadingFutures}
          />

          <EMatButton
            type="primary"
            className="primary"
            onClick={onClick}
            loading={loading}
            disabled={selectedInstrument === ""}
          >
            {t("accounts:rollOver.buttons.createOrders")}
          </EMatButton>
          <EMatButton type="text" id="edit" className="secondary" onClick={closeModal}>
            {t("accounts:rollOver.buttons.cancel")}
          </EMatButton>
        </div>
      </div>
    </EMatModal>
  );
};

export default RollOverModal;
