import { Col, Form, Row } from "antd";
import { EMatButton, EMatFormItem, EMatInput } from "Components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  validatePrice,
  validatePriceFrom,
  validatePriceTo,
  validateQuantity,
} from "src/common/orderValidations";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { OPERATION_POSITION, OPTION_TYPES } from "src/constants/operation";
import "./BuyOrSellForm.scss";

const BuyOrSellForm = ({
  instrument,
  price,
  onCancel,
  onSubmit,
  id,
  loading,
  isBuy,
  operationToEdit,
  optionType,
  tons,
}: {
  instrument: string;
  price: number;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  id: string;
  loading: boolean;
  isBuy: boolean;
  operationToEdit: any;
  optionType: string;
  tons: number | null;
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [priceDisabled, setPriceDisabled] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);

  const handleToMarket = (e: any) => {
    if (e.target.checked) {
      setPriceDisabled(true);
      form.resetFields(["price", "priceTo", "priceFrom"]);
      form.setFieldsValue({
        price: undefined,
        priceTo: undefined,
        priceFrom: undefined,
      });
      form.setFieldsValue({ toMarket: 1 });
    } else {
      setPriceDisabled(false);
      form.setFieldsValue({ toMarket: 0 });
    }
  };

  const handleSwitch = (value: any) => {
    setSwitchChecked(value);
    form.setFieldsValue({
      position: value ? OPERATION_POSITION.CLOSE : OPERATION_POSITION.OPEN,
    });
  };

  const initialValues = operationToEdit
    ? {
        id: operationToEdit.id,
        quantity: operationToEdit.quantity,
        price: operationToEdit.price,
        priceFrom: operationToEdit.priceFrom,
        priceTo: operationToEdit.priceTo,
        toMarket: !!operationToEdit.toMarket,
        position: operationToEdit.position ? OPERATION_POSITION.CLOSE : OPERATION_POSITION.OPEN,
      }
    : {
        position: OPERATION_POSITION.OPEN,
        price: price ? price : undefined,
        toMarket: 0,
        quantity: tons,
      };

  useEffect(() => {
    if (operationToEdit) {
      if (!!operationToEdit.toMarket === true) setPriceDisabled(true);
      if (operationToEdit.position === OPERATION_POSITION.CLOSE) {
        form.setFieldsValue({ position: OPERATION_POSITION.CLOSE });
        setSwitchChecked(true);
      } else {
        form.setFieldsValue({ position: OPERATION_POSITION.OPEN });
      }
    }
  }, [operationToEdit]);

  return (
    <>
      <div className="instrument">
        <h3 id="instrument">{instrument}</h3>
      </div>
      <Form
        id={id}
        form={form}
        onFinish={onSubmit}
        onFinishFailed={(error) => console.log(error)}
        initialValues={initialValues}
      >
        <EMatFormItem
          name="quantity"
          rules={[
            {
              required: true,
              message: t("common:errors:fieldRequiredDefault"),
            },
            {
              pattern: new RegExp(/^[0-9]+$/),
              message: t("common:errors.invalidValue", {
                field: t("operation:futures.quantity"),
              }),
            },
            { validator: (rule, value) => validateQuantity(rule, value, t, form) },
          ]}
          validateTrigger={["onBlur"]}
        >
          <EMatInput
            min="0"
            step={100}
            eMatType={EMAT_INPUT_TYPES.NUMBER}
            id="quantity"
            placeholder={t("operation:futures.quantity")}
            suffix="Tn."
            disabled={tons !== null}
          />
        </EMatFormItem>
        <Row gutter={8}>
          <Col sm={12} xs={24}>
            <EMatFormItem
              name="price"
              rules={[
                {
                  required: !priceDisabled,
                  message: t("common:errors:fieldRequiredDefault"),
                },
                {
                  validator: (rule, value) =>
                    validatePrice(rule, value, t, form, priceDisabled, optionType),
                },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="price"
                placeholder={
                  optionType === OPTION_TYPES.FUTURE
                    ? t("operation:futures.price")
                    : t("operation:spreads.price")
                }
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
          <Col sm={12} xs={24} className="col-to-market">
            <EMatFormItem name="toMarket" valuePropName="checked">
              <EMatInput
                eMatType={EMAT_INPUT_TYPES.CHECKBOX}
                id="price-to-Market"
                onChange={handleToMarket}
              >
                {t("operation:common.to-market")}
              </EMatInput>
            </EMatFormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col sm={12} xs={24}>
            <EMatFormItem
              name="priceFrom"
              rules={[
                { validator: (rule, value) => validatePriceFrom(rule, value, t, form, optionType) },
              ]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                min="0"
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="price-from"
                placeholder={t("operation:common.priceFrom")}
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
          <Col sm={12} xs={24}>
            <EMatFormItem
              name="priceTo"
              rules={[{ validator: (rule, value) => validatePriceTo(rule, value, t, form) }]}
              validateTrigger={["onBlur"]}
            >
              <EMatInput
                min="0"
                step=".01"
                disabled={priceDisabled}
                eMatType={EMAT_INPUT_TYPES.NUMBER}
                id="price-from"
                placeholder={t("operation:common.priceTo")}
                suffix="US$"
              />
            </EMatFormItem>
          </Col>
        </Row>
        <EMatFormItem className="open-close-switch" name="position" valuePropName="checked">
          <span className="option">{t("operation:common.open")}</span>
          <EMatInput
            onChange={handleSwitch}
            eMatType={EMAT_INPUT_TYPES.SWITCH}
            id="open-close-switch"
            checked={switchChecked}
          />
          <span className="option">{t("operation:common.close")}</span>
        </EMatFormItem>

        <EMatFormItem className="buttons-container">
          <EMatButton type="text" id="cancel" onClick={onCancel}>
            {t("common:actions.cancel")}
          </EMatButton>
          <EMatButton type="primary" id="submit" htmlType="submit" loading={loading}>
            {t("common:actions.accept")}
          </EMatButton>
        </EMatFormItem>
      </Form>
    </>
  );
};

export default BuyOrSellForm;
