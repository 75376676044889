import { EMatTabs } from "Components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "src/api/axios-instance";
import { ROUTE_PATHS } from "src/constants/routePaths";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import "./IssuedOrders.scss";
import Current from "./Current/Current";
import History from "./History/History";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { useEffect, useState } from "react";
import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import * as actions from "../../store/actions";

const IssuedOrders = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const yesterday = moment().startOf("day").subtract(1, "day");
  const aWeekAgo = moment().startOf("day").subtract(1, "week");
  const loadingOrders = useAppSelector((state) => state.operation.loadingOrders);
  const dataOrders = useAppSelector<any>((state) => state.operation.issuedOrdersGroups || []);
  const operationSubmitted = useAppSelector((state) => state.operation.operationSubmitted);

  const [dateRange, setDateRange] = useState<{ from: Moment | null; to: Moment | null }>({
    from: null,
    to: null,
  });
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.ISSUED_ORDERS_CURRENT) {
      dispatch(actions.getIssuedOrdersGroups({ page }));
    } else {
      if (dateRange.from !== null && dateRange.to !== null) {
        const from = dateRange.from.toISOString();
        const to = dateRange.to.toISOString();
        dispatch(actions.getIssuedOrdersGroups({ page, from, to }));
      }
    }
  }, [dateRange, page]);

  const onDateChange = (value: RangePickerProps["value"]) => {
    if (value) {
      const from = value[0]?.startOf("day")!;
      const to = value[1]?.startOf("day")!;

      // Calculate one month later from the start date
      const oneMonthLater = from.clone().add(1, "month");

      // Check if the selected range exceeds one month
      if (to.isAfter(oneMonthLater)) {
        alert(t(`issued_orders:calendar.validation`));
        setDateRange({ from: null, to: null });
        return;
      }

      setDateRange({ from, to });
    } else {
      setDateRange({ from: null, to: null });
    }
  };

  const handleClickTab = (key: string) => {
    navigate(key);
    if (key === ROUTE_PATHS.ISSUED_ORDERS_CURRENT) {
      setPage(1);
      setDateRange({ from: null, to: null });
    } else {
      setPage(1);
      setDateRange({ from: aWeekAgo, to: yesterday });
    }
  };

  const tabs = [
    {
      tab: t("issued_orders:tabs.current"),
      key: ROUTE_PATHS.ISSUED_ORDERS_CURRENT,
      component: (
        <Current dataOrders={dataOrders} loadingOrders={loadingOrders} setPage={setPage} />
      ),
    },
    {
      tab: t("issued_orders:tabs.history"),
      key: ROUTE_PATHS.ISSUED_ORDERS_HISTORY,
      component: (
        <History
          dataOrders={dataOrders}
          loadingOrders={loadingOrders}
          setPage={setPage}
          dateRange={dateRange}
          onChange={onDateChange}
        />
      ),
    },
  ];

  useEffect(() => {
    if (operationSubmitted) {
      dispatch(actions.getIssuedOrdersGroups({ page }));
    }
  }, [operationSubmitted]);

  return (
    <div className="operation-content background light">
      <EMatTabs
        defaultActiveKey={!location.pathname ? tabs[0].key : location.pathname}
        size="large"
        className="tab-menu"
        centered
        moreIcon={<div></div>}
        onTabClick={handleClickTab}
        tabs={tabs}
      ></EMatTabs>
    </div>
  );
};

export default withErrorHandler(IssuedOrders, instance);
