import { columns } from "src/containers/IssuedOrders/utils";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../../store/actions";
import { useTranslation } from "react-i18next";
import { EMatGroupSummary } from "Components";
import GroupActionsButtons from "./GroupActionsButtons";

const GroupActions = (props: any) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { ordersData, clientId, isOwner, group, isReceived, isFinalUser } = props;

  const order = group.order;
  const status = group.status;
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [typeButton, setTypeButton] = useState<string>();
  const [position, setPosition] = useState("");
  const [titleSummary, setTitleSummary] = useState("");
  const [buttonAcceptSummary, setButtonAcceptSummary] = useState("");
  const [buttonCancelSummary, setButtonCancelSummary] = useState("");
  const [orderId, setOrderId] = useState("");
  const [contrapositions, setContrapositions] = useState();
  const [info, setInfo] = useState();
  const [partiallyOperated, setpartiallyOperated] = useState(false);
  const loadingOrders = useAppSelector<any>((state) => state.operation.loadingOrders);

  const handleShowModalSummary = (
    id: string,
    type: string,
    data: any,
    info: any,
    partiallyOperated?: boolean
  ) => {
    setTypeButton(type);
    setPosition(info.position);
    setOrderId(id);
    setContrapositions(info.contrapositions);
    setInfo(info);

    switch (type) {
      case "delete":
        setTitleSummary(t(`received_orders:summary-delete.open-title`));
        setButtonAcceptSummary(t(`received_orders:summary-delete.confirm`));
        setButtonCancelSummary(t(`received_orders:summary-delete.cancel`));
        break;
      case "mark-received":
        setTitleSummary(t(`received_orders:summary-received.open-title`));
        setButtonAcceptSummary(t(`received_orders:summary-received.confirm`));
        setButtonCancelSummary(t(`received_orders:summary-received.cancel`));
        break;

      case "sent-to-operator":
        setTitleSummary(t(`received_orders:summary-sent-to-operator.open-title`));
        setButtonAcceptSummary(t(`received_orders:summary-sent-to-operator.confirm`));
        setButtonCancelSummary(t(`received_orders:summary-sent-to-operator.cancel`));
        break;
      case "mark-operated":
        setTitleSummary(
          t(
            `received_orders:summary-operated.${
              !partiallyOperated ? "open-title" : "open-title-parcial"
            }`
          )
        );
        setButtonAcceptSummary(t(`received_orders:summary-operated.confirm`));
        setButtonCancelSummary(t(`received_orders:summary-operated.cancel`));
        break;
      case "renew":
        if (type === "renew") {
          setTitleSummary(t(`issued_orders:summary-renew.open-title`));
          setButtonAcceptSummary(t(`issued_orders:summary-renew.confirm`));
          setButtonCancelSummary(t(`issued_orders:summary-renew.cancel`));
        }
        break;
      default:
        break;
    }
    setpartiallyOperated(partiallyOperated || false);
    setShowModalSummary(true);
  };

  const receivedSelectButton = (id: string, quantity?: number) => {
    switch (typeButton) {
      case "delete":
        dispatch(actions.deleteReceivedOrder({ id, filter: clientId, isGroup: true }));
        break;
      case "renew":
        dispatch(actions.setStatusSent({ id, filter: clientId }));
        break;
      case "mark-received":
        dispatch(actions.markReceived({ id, filter: clientId }));
        break;
      case "sent-to-operator":
        dispatch(actions.sentToOperator({ id, filter: clientId }));
        break;
      default:
        partiallyOperated && quantity
          ? dispatch(actions.markPartiallyOperated({ id, filter: clientId, quantity }))
          : dispatch(actions.markOperated({ id, filter: clientId }));
        break;
    }
    setShowModalSummary(false);
  };

  return (
    <>
      {showModalSummary && (
        <EMatGroupSummary
          order={group.orders[0]}
          onCancel={() => setShowModalSummary(false)}
          getContainer={document.getElementById("received-orders-container") || false}
          onClick={receivedSelectButton}
          position={position}
          labelTitle={titleSummary}
          buttonAccept={buttonAcceptSummary}
          buttonCancel={buttonCancelSummary}
          orderId={orderId}
          contrapositions={contrapositions}
          columns={columns}
          info={info}
          loading={loadingOrders}
          partiallyOperated={partiallyOperated}
        />
      )}
      <GroupActionsButtons
        groupId={group.id}
        info={ordersData}
        columns={columns}
        showModalSummary={handleShowModalSummary}
        data={order}
        status={status}
        isOwner={isOwner}
        isReceived={isReceived}
        isFinalUser={isFinalUser}
      />
    </>
  );
};

export default GroupActions;
