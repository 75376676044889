import { Routes, Route } from 'react-router';
import RouteWithSubRoutes from './routesWithSubRoutes/RouteWithSubRoutes';
import { useAppSelector } from 'src/hooks/reduxHooks';

const MainRoutes = ({ routes }) => {
  const isAuthenticated = useAppSelector(state => !!state.auth.token);
  const userPermissions = useAppSelector(state => state.auth.userPermissions);
  return (
    <Routes>
      {routes.map((route, i) => {
        const privateRouteProps = { ...route, isAuth: isAuthenticated, userPermissions: userPermissions, permissions: route.permissions };
        return (
          <Route key={i} path={route.path} element={<RouteWithSubRoutes {...privateRouteProps} />} />
        )
      })}
    </Routes>
  )
}

export default MainRoutes;