import { Col, Row, Form } from "antd";
import { arrowDown } from "Assets";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { OPERATION_TYPES } from "src/constants/operation";
import { DefaultOptionType } from "antd/lib/cascader";
import { EMatTable, EMatFormItem, EMatInput, EMatButton } from "Components";
import { validatePartialQuantity } from "src/common/orderValidations";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";

const CloseSummary = ({
  operation,
  onCancel,
  onClick,
  labelTitle,
  buttonAccept,
  buttonCancel,
  orderId,
  contrapositions,
  columns,
  info,
  loading,
  partiallyOperated,
}: {
  operation: any;
  onCancel: () => void;
  onClick: (id: any, quantity?: any) => void;
  labelTitle: string;
  buttonAccept: string;
  buttonCancel: string;
  orderId: string;
  contrapositions: any;
  columns: any;
  info: any;
  loading: boolean;
  partiallyOperated?: boolean;
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const quantity = operation.find((op: any) => op.label === "CANTIDAD");

  const max = parseInt(quantity.value.split(" ")[0]);

  const initialValues = {
    quantity: null,
  };

  return (
    <div>
      <h1>{labelTitle}</h1>
      <div className="summary-values close">
        {operation?.map((item: DefaultOptionType, i: any) => {
          if (!item) {
            return null;
          }
          return (
            <Row gutter={8} key={i}>
              <Col span={12} className="label">
                {item.label}
              </Col>
              <Col span={12} className="value-summary">
                {item.value}
              </Col>
            </Row>
          );
        })}
      </div>
      <div className="close-operations">
        <h1>
          {t(
            `operation:common.summary.${info?.operation === OPERATION_TYPES.BUY ? "sell" : "buy"}`
          ) +
            " " +
            info?.instrument}
        </h1>       
        <EMatTable
          id="contra-positions"
          columns={columns}
          dataSource={contrapositions}
          rowKey={"order_id"}
          pagination={false}
          className="table-summary"
        />
        {partiallyOperated && (
          <Row>
            <h2>{t("received_orders:summary-operated.parcial-operated-title")}</h2>
            <Form
              id={"id"}
              form={form}
              onFinish={(values) => onClick(orderId, values.quantity)}
              onFinishFailed={(error) => console.log(error)}
              initialValues={initialValues}
            >
              <EMatFormItem
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t("common:errors:fieldRequiredDefault"),
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: t("common:errors.invalidValue", {
                      field: t("operation:futures.quantity"),
                    }),
                  },
                  {
                    validator: (rule, value) => validatePartialQuantity(value, t, max),
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <EMatInput
                  min="0"
                  step={100}
                  eMatType={EMAT_INPUT_TYPES.NUMBER}
                  id="quantity"
                  placeholder={t("operation:futures.quantity")}
                  suffix={`Tn. / ${quantity.value}`}
                />
              </EMatFormItem>
              <div className="buttons-container">
                <EMatButton type="primary" id="submit" htmlType="submit" loading={loading}>
                  {buttonAccept}
                </EMatButton>
                <EMatButton type="text" id="edit" onClick={onCancel}>
                  {buttonCancel}
                </EMatButton>
              </div>
            </Form>
          </Row>
        )}

        {!partiallyOperated && (
          <div className="buttons-container close">
            <EMatButton type="primary" onClick={() => onClick(orderId)} loading={loading}>
              {buttonAccept}
            </EMatButton>
            <EMatButton type="text" id="edit" onClick={onCancel}>
              {buttonCancel}
            </EMatButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CloseSummary;
