import { takeLatest } from "redux-saga/effects";

import * as actions from "../../actions/auth";
import {
  authCheckStateSaga,
  changePasswordSaga,
  logoutSaga,
  recoverPasswordSaga,
  signInSaga,
  validateTokenSaga,
  getFirebaseTokenSaga,
} from "./auth";

export function* watchAuthSaga() {
  yield takeLatest(actions.signIn.type, signInSaga);
  yield takeLatest(actions.recoverPassword.type, recoverPasswordSaga);
  yield takeLatest(actions.validateToken.type, validateTokenSaga);
  yield takeLatest(actions.changePassword.type, changePasswordSaga);
  yield takeLatest(actions.logout.type, logoutSaga);
  yield takeLatest(actions.tryAutoSignUp.type, authCheckStateSaga);
  yield takeLatest(actions.getFirebaseToken.type, getFirebaseTokenSaga);
}
