import { OPTION_TYPES } from "src/constants/operation";

export const validateQuantity = (rule: any, value: any, t: any, form: any) => {
  try {
    const intValue = parseInt(value);
    if (intValue < 100)
      return Promise.reject(new Error(t("common:errors.minValue", { min: "100" })));
    if (intValue % 100 !== 0)
      return Promise.reject(new Error(t("common:errors.multipleOf", { value: "100" })));
    if (intValue > 50000)
      return Promise.reject(new Error(t("common:errors.maxValue", { max: "50000" })));
  } catch (error) {
    return Promise.reject(
      new Error(
        t("common:errors.invalidValue", {
          field: t("operation:common.quantity"),
        })
      )
    );
  }
  return Promise.resolve();
};

export const validatePartialQuantity = (value: any, t: any, max: any) => {
  try {
    const intValue = parseInt(value);
    if (intValue < 100)
      return Promise.reject(new Error(t("common:errors.minValue", { min: "100" })));
    if (intValue % 100 !== 0)
      return Promise.reject(new Error(t("common:errors.multipleOf", { value: "100" })));
    if (intValue > max)
      return Promise.reject(new Error(t("common:errors.maxValue", { max })));
  } catch (error) {
    return Promise.reject(
      new Error(
        t("common:errors.invalidValue", {
          field: t("operation:common.quantity"),
        })
      )
    );
  }
  return Promise.resolve();
};

export const validatePrice = (
  rule: any,
  value: any,
  t: any,
  form: any,
  isDisabled: boolean,
  optionType: any
) => {
  try {
    if (isDisabled) return Promise.resolve();
    const intValue = parseFloat(value);
    if (intValue <= 0 && optionType !== OPTION_TYPES.SPREAD)
      return Promise.reject(new Error(t("common:errors.biggerThanValue", { min: "0" })));
  } catch (error) {
    return Promise.reject(
      new Error(
        t("common:errors.invalidValue", {
          field:
            optionType === OPTION_TYPES.FUTURE
              ? t("operation:futures.price")
              : t("operation:spreads.price"),
        })
      )
    );
  }
  return Promise.resolve();
};

export const validatePriceFrom = (rule: any, value: any, t: any, form: any, optionType?: any) => {
  try {
    const intValue = parseFloat(value);
    if (intValue >= parseFloat(form.getFieldValue("priceTo")))
      return Promise.reject(
        new Error(
          t("common:errors.lowerThanValue", {
            max: form.getFieldValue("priceTo"),
          })
        )
      );
    if (intValue >= parseFloat(form.getFieldValue("price")))
      return Promise.reject(
        new Error(
          t("common:errors.lowerThanValue", {
            max: form.getFieldValue("price"),
          })
        )
      );
      if (intValue <= 0 && optionType !== OPTION_TYPES.SPREAD)
      return Promise.reject(new Error(t("common:errors.biggerThanValue", { min: "0" })));
  } catch (error) {
    return Promise.reject(
      new Error(
        t("common:errors.invalidValue", {
          field: t("operation:common.priceFrom"),
        })
      )
    );
  }
  return Promise.resolve();
};

export const validatePriceTo = (rule: any, value: any, t: any, form: any) => {
  try {
    const intValue = parseFloat(value);
    if (intValue <= parseFloat(form.getFieldValue("priceFrom"))) {
      return Promise.reject(
        new Error(
          t("common:errors.biggerThanValue", {
            min: form.getFieldValue("priceFrom"),
          })
        )
      );
    }
    if (intValue <= parseFloat(form.getFieldValue("price"))) {
      return Promise.reject(
        new Error(
          t("common:errors.biggerThanValue", {
            min: form.getFieldValue("price"),
          })
        )
      );
    }
    if (intValue <= 0)
      return Promise.reject(new Error(t("common:errors.biggerThanValue", { min: "0" })));
  } catch (error) {
    return Promise.reject(
      new Error(
        t("common:errors.invalidValue", {
          field: t("operation:common.priceTo"),
        })
      )
    );
  }
  return Promise.resolve();
};
