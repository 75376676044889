import { useEffect } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EMatButton, EMatFormItem, EMatInput, EMatLogo } from "Components";
import { ROUTE_PATHS } from "src/constants/routePaths";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../../store/actions/auth";
import * as sliceActions from "../../../store/slices/auth";
import "../common/auth.scss";
import "./SignIn.scss";
import EMAT_INPUT_TYPES from "src/constants/EMatInputTypes";
import { hasPermissions } from "src/common/utils";
import routeArray from "src/routing/routeArray";
import getDeviceId from "src/helpers/getDeviceId";

const SignIn = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => !!state.auth.token);
  const error = useAppSelector((state) => state.auth.error);
  const previousPath = useAppSelector((state) => state.layout.selectedPath);
  const userPermissions = useAppSelector((state) => state.auth.userPermissions);

  const handleClickForgotPassword = () => {
    navigate(ROUTE_PATHS.RECOVER_PASSWORD);
  };

  const handleClickSignIn = (values: { email: string; password: string; device_id: string }) => {
    values.device_id = getDeviceId();
    values.email && values.password && dispatch(actions.signIn(values));
  };

  useEffect(() => {
    return () => {
      dispatch(sliceActions.clearStore());
    };
  }, []);

  useEffect(() => {
    const previousPathPermissions = routeArray.find((r) => r.path === previousPath)?.permissions;
    if (isAuth)
      if (previousPath && hasPermissions(userPermissions, previousPathPermissions))
        navigate(previousPath);
      else navigate(ROUTE_PATHS.DASHBOARD);
  }, [isAuth, userPermissions]);

  return (
    <div className="auth-container signIn-container background dark">
      <div className="auth-content">
        <EMatLogo />
        <Form className="signin-form" form={form} onFinish={handleClickSignIn}>
          <EMatFormItem
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: t("auth:invalidEmail"),
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.TEXT}
              id="email"
              placeholder={t("auth:signIn:email")}
            />
          </EMatFormItem>
          <EMatFormItem
            name="password"
            rules={[
              {
                required: true,
                message: t("common:errors:fieldRequiredDefault"),
              },
            ]}
          >
            <EMatInput
              eMatType={EMAT_INPUT_TYPES.PASSWORD}
              id="password"
              placeholder={t("auth:signIn:password")}
            />
          </EMatFormItem>
          {error && (
            <div className="error-msg">
              <p>{t(error)}</p>
            </div>
          )}
          <EMatFormItem>
            <EMatButton type="primary" id="submit" htmlType="submit">
              {t("auth:signIn.signIn")}
            </EMatButton>
          </EMatFormItem>
        </Form>
        <EMatButton type="link" onClick={handleClickForgotPassword} id="link-recover-password">
          {t("auth:signIn:forgotPassword")}
        </EMatButton>
      </div>
    </div>
  );
};

export default SignIn;
