import { generatePath } from "react-router-dom";
import API_ROUTES from "src/constants/apiRoutes";
import {
  ContraPositionBody,
  HistoricIssuedOrdersFilters,
  OperationGroupBody,
  OperationType,
} from "src/types/operation";
import axios from "../api/axios-instance";

export const operationService = {
  /** INSTRUMENTS */
  getFutures: (type?: string) => {
    return axios.get(generatePath(API_ROUTES.OPERATION.GET_FUTURES, { type: type }));
  },
  getOptions: (type?: string) => {
    return axios.get(generatePath(API_ROUTES.OPERATION.GET_OPTIONS, { type: type }));
  },
  getSpreads: (type: string) => {
    return axios.get(generatePath(API_ROUTES.OPERATION.GET_SPREADS, { type: type }));
  },
  /** OPERATION */
  createOperation: (body: OperationType) => {
    return axios.post(API_ROUTES.OPERATION.CREATE_OPERATION, body);
  },
  updateOperation: (body: OperationType) => {
    return axios.put(generatePath(API_ROUTES.OPERATION.UPDATE_OPERATION, { id: body.id }), body);
  },
  getIssuedOrders: (type: string) => {
    return axios.get(`${API_ROUTES.ISSUED_ORDERS.GET_ISSUED_ORDERS}?filter=${type}`);
  },
  getHistoricIssuedOrders: (filters: HistoricIssuedOrdersFilters) => {
    const { page, from, to } = filters;
    return axios.get(`${API_ROUTES.ISSUED_ORDERS.HISTORIC}?page=${page}&from=${from}&to=${to}`);
  },
  deleteIssuedOrder: (id: any) => {
    return axios.delete(`${API_ROUTES.ISSUED_ORDERS.DELETE_ISSUED_ORDER}/${id}`);
  },
  getOrderById: (id: any) => {
    return axios.get(`${API_ROUTES.ISSUED_ORDERS.GET_ORDER_BY_ID}/${id}`);
  },
  /** ORDERS GROUPS */
  createOperationGroup: (body: OperationGroupBody) => {
    return axios.post(API_ROUTES.OPERATION.CREATE_OPERATION_GROUP, body);
  },
  getOrdersGroups: (filters: { page: number; from?: string; to?: string }) => {
    const { page, from, to } = filters;
    return axios.get(
      `${API_ROUTES.ISSUED_ORDERS.GET_ISSUED_ORDERS}?page=${page}&created_at_from=${
        from ? from : ""
      }&created_at_to=${to ? to : ""}`
    );
  },
  getReceivedOredersGroups: (filters: { page: number; clientId: string }) => {
    const { page, clientId } = filters;
    return axios.get(
      `${API_ROUTES.RECEIVED_ORDERS.GET_RECEIVED_ORDERS}?page=${page}&client_id=${clientId}`
    );
  },
  deleteOrderGroup: (id: any) => {
    return axios.delete(`${API_ROUTES.RECEIVED_ORDERS.DELETE_ORDER_GROUP}/${id}`);
  },
  /** ------------ */
  getContraPositions: (body: ContraPositionBody) => {
    return axios.get(generatePath(API_ROUTES.OPERATION.GET_CONTRA_POSITIONS, body));
  },
  setStatusSent: (id: any) => {
    return axios.patch(`${API_ROUTES.ISSUED_ORDERS.SET_STATUS_SENT_ORDER}/${id}`);
  },
  getClients: () => {
    return axios.get(API_ROUTES.RECEIVED_ORDERS.GET_CLIENTS);
  },
  getReceivedOrders: (id: any) => {
    return axios.get(`${API_ROUTES.RECEIVED_ORDERS.GET_RECEIVED_ORDERS}?userId=${id}`);
  },
  deleteReceivedOrder: (id: any) => {
    return axios.delete(`${API_ROUTES.RECEIVED_ORDERS.DELETE_RECEIVED_ORDERS}/${id}`);
  },
  markReceived: (id: any) => {
    return axios.post(`${API_ROUTES.RECEIVED_ORDERS.MARK_RECEIVED}/${id}`);
  },
  markOperated: (id: any) => {
    return axios.post(`${API_ROUTES.RECEIVED_ORDERS.MARK_OPERATED}/${id}`);
  },
  markPartiallyOperated: (id: any, quantity: number) => {
    return axios.post(`${API_ROUTES.RECEIVED_ORDERS.MARK_PARTIAL_OPERATED}/${id}`, {
      operated_quantity: quantity,
    });
  },
  sentToOperator: (id: any) => {
    return axios.post(`${API_ROUTES.RECEIVED_ORDERS.SEND_TO_OPERATOR}/${id}`);
  },
  getOwnOpenPositions: (filters: any) => {
    return axios.get(
      `${API_ROUTES.OPERATION.GET_OWN_OPEN_POSITIONS}?grain=${filters.options}&operation=${filters.instrument}`
    );
  },
  getOwnOpenPositionsCsv: () => {
    return axios.get(API_ROUTES.OPERATION.GET_OWN_OPEN_POSITIONS_CSV);
  },
  getOwnClosedPositions: (filters: any) => {
    const { options, operation, page } = filters;
    return axios.get(
      `${API_ROUTES.OPERATION.GET_OWN_CLOSED_POSITIONS}?grain=${options}&side=${operation}&page=${page}&include_expired=false`
    );
  },
  getOwnClosedPositionsCsv: (filters: any) => {
    return axios.get(
      `${API_ROUTES.OPERATION.GET_OWN_CLOSED_POSITIONS_CSV}?grain=${filters.options}`
    );
  },
  getClientsOpenPositions: (filters: any) => {
    return axios.get(
      `${API_ROUTES.OPERATION.GET_CLIENTS_OPEN_POSITIONS}?userId=${filters.client}&grain=${filters.options}&operation=${filters.instrument}`
    );
  },
  getClientsOpenPositionsCsv: (filters: any) => {
    return axios.get(
      `${API_ROUTES.OPERATION.GET_CLIENTS_OPEN_POSITIONS_CSV}?userId=${filters.client}`
    );
  },
  getClientsClosedPositions: (filters: any) => {
    const { client, options, operation, page } = filters;
    return axios.get(
      `${API_ROUTES.OPERATION.GET_CLIENTS_CLOSED_POSITIONS}?userId=${client}&grain=${options}&side=${operation}&page=${page}&include_expired=false`
    );
  },
  getClientsClosedPositionsCsv: (filters: any) => {
    return axios.get(
      `${API_ROUTES.OPERATION.GET_CLIENTS_CLOSED_POSITIONS_CSV}?userId=${filters.client}&grain=${filters.options}`
    );
  },
  getPositions: (body: any) => {
    return axios.get(generatePath(API_ROUTES.OPERATION.GET_POSITIONS, body));
  },
  getCount: () => {
    return axios.get(API_ROUTES.OPERATION.GET_COUNT);
  },
};
