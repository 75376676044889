export const OPTION_TYPES = {
  FUTURE: 'Future',
  OPTION: 'Option',
  SPREAD: 'Spread'
}

export const OPERATION_TYPES = {
  BUY: 'Buy',
  SELL: 'Sell'
}

export const OPERATION_POSITION = {
  OPEN: 'Open',
  CLOSE: 'Close'
}
export const AGREEMENT_TYPE = {
  PUT: 'Put',
  CALL: 'Call'
}

export const ALERT_TYPE_OPTIONS = ['future', 'put', 'call', 'spread']

export const ALERT_TYPES = {
  FUTURE: 'future',
  PUT: 'put',
  CALL: 'call',
  SPREAD: 'spread'
}

export const STATE_OPTIONS = {
  SENT: 1,
  RECEIVED: 2,
  OPERATED: 3,
  EXPIRED: 4,
  SENT_TO: 5,
  PARTIALLY_OPERATED: 6
}
