import { useEffect, useState } from "react";
import withErrorHandler from "src/hoc/WithErrorHandler/withErrorHandler";
import instance from "src/api/axios-instance";
import "./ReceivedOrders.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import * as actions from "../../store/actions";
import { Pagination } from "Components";
import { Client } from "src/types/client";
import ListOrderGroups from "src/components/ListOrdersGroups/ListOrdersGroups";

const refresh_interval = parseInt(process.env.REACT_APP_REFETCH_INTERVAL || "60000");

const ReceivedOrders = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const loadingOrders = useAppSelector<any>((state) => state.operation.loadingOrders);
  const dataOrders = useAppSelector<any>((state) => state.operation.receivedOrdersGroups || []);
  const dataClients = useAppSelector<any>((state) => state.operation.clients || []);
  const loadingClients = useAppSelector<boolean>((state) => state.operation.loadingGetClients);
  const [clientId, setClientId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    dispatch(actions.getReceivedOrdersGroups({ page, clientId }));

    const interval = setInterval(() => {
      dispatch(actions.getReceivedOrdersGroups({ page, clientId }));
    }, refresh_interval);

    return () => clearInterval(interval);
  }, [clientId]);

  const filterClients = [
    {
      label: t("received_orders:filterOptions.allClients"),
      value: "",
    },
    ...dataClients.map((client: Client) => ({
      label: client.company_name,
      value: client.id,
    })),
  ];

  useEffect(() => {
    dispatch(actions.getClients());
  }, []);

  return (
    <div className="main-content received-orders-container" id="received-orders-container">
      <ListOrderGroups
        groups={dataOrders.groups}
        loading={loadingOrders}
        labelCount={t(`received_orders:receivedOrdersGroups`)}
        isReceived={true}
        filter={{
          options: filterClients,
          defaultValue: "",
          onSelect: (value: string) => setClientId(value),
          loading: loadingClients,
        }}
        clientId={clientId}
        pagination={
          dataOrders &&
          dataOrders.current_page && (
            <Pagination
              pagination={{
                count: dataOrders.total,
                next_page:
                  dataOrders.current_page === dataOrders.last_page
                    ? null
                    : dataOrders.current_page + 1,
                prev_page: dataOrders.current_page !== 1 ? dataOrders.current_page - 1 : null,
                page_size: dataOrders.per_page,
              }}
              setPrevPage={() => setPage(dataOrders.current_page + 1)}
              setNextPage={() =>
                setPage(dataOrders.current_page - 1 !== 0 ? dataOrders.current_page - 1 : 1)
              }
            />
          )
        }
      />
    </div>
  );
};

export default withErrorHandler(ReceivedOrders, instance);
