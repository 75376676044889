import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import * as sliceActions from "../../slices/commissions";
import { commissionService } from "Services";
import { ClientsResponse } from "src/types/monitor";
import { CommissionsResponse } from "src/types/commissions";

export function* getCommissionsSaga(action: ReturnType<typeof actions.getCommissions>) {
  try {
    yield put(sliceActions.getCommissionsStart());
    const { clientId, date } = action.payload;
    const filters = { clientId, date };
    const { data }: AxiosResponse<CommissionsResponse> = yield commissionService.getCommissions(
      filters
    );

    yield put(sliceActions.getCommissionsSuccess(data.data.commissions));
  } catch (error: any) {
    yield put(sliceActions.getCommissionsFail(error.message));
  }
}

export function* getMasterClientsSaga() {
  try {
    yield put(sliceActions.getMasterClientsStart());

    const axiosResponse: AxiosResponse<ClientsResponse> =
      yield commissionService.getMasterClients();
    const response: ClientsResponse = axiosResponse.data;
    yield put(sliceActions.getMasterClientsSuccess(response.data));
  } catch (error: any) {
    yield put(sliceActions.getMasterClientsFail(error.message));
  }
}

export function* getCommissionsCsvSaga(action: ReturnType<typeof actions.getCommissionsCsv>) {
  try {
    yield put(sliceActions.getCommissionsCsvStart());
    const { clientId, date } = action.payload;
    const filters = { clientId, date };
    const { data }: AxiosResponse<CommissionsResponse> = yield commissionService.getCsv(filters);

    if (data.data) {
      yield put(sliceActions.getCommissionsCsvSuccess(data.data.commissions));
    } else {
      yield put(sliceActions.getCommissionsCsvSuccess(data));
    }
  } catch (error: any) {
    yield put(sliceActions.getCommissionsCsvFail(error.message));
  }
}
