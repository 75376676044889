import { Button } from "antd";
import "./OperationGroup.scss";
import { EMatButton, EmatOrderGroupModal } from "Components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

export const OperationGroup = (props: any) => {
  const { operationGroup, setOperationGroup, className } = props;
  const [t] = useTranslation();
  const [isSumaryVisible, setIsSumaryVisible] = useState<boolean>(false);
  const handleRemoveItem = (key: number) => {
    const aux = operationGroup.filter((op: any, index: number) => index !== key);
    setOperationGroup(aux);
  };

  return (
    <div className={className}>
      <div className={`operations ${className}`}>

        {operationGroup.map((op: any, key: number) => {
          const { operation, instrument, optionType, strike } = op;
          return (
            <div className="operation" key={key}>
              <h3>
                {t(`operation:common.${operation.toLowerCase()}`)}{" "}
                {t(`operation:common.option-type.${optionType.toLowerCase()}`)} {instrument}
                {strike ? `(${strike})` : ""}
              </h3>
              <DeleteOutlined
                style={{ fontSize: "24px", marginLeft: "5px", color: "#ff4d4f" }}
                onClick={() => handleRemoveItem(key)}
              />
            </div>
          );
        })}

      </div>
      <EMatButton
        type="primary"
        id="submit"
        className="submit-button"
        size="small"
        disabled={operationGroup.length === 0}
        onClick={() => setIsSumaryVisible(true)}
      >
        Enviar
      </EMatButton>
      <EmatOrderGroupModal
        orders={operationGroup}
        onCancel={() => setIsSumaryVisible(false)}
        visible={isSumaryVisible}
      />
    </div>
  );
};
