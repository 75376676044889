import { RangePickerProps } from "antd/lib/date-picker";
import { DateRange } from "src/types/commissions";

export const onDatePickerChange = (
  value: RangePickerProps["value"],
  setDate: (date: DateRange) => void,
  t: (message: string) => void
) => {
  if (value) {
    const from = value[0]?.startOf("day")!;
    const to = value[1]?.startOf("day")!;

    // Calculate one month later from the start date
    const oneMonthLater = from.clone().add(1, "month");

    // Check if the selected range exceeds one month
    if (to.isAfter(oneMonthLater)) {
      alert(t(`issued_orders:calendar.validation`));
      setDate({ from: null, to: null });
      return;
    }

    setDate({ from, to });
  } else {
    setDate({ from: null, to: null });
  }
};
