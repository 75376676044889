import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import * as firebaseConfig from "../config/environments/firebase.json";

const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  let token = "";
  await getToken(messaging, {
    vapidKey: FIREBASE_API_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        token = currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
  return token;
};

export const deleteFirebaseToken = async () => {
  const response = await deleteToken(messaging);
  return response;
};

export const onMessageListener = async () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
