import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { monitorService } from "Services";
import { ClientsResponse, ClientTons, ResultReportType } from "src/types/monitor";
import * as actions from "../../actions";
import * as sliceActions from "../../slices/monitor";

export function* getClientsSaga(action: ReturnType<typeof actions.getMonitorClients>) {
  try {
    yield put(sliceActions.getClientsStart());
    const { data }: AxiosResponse<ClientsResponse> = yield monitorService.getClients();
    yield put(sliceActions.getClientsSuccess(data.data));
  } catch (error: any) {
    yield put(sliceActions.getClientsFail(error.message));
  }
}

export function* getClientTonsSaga(action: ReturnType<typeof actions.getClientTons>) {
  try {
    yield put(sliceActions.getClientTonsStart());
    const { data }: AxiosResponse<ClientTons> = yield monitorService.getClientTons(action.payload);
    yield put(sliceActions.getClientTonsSuccess(data));
  } catch (error: any) {
    yield put(sliceActions.getClientTonsFail(error.message));
  }
}

export function* getResultReportSaga(action: ReturnType<typeof actions.getResultReport>) {
  try {
    yield put(sliceActions.getReultReportDataStart());
    const { instrument, userId } = action.payload
    const { data }: AxiosResponse<ResultReportType> = yield monitorService.getResultRequest(userId, instrument);
    yield put(sliceActions.getReultReportDataSuccess(data));
  } catch (error: any) {
    yield put(sliceActions.getReultReportDataFail(error.message));
  }
}
