import { Col, Row } from "antd";
import { arrowDown } from "Assets";
import { EMatButton } from "Components";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { OPTION_TYPES } from "src/constants/operation";
import { formatCurrency } from "src/helpers/formatCurrency";

//TODO prop types
const OpenSummary = ({
  operation,
  submitting,
  onClickConfirm,
  optionType,
  onFinalize,
}: {
  operation: any;
  submitting: boolean;
  onClickConfirm: (operation: any) => void;
  optionType: string;
  onFinalize: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <>
      <h1>{t("operation:common.summary.open-title")}</h1>
      <div className="summary-values">
        <Row gutter={8}>
          <Col span={12} className="label">
            {t("operation:common.summary.operation")}
          </Col>
          <Col span={12} className="value">
            {t(`operation:common.summary.${operation?.operation.toLowerCase()}`) +
              " " +
              t(`operation:operation-options.${operation.optionType.toLowerCase()}`)}
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12} className="label">
            {t("operation:common.summary.instrument")}
          </Col>
          <Col span={12} className="value">
            {operation?.instrument}
          </Col>
        </Row>
        {operation?.quantity && (
          <Row gutter={8}>
            <Col span={12} className="label">
              {t("operation:common.summary.quantity")}
            </Col>
            <Col span={12} className="value">
              {operation?.quantity} Tn.
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={12} className="label">
            {optionType === OPTION_TYPES.FUTURE
              ? t("operation:common.summary.price.future")
              : t("operation:common.summary.price.spread")}
          </Col>
          <Col span={12} className="value">
            {operation.price
              ? `${formatCurrency(operation.price)} US$`
              : t("operation:common.to-market")}
          </Col>
        </Row>
        {operation?.priceFrom && operation?.priceTo && (
          <Row gutter={8}>
            <Col span={12} className="label">
              {t("operation:common.summary.range")}
            </Col>
            <Col span={12} className="value">
              <span className="range-elipsis">
                {formatCurrency(operation.priceFrom)} US$ / {formatCurrency(operation.priceTo)} US$
              </span>
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col span={12} className="label">
            {t("operation:common.summary.position")}
          </Col>
          <Col span={12} className="value">
            {" "}
            {t(`operation:common.${operation.position.toLowerCase()}`)}{" "}
          </Col>
        </Row>
      </div>
      <div className="buttons-container">
        <EMatButton
          type="primary"
          loading={submitting}
          onClick={() => {
            onClickConfirm(operation);
            onFinalize();
          }}
        >
          {t("operation:common.summary.confirm-order")}
        </EMatButton>
        <EMatButton type="text" id="edit" onClick={() => onClickConfirm(operation)}>
          {t("operation:common.summary.add-new")}
        </EMatButton>
      </div>
    </>
  );
};

export default OpenSummary;
