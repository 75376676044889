import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import { operationService } from "Services";
import * as sliceActions from "src/store/slices/operation";
import { AxiosResponse } from "axios";

export function* createOperationGroupSaga(action: ReturnType<typeof actions.createOperationGroup>) {
  yield put(sliceActions.createOrdersGroupStart());
  try {
    const response: AxiosResponse<any> = yield operationService.createOperationGroup(
      action.payload
    );
    yield put(sliceActions.createOrdersGroupSuccess(response.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.createOrdersGroupError("common:errors.somethingWentWrong"));
  }
}

export function* getIssuedOrdersGroupsSaga(action: ReturnType<typeof actions.getIssuedOrdersGroups>) {
  yield put(sliceActions.getIssuedOrdersGroupsStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getOrdersGroups(action.payload);
    yield put(sliceActions.getIssuedOrdersGroupsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getIssuedOrdersGroupsError("common:errors.somethingWentWrong"));
  }
}

export function* getReceivedOrdersGroupsSaga(action: ReturnType<typeof actions.getReceivedOrdersGroups>) {
  yield put(sliceActions.getReceivedOrdersGroupsStart());
  try {
    const { data }: AxiosResponse<any> = yield operationService.getReceivedOredersGroups(action.payload);
    yield put(sliceActions.getReceivedOrdersGroupsSuccess(data.data));
  } catch (err: any) {
    console.error(err);
    yield put(sliceActions.getReceivedOrdersGroupsError("common:errors.somethingWentWrong"));
  }
}
