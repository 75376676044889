import { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import useHttpErrorHandler from "../../hooks/httpErrorHandler";
import * as actions from "../../store/actions/auth";
import "./WithErrorHandler.scss";
import { ErrorModal } from "Components";
import { useTranslation } from "react-i18next";
import { EMatButton } from "Components";
import { AxiosInstance } from "axios";

const withErrorHandler = (WrappedComponent: React.ComponentType, axios: AxiosInstance) => {
  return (props: any) => {
    const [t] = useTranslation();
    const { onLogout } = props;
    const [httpError, httpErrorClosedHandler] = useHttpErrorHandler(
      axios,
      onLogout
    );

    let message;
    if (typeof httpError?.data?.message === "string") message = httpError?.data.message;
    else if (httpError?.data) {
      const firstErrorKey = Object.keys(httpError?.data?.message)[0];
      message = httpError?.data?.message[firstErrorKey][0];
    } else {
      message = t("common:errors.somethingWentWrong");
    }

    return (
      <Fragment>
        <ErrorModal
          closable={true}
          visible={!!httpError}
          title="Error"
          className="error-modal"
          onCancel={httpErrorClosedHandler}
          footer={
            <EMatButton type="primary" id="close-error-modal" onClick={httpErrorClosedHandler}>
              {t("common:actions.close")}
            </EMatButton>
          }
        >
          <Fragment>
            <p>{message}</p>
            {/* <FontAwesomeIcon icon={faDesktop} className="desktop" /> */}
          </Fragment>
        </ErrorModal>
        <WrappedComponent {...props} />
      </Fragment>
    );
  };
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withErrorHandler);
